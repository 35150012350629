import { Err, Ok, Result } from "ts-results"

import { BigDecimal } from "./math/bigdecimal"
import { SilverKoiApi } from "./silverkoi"
import {
  DiscretizedOrderBook,
  DiscretizedOrderBookPriceLevel,
  OrderBook,
  OrderBookOrder,
  OrderSide,
  PlaceOrderError,
  PlaceOrderResult,
  TimeInForce,
} from "./types"
import { getOppositeSide } from "./util"

export async function getBestBidAndAsk({
  api,
  marketId,
  blockNumber,
}: {
  api: SilverKoiApi
  marketId: bigint
  blockNumber?: bigint
}): Promise<{ bestBid: BigDecimal; bestAsk: BigDecimal }> {
  const args = [Number(marketId)] as const
  const [bestBidX7, bestAskX7] = await api.silverKoi.read.getBestPricesX7(args, { blockNumber })
  const bestBid = BigDecimal.fromRaw(bestBidX7, 7)
  const bestAsk = BigDecimal.fromRaw(bestAskX7, 7)
  return { bestBid, bestAsk }
}

export async function getDiscretizedOrderBook({
  api,
  marketId,
  depth,
  priceStep,
  blockNumber,
}: {
  api: SilverKoiApi
  marketId: bigint
  depth: bigint
  priceStep: BigDecimal
  blockNumber?: bigint
}): Promise<DiscretizedOrderBook> {
  const args = [Number(marketId), depth, priceStep.raw(18)] as const
  const result = await api.silverKoi.read.getDiscretizedBook(args, { blockNumber })

  const { bestBid, bestAsk } = await getBestBidAndAsk({ api, marketId, blockNumber })

  const bids: DiscretizedOrderBookPriceLevel[] = []
  for (const [i, bid] of result.bids.entries()) {
    if (i >= result.bidsLength) {
      break
    }
    bids.push({
      price: BigDecimal.fromRaw(bid.priceX18, 18),
      size: BigDecimal.fromRaw(bid.sizeX5, 5),
    })
  }

  const asks: DiscretizedOrderBookPriceLevel[] = []
  for (const [i, ask] of result.asks.entries()) {
    if (i >= result.asksLength) {
      break
    }
    asks.push({
      price: BigDecimal.fromRaw(ask.priceX18, 18),
      size: BigDecimal.fromRaw(ask.sizeX5, 5),
    })
  }

  return {
    bestBid,
    bestAsk,
    bids,
    asks,
  }
}

// Retrieves the top orders on book such that the total notional for each side
// is at least the specified notional. If the total book depth is less that the
// specified notional, then this will retrieve the full book.
// NOTE: `blockNumber` is required here. Unlike most of the other methods in this
// sdk, this method requires multiple calls to the chain, so `blockNumber` is
// needed to ensure consistent data is returned from the chain.
export async function getOrderBook({
  api,
  marketId,
  notional,
  blockNumber,
}: {
  api: SilverKoiApi
  marketId: bigint
  notional: BigDecimal
  blockNumber: bigint
}): Promise<OrderBook> {
  const bids = await getOrderBookSide({ api, marketId, side: "bid", notional, blockNumber })
  const asks = await getOrderBookSide({ api, marketId, side: "ask", notional, blockNumber })
  return { bids, asks }
}

// Retrieves the top orders on book for the specified side total notional is at
// least the specified notional. If the total book depth is less that the
// specified notional, then this will retrieve the full book.
// NOTE: `blockNumber` is required here. Unlike most of the other methods in
// this sdk, this method requires multiple calls to the chain, so `blockNumber`
// is needed to ensure consistent data is returned from the chain.
export async function getOrderBookSide({
  api,
  marketId,
  side,
  notional,
  blockNumber,
}: {
  api: SilverKoiApi
  marketId: bigint
  side: OrderSide
  notional: BigDecimal
  blockNumber: bigint
}): Promise<OrderBookOrder[]> {
  const orders: OrderBookOrder[] = []
  let remainingNotional = notional

  const batchSize = 50n
  let startOrderId = 0n
  while (!remainingNotional.isZero()) {
    const args = [Number(marketId), side === "bid", startOrderId, batchSize] as const
    const [rawOrders, rawOrdersLength, nextOrderId] = await api.silverKoi.read.getOrdersForSide(
      args,
      { blockNumber },
    )
    const batch: OrderBookOrder[] = rawOrders.map((rawOrder) => {
      return {
        marketId,
        orderId: rawOrder.orderId,
        price: BigDecimal.fromRaw(rawOrder.priceX7, 7),
        size: BigDecimal.fromRaw(rawOrder.remainingSizeX5, 5),
        deadline: BigInt(rawOrder.deadline),
      }
    })

    for (const [i, order] of batch.entries()) {
      if (i >= rawOrdersLength) {
        break
      }
      orders.push(order)

      const orderNotional = order.size.mul(order.price)
      if (orderNotional.le(remainingNotional)) {
        remainingNotional = remainingNotional.sub(orderNotional)
      } else {
        remainingNotional = BigDecimal.zero()
        break
      }
    }

    if (nextOrderId === 0n) break
    startOrderId = nextOrderId
  }

  return orders
}

// Given the specified order value to fill, determines the order size and
// notional that would actually be filled.
export async function getOrderSizeForOrderNotional({
  api,
  marketId,
  side,
  notional,
  limitPrice,
  blockNumber,
}: {
  api: SilverKoiApi
  marketId: bigint
  side: OrderSide
  notional: BigDecimal
  limitPrice?: BigDecimal
  blockNumber: bigint
}): Promise<{
  fillSize: BigDecimal
  fillNotional: BigDecimal
}> {
  if (notional.isZero()) {
    return {
      fillSize: BigDecimal.zero(),
      fillNotional: BigDecimal.zero(),
    }
  }

  // We assume that the returned orders are in order of increasing price for
  // asks and decreasing price for bids.
  const orders = await getOrderBookSide({
    api,
    marketId,
    side: getOppositeSide(side),
    notional,
    blockNumber,
  })

  let fillSize = BigDecimal.zero()
  let fillNotional = BigDecimal.zero()
  let remainingNotional = notional
  for (const order of orders) {
    if (limitPrice) {
      if (side === "bid" && order.price.gt(limitPrice)) {
        break
      }
      if (side === "ask" && order.price.lt(limitPrice)) {
        break
      }
    }

    const orderNotional = order.size.mul(order.price)
    if (orderNotional.le(remainingNotional)) {
      fillSize = fillSize.add(order.size)
      fillNotional = fillNotional.add(orderNotional)
      remainingNotional = remainingNotional.sub(orderNotional)
    } else {
      const remainingNotionalX18 = remainingNotional.raw(18)
      const orderPriceX18 = order.price.raw(18)
      const partialFillSizeX18 = (remainingNotionalX18 * 10n ** 18n) / orderPriceX18
      const partialFillSize = BigDecimal.fromRaw(partialFillSizeX18, 18)
      fillSize = fillSize.add(partialFillSize)
      fillNotional = fillNotional.add(remainingNotional)
      remainingNotional = BigDecimal.zero()
      break
    }
  }

  return { fillSize, fillNotional }
}

export function computePlaceOrderResult({
  orderBook,
  side,
  size,
  price,
  tif,
  postOnly,
}: {
  orderBook: OrderBook
  side: OrderSide
  size: BigDecimal
  price: BigDecimal
  tif: TimeInForce
  postOnly: boolean
}): Result<PlaceOrderResult, PlaceOrderError> {
  const zero = BigDecimal.zero()
  const bestBid = orderBook.bids.at(0)?.price
  const bestAsk = orderBook.asks.at(0)?.price

  if (postOnly) {
    // Maker only. We already validated that the order will not cross.
    if (
      (side === "bid" && bestAsk && price.ge(bestAsk)) ||
      (side === "ask" && bestBid && price.le(bestBid))
    ) {
      return Err({ type: "PlaceOrderError", message: "OrderWillCross" })
    }

    return Ok({
      takerNotional: zero,
      takerSize: zero,
      makerNotional: size.mul(price),
      makerSize: size,
      remainingSize: size,
      newBestBid: side === "bid" && (!bestBid || price.gt(bestBid)) ? price : bestBid,
      newBestAsk: side === "ask" && (!bestAsk || price.lt(bestAsk)) ? price : bestAsk,
    })
  } else {
    // Execution is allowed. Determine how much would be filled.
    const oppSide = getOppositeSide(side)
    const makerOrders = oppSide === "bid" ? orderBook.bids : orderBook.asks
    let remainingSize = size
    let takerNotional = zero
    let newBestOppPrice = makerOrders.at(0)?.price
    for (const order of makerOrders) {
      newBestOppPrice = order.price
      if (remainingSize.isZero()) break

      // Early exit if we violate the limit price.
      if (!price.isZero()) {
        if (side === "bid" && order.price.gt(price)) break
        if (side === "ask" && order.price.lt(price)) break
      }

      if (order.size.le(remainingSize)) {
        takerNotional = takerNotional.add(order.price.mul(order.size))
        remainingSize = remainingSize.sub(order.size)
      } else {
        takerNotional = takerNotional.add(order.price.mul(remainingSize))
        remainingSize = zero
        break
      }
    }
    const takerSize = size.sub(remainingSize)

    // Remaining GTC/GTD order goes on the book.
    let makerNotional = zero
    let makerSize = zero
    if ((tif === "gtc" || tif === "gtd") && !remainingSize.isZero()) {
      makerNotional = remainingSize.mul(price)
      makerSize = remainingSize
    }

    const max = (a: BigDecimal | undefined, b: BigDecimal | undefined) => {
      if (a === undefined) return b
      if (b === undefined) return a
      return a.gt(b) ? a : b
    }

    const min = (a: BigDecimal | undefined, b: BigDecimal | undefined) => {
      if (a === undefined) return b
      if (b === undefined) return a
      return a.lt(b) ? a : b
    }

    const newBestBid = (() => {
      if (side === "bid") {
        if (makerNotional.isZero()) {
          return bestBid
        } else {
          return max(bestBid, price)
        }
      } else {
        return newBestOppPrice
      }
    })()
    const newBestAsk = (() => {
      if (side === "ask") {
        if (makerNotional.isZero()) {
          return bestAsk
        } else {
          return min(bestAsk, price)
        }
      } else {
        return newBestOppPrice
      }
    })()

    if (tif === "fok" && !remainingSize.isZero()) {
      return Err({ type: "PlaceOrderError", message: "FokNotFullyFilled" })
    }

    return Ok({
      takerNotional,
      takerSize,
      makerNotional,
      makerSize,
      remainingSize,
      newBestBid,
      newBestAsk,
    })
  }
}
