/*
 * Generated type guards for "types.ts".
 * WARNING: Do not manually change this file.
 */
import { BigDecimal } from "../../deps/silverkoi/src/math/bigdecimal";
import { WithdrawCollateralRequest, DepositCollateralRequest, CancelOrderRequest, PlaceOrderRequest, CreateTriggerRequest, ReplaceTriggerRequest, CancelTriggerRequest } from "./types";

export function isWithdrawCollateralRequest(obj: unknown): obj is WithdrawCollateralRequest {
    const typedObj = obj as WithdrawCollateralRequest
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        typedObj["type"] === "WithdrawCollateral" &&
        typeof typedObj["traderId"] === "bigint" &&
        typeof typedObj["marketId"] === "bigint" &&
        typeof typedObj["positionSubId"] === "bigint" &&
        typedObj["amount"] instanceof BigDecimal
    )
}

export function isDepositCollateralRequest(obj: unknown): obj is DepositCollateralRequest {
    const typedObj = obj as DepositCollateralRequest
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        typedObj["type"] === "DepositCollateral" &&
        typeof typedObj["traderId"] === "bigint" &&
        typeof typedObj["marketId"] === "bigint" &&
        typeof typedObj["positionSubId"] === "bigint" &&
        typedObj["amount"] instanceof BigDecimal
    )
}

export function isCancelOrderRequest(obj: unknown): obj is CancelOrderRequest {
    const typedObj = obj as CancelOrderRequest
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        typedObj["type"] === "CancelOrder" &&
        typeof typedObj["traderId"] === "bigint" &&
        typeof typedObj["marketId"] === "bigint" &&
        typeof typedObj["clientOrderId"] === "bigint"
    )
}

export function isPlaceOrderRequest(obj: unknown): obj is PlaceOrderRequest {
    const typedObj = obj as PlaceOrderRequest
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        typedObj["type"] === "PlaceOrder" &&
        typeof typedObj["traderId"] === "bigint" &&
        typeof typedObj["marketId"] === "bigint" &&
        typeof typedObj["positionSubId"] === "bigint" &&
        typeof typedObj["clientOrderId"] === "bigint" &&
        (typedObj["side"] === "bid" ||
            typedObj["side"] === "ask") &&
        typedObj["size"] instanceof BigDecimal &&
        typedObj["price"] instanceof BigDecimal &&
        typeof typedObj["tick"] === "bigint" &&
        (typedObj["tif"] === "ioc" ||
            typedObj["tif"] === "fok" ||
            typedObj["tif"] === "gtc" ||
            typedObj["tif"] === "gtd") &&
        typeof typedObj["deadline"] === "bigint" &&
        typeof typedObj["postOnly"] === "boolean" &&
        typeof typedObj["reduceOnly"] === "boolean" &&
        typedObj["targetLeverage"] instanceof BigDecimal &&
        typeof typedObj["maintainLeverage"] === "boolean"
    )
}

export function isCreateTriggerRequest(obj: unknown): obj is CreateTriggerRequest {
    const typedObj = obj as CreateTriggerRequest
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        typedObj["type"] === "CreateTrigger" &&
        typeof typedObj["traderId"] === "bigint" &&
        typeof typedObj["marketId"] === "bigint" &&
        typeof typedObj["positionSubId"] === "bigint" &&
        typeof typedObj["clientOrderId"] === "bigint" &&
        (typedObj["side"] === "bid" ||
            typedObj["side"] === "ask") &&
        typedObj["size"] instanceof BigDecimal &&
        typedObj["fromAboveTriggerPrice"] instanceof BigDecimal &&
        typedObj["fromBelowTriggerPrice"] instanceof BigDecimal &&
        typedObj["fromAboveLimitPrice"] instanceof BigDecimal &&
        typedObj["fromBelowLimitPrice"] instanceof BigDecimal &&
        typeof typedObj["fromAboveTriggerTick"] === "bigint" &&
        typeof typedObj["fromBelowTriggerTick"] === "bigint" &&
        typeof typedObj["fromAboveLimitTick"] === "bigint" &&
        typeof typedObj["fromBelowLimitTick"] === "bigint" &&
        (typedObj["tif"] === "ioc" ||
            typedObj["tif"] === "fok" ||
            typedObj["tif"] === "gtc" ||
            typedObj["tif"] === "gtd") &&
        typeof typedObj["postOnly"] === "boolean" &&
        typeof typedObj["reduceOnly"] === "boolean" &&
        typedObj["targetLeverage"] instanceof BigDecimal &&
        typeof typedObj["maintainLeverage"] === "boolean"
    )
}

export function isReplaceTriggerRequest(obj: unknown): obj is ReplaceTriggerRequest {
    const typedObj = obj as ReplaceTriggerRequest
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        typedObj["type"] === "ReplaceTrigger" &&
        typeof typedObj["fromAbove"] === "boolean" &&
        typeof typedObj["oldClientOrderId"] === "bigint" &&
        typeof typedObj["traderId"] === "bigint" &&
        typeof typedObj["marketId"] === "bigint" &&
        typeof typedObj["positionSubId"] === "bigint" &&
        typeof typedObj["clientOrderId"] === "bigint" &&
        (typedObj["side"] === "bid" ||
            typedObj["side"] === "ask") &&
        typedObj["size"] instanceof BigDecimal &&
        typedObj["fromAboveTriggerPrice"] instanceof BigDecimal &&
        typedObj["fromBelowTriggerPrice"] instanceof BigDecimal &&
        typedObj["fromAboveLimitPrice"] instanceof BigDecimal &&
        typedObj["fromBelowLimitPrice"] instanceof BigDecimal &&
        typeof typedObj["fromAboveTriggerTick"] === "bigint" &&
        typeof typedObj["fromBelowTriggerTick"] === "bigint" &&
        typeof typedObj["fromAboveLimitTick"] === "bigint" &&
        typeof typedObj["fromBelowLimitTick"] === "bigint" &&
        (typedObj["tif"] === "ioc" ||
            typedObj["tif"] === "fok" ||
            typedObj["tif"] === "gtc" ||
            typedObj["tif"] === "gtd") &&
        typeof typedObj["postOnly"] === "boolean" &&
        typeof typedObj["reduceOnly"] === "boolean" &&
        typedObj["targetLeverage"] instanceof BigDecimal &&
        typeof typedObj["maintainLeverage"] === "boolean"
    )
}

export function isCancelTriggerRequest(obj: unknown): obj is CancelTriggerRequest {
    const typedObj = obj as CancelTriggerRequest
    return (
        (typedObj !== null &&
            typeof typedObj === "object" ||
            typeof typedObj === "function") &&
        typedObj["type"] === "CancelTrigger" &&
        typeof typedObj["traderId"] === "bigint" &&
        typeof typedObj["marketId"] === "bigint" &&
        typeof typedObj["clientOrderId"] === "bigint"
    )
}
