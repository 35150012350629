export function encodePositionId({
  traderId,
  positionSubId,
}: {
  traderId: bigint
  positionSubId: bigint
}): bigint {
  return (traderId << 8n) | positionSubId
}

export function decodePositionId(positionId: bigint): {
  traderId: bigint
  positionSubId: bigint
} {
  const positionSubId = positionId & ((1n << 8n) - 1n)
  positionId = positionId << 8n

  const traderId = positionId

  return { traderId, positionSubId }
}

export function encodeOrderId({
  traderId,
  clientOrderId,
}: {
  traderId: bigint
  clientOrderId: bigint
}): bigint {
  return (traderId << 32n) | clientOrderId
}

export function decodeOrderId(orderId: bigint): {
  traderId: bigint
  clientOrderId: bigint
  forLiquidation: boolean
} {
  const clientOrderId = orderId & ((1n << 31n) - 1n)
  const forLiquidation = ((orderId >> 31n) & 1n) === 1n
  const traderId = orderId >> 32n
  return { traderId, clientOrderId, forLiquidation }
}

export function encodeTriggerId({
  traderId,
  clientOrderId,
}: {
  traderId: bigint
  clientOrderId: bigint
}): bigint {
  return (traderId << 32n) | clientOrderId
}

export function decodeTriggerId(triggerId: bigint): {
  traderId: bigint
  clientOrderId: bigint
} {
  const clientOrderId = triggerId & ((1n << 31n) - 1n)
  const traderId = triggerId >> 32n
  return { traderId, clientOrderId }
}
