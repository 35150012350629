import * as viem from "viem"

import { PlumeTestnetSilverKoiData, loadSilverKoiData } from "./abi"
import { Chain, getChainId } from "./chain"
import { decodeOrderId, decodeTriggerId, encodePositionId } from "./codec"
import { BigDecimal } from "./math"
import { getDefaultMulticall3Address } from "./multicall"
import { Universe, getUniverse } from "./symbol"
import {
  CURRENT_PRICE,
  CreateTriggerSimulationResult,
  CurrentPrice,
  FundingRate,
  LiquidationPriceFlag,
  MarketMeta,
  MarketSnapshot,
  OperationTransactionSimulationResult,
  OrderSide,
  OrderSnapshot,
  OrderState,
  PositionSnapshot,
  ReplaceTriggerSimulationResult,
  TimeInForce,
  TraderConfig,
  TraderInfo,
  TriggerSnapshot,
  UserBalances,
} from "./types"
import { ShallowMapProp } from "./util"

import { abi as silverKoiAbi } from "./data/abis/plume-testnet/ISilverKoi"

// TODO: Get this limit from chain directly
const MAX_POSITION_SUB_IDS = 3n
const HOURS_IN_YEAR = 24n * 365n

export class NoSignerAccountError extends Error {
  constructor() {
    super("No signer account is found")
    this.name = "NoSignerAccountError"
  }
}

export class NoFaucetError extends Error {
  constructor() {
    super("No faucet is defined")
    this.name = "NoFaucetError"
  }
}
export class MarketContainer {
  private _marketsBySymbol: Map<string, MarketMeta>
  private _marketsByMarketId: Map<bigint, MarketMeta>

  constructor(marketInfos: MarketMeta[]) {
    this._marketsBySymbol = new Map<string, MarketMeta>()
    this._marketsByMarketId = new Map<bigint, MarketMeta>()
    for (const info of marketInfos) {
      this._marketsBySymbol.set(info.symbol, info)
      this._marketsByMarketId.set(info.marketId, info)
    }
  }

  getBySymbol(symbol: string): MarketMeta {
    const info = this._marketsBySymbol.get(symbol)
    if (info === undefined) {
      throw new Error(`symbol not found: ${symbol}`)
    }
    return info
  }

  getByMarketId(marketId: bigint): MarketMeta {
    const info = this._marketsByMarketId.get(marketId)
    if (info === undefined) {
      throw new Error(`market id not found: ${marketId}`)
    }
    return info
  }

  symbols(): string[] {
    return [...this._marketsBySymbol.keys()]
  }

  marketIds(): bigint[] {
    return [...this._marketsByMarketId.keys()]
  }
}

export type KeyedClient = {
  public: viem.PublicClient
  wallet?: viem.WalletClient
}

type IERC20 = viem.GetContractReturnType<PlumeTestnetSilverKoiData["abis"]["erc20"], KeyedClient>
type IMulticall3 = viem.GetContractReturnType<
  PlumeTestnetSilverKoiData["abis"]["multicall3"],
  KeyedClient
>
type PlumeTestnetSilverKoi = viem.GetContractReturnType<
  PlumeTestnetSilverKoiData["abis"]["silverKoi"],
  KeyedClient
>
type PlumeTestnetVault = viem.GetContractReturnType<
  PlumeTestnetSilverKoiData["abis"]["vault"],
  KeyedClient
>
type PlumeTestnetFaucet = viem.GetContractReturnType<
  PlumeTestnetSilverKoiData["abis"]["faucet"],
  KeyedClient
>

export interface SilverKoiApi {
  uid: string
  chain: Chain
  chainId: bigint
  client: KeyedClient
  usdc: IERC20
  usdcDecimals: bigint
  silverKoi: PlumeTestnetSilverKoi
  vault: PlumeTestnetVault
  faucet?: PlumeTestnetFaucet
  markets: MarketContainer
  universe: Universe
  multicall: IMulticall3
}

export async function loadSilverKoiApi({
  chain,
  client,
}: {
  chain: Chain
  client: KeyedClient
}): Promise<SilverKoiApi> {
  const data = loadSilverKoiData({ chain })

  const silverKoi = viem.getContract({
    address: data.addresses.silverKoi,
    abi: data.abis.silverKoi,
    client,
  })
  const vault = viem.getContract({
    address: data.addresses.vault,
    abi: data.abis.vault,
    client,
  })

  const usdcAddress = await silverKoi.read.getSettlementToken()
  const usdc = viem.getContract({
    address: usdcAddress,
    abi: data.abis.erc20,
    client,
  })
  const usdcDecimals = BigInt(await usdc.read.decimals())

  const faucet =
    data.abis.faucet && data.addresses.faucet
      ? viem.getContract({
          address: data.addresses.faucet,
          abi: data.abis.faucet,
          client,
        })
      : undefined

  const rawMarketInfos = await silverKoi.read.getAllMarketInfos()
  const marketMetas: MarketMeta[] = []
  for (const info of rawMarketInfos) {
    const symbol = decodeShortString20(info.symbol)
    marketMetas.push({
      address: info.marketAddress,
      symbol,
      marketId: BigInt(info.marketId),
    })
  }
  const markets = new MarketContainer(marketMetas)

  const universe = getUniverse()

  const chainId = getChainId(chain)
  const uid = `${chain}:${client.public.uid}:${client.wallet?.uid}`

  const multicall = viem.getContract({
    address: getDefaultMulticall3Address(chain),
    abi: data.abis.multicall3,
    client,
  })

  return {
    uid,
    chain,
    chainId,
    client,
    usdc,
    usdcDecimals,
    silverKoi,
    vault,
    faucet,
    markets,
    universe,
    multicall,
  }
}

export async function getFundingRate({
  api,
  marketId,
  blockNumber,
}: {
  api: SilverKoiApi
  marketId: bigint
  blockNumber?: bigint
}): Promise<FundingRate> {
  const detail = await api.silverKoi.read.getFundingDetail([Number(marketId)], { blockNumber })
  const longYearlyRateX6 = BigInt(detail.longYearlyRateX6)
  const shortYearlyRateX6 = BigInt(detail.shortYearlyRateX6)
  const lastDistributeTimestamp = BigInt(detail.lastDistributeTimestamp)

  const longHourlyRate = BigDecimal.fromRaw((longYearlyRateX6 * 10n ** 12n) / HOURS_IN_YEAR, 18)
  const shortHourlyRate = BigDecimal.fromRaw((shortYearlyRateX6 * 10n ** 12n) / HOURS_IN_YEAR, 18)
  const longYearlyRate = BigDecimal.fromRaw(longYearlyRateX6, 6)
  const shortYearlyRate = BigDecimal.fromRaw(shortYearlyRateX6, 6)

  return {
    longHourlyRate,
    shortHourlyRate,
    longYearlyRate,
    shortYearlyRate,
    lastDistributeTimestamp,
  }
}

// Queries the trader id for the specified account. Returns `undefined` if the
// trader is not registered.
export async function getTraderId({
  api,
  account,
  blockNumber,
}: {
  api: SilverKoiApi
  account: viem.Address
  blockNumber?: bigint
}): Promise<bigint | undefined> {
  try {
    const traderId = await api.silverKoi.read.getTraderId([account], { blockNumber })
    return BigInt(traderId)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    // TODO: instanceof doesn't work with any of the viem errors...
    const err = (e as viem.BaseError).walk(
      (err) => (err as viem.BaseError).name === "ContractFunctionRevertedError",
    ) as viem.ContractFunctionRevertedError | null
    if (err?.data?.errorName === "Registry_TraderNotRegistered") {
      return undefined
    }
    throw e
  }
}

export async function getTraderInfo({
  api,
  account,
  blockNumber,
}: {
  api: SilverKoiApi
  account: viem.Address
  blockNumber?: bigint
}): Promise<TraderInfo> {
  const traderId = await getTraderId({ api, account, blockNumber })
  return { address: account, traderId }
}

// Queries the trader id for the specified account. Returns `undefined` if the
// trader is not registered.
export async function getTraderConfig({
  api,
  traderId,
  marketId,
  blockNumber,
}: {
  api: SilverKoiApi
  traderId: bigint
  marketId: bigint
  blockNumber?: bigint
}): Promise<TraderConfig> {
  const [takerFeeRatioX6, makerFeeRatioX6] =
    await api.silverKoi.read.getTakerAndMakerFeeRatioForTrader(
      [Number(marketId), Number(traderId)],
      {
        blockNumber,
      },
    )
  const takerFeeRatio = BigDecimal.fromRaw(takerFeeRatioX6, 6)
  const makerFeeRatio = BigDecimal.fromRaw(makerFeeRatioX6, 6)

  return {
    marketId,
    traderId,
    takerFeeRatio,
    makerFeeRatio,
  }
}

export async function getMarketSnapshots({
  api,
  marketIds,
  blockNumber,
}: {
  api: SilverKoiApi
  marketIds: bigint[]
  blockNumber?: bigint
}): Promise<Map<bigint, MarketSnapshot>> {
  const snapshots = new Map<bigint, MarketSnapshot>()

  if (marketIds.length === 0) {
    return snapshots
  }

  interface Context {
    marketId: bigint
  }

  const { address, abi } = api.silverKoi
  const contract = { address, abi }

  const allCalls: viem.ContractFunctionParameters[] = []
  const contexts: Context[] = []
  let numCallsPerMarket = 0
  for (const marketId of marketIds) {
    // TODO: Query other market configs we will need for the frontend
    const calls: viem.ContractFunctionParameters[] = [
      {
        ...contract,
        functionName: "getMarginRatios",
        args: [Number(marketId)],
      },
      {
        ...contract,
        functionName: "getMarketPriceX10",
        args: [Number(marketId)],
      },
      {
        ...contract,
        functionName: "getOraclePriceX10",
        args: [Number(marketId)],
      },
      {
        ...contract,
        functionName: "getBestPricesX7",
        args: [Number(marketId)],
      },
      {
        ...contract,
        functionName: "getMarketPriceTwapInterval",
        args: [Number(marketId)],
      },
      {
        ...contract,
        functionName: "getTwapMarketPriceX10ForDefaultTwapInterval",
        args: [Number(marketId)],
      },
      {
        ...contract,
        functionName: "getFundingDetail",
        args: [Number(marketId)],
      },
    ]
    numCallsPerMarket = calls.length
    allCalls.push(...calls)
    for (let j = 0; j < calls.length; ++j) {
      contexts.push({ marketId })
    }
  }

  const results = await api.client.public.multicall({
    contracts: allCalls,
    allowFailure: false,
    multicallAddress: api.multicall.address,
    blockNumber,
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parseMarketSnapshot = (marketId: bigint, results: any[]): MarketSnapshot => {
    const { symbol } = api.markets.getByMarketId(marketId)
    const [
      [imRatioX6, mmRatioX6],
      marketPriceX10,
      oraclePriceX10,
      [bestBidX7, bestAskX7],
      twapInterval,
      twapMarketPriceX10,
      fundingDetail,
    ] = results
    const longYearlyRateX6 = BigInt(fundingDetail.longYearlyRateX6)
    const shortYearlyRateX6 = BigInt(fundingDetail.shortYearlyRateX6)
    const lastDistributeTimestamp = BigInt(fundingDetail.lastDistributeTimestamp)

    const imRatio = BigDecimal.fromRaw(imRatioX6, 6)
    const mmRatio = BigDecimal.fromRaw(mmRatioX6, 6)
    const maxInitialLeverage = BigDecimal.one().div(imRatio, 6)
    const marketPrice = BigDecimal.fromRaw(marketPriceX10, 10)
    const oraclePrice = BigDecimal.fromRaw(oraclePriceX10, 10)
    const bestBid = BigDecimal.fromRaw(bestBidX7, 7)
    const bestAsk = BigDecimal.fromRaw(bestAskX7, 7)
    const twapMarketPrice = BigDecimal.fromRaw(twapMarketPriceX10, 10)

    const longHourlyRate = BigDecimal.fromRaw((longYearlyRateX6 * 10n ** 12n) / HOURS_IN_YEAR, 18)
    const shortHourlyRate = BigDecimal.fromRaw((shortYearlyRateX6 * 10n ** 12n) / HOURS_IN_YEAR, 18)
    const longYearlyRate = BigDecimal.fromRaw(longYearlyRateX6, 6)
    const shortYearlyRate = BigDecimal.fromRaw(shortYearlyRateX6, 6)

    return {
      symbol,
      marketId,
      imRatio,
      mmRatio,
      maxInitialLeverage,
      marketPrice,
      oraclePrice,
      bestBid,
      bestAsk,
      twapInterval,
      twapMarketPrice,

      longHourlyRate,
      shortHourlyRate,
      longYearlyRate,
      shortYearlyRate,
      lastDistributeTimestamp,
    }
  }

  for (let i = 0; i < results.length; i += numCallsPerMarket) {
    const { marketId } = contexts[i]
    const chunk = results.slice(i, i + numCallsPerMarket)
    const marketSnapshot = parseMarketSnapshot(marketId, chunk)
    snapshots.set(marketId, marketSnapshot)
  }

  return snapshots
}

export async function getUserBalances({
  api,
  account,
  traderId,
  blockNumber,
}: {
  api: SilverKoiApi
  account: viem.Address
  traderId?: bigint
  blockNumber?: bigint
}): Promise<UserBalances> {
  const calls: viem.ContractFunctionParameters[] = [
    {
      address: api.multicall.address,
      abi: api.multicall.abi,
      functionName: "getEthBalance",
      args: [account],
    },
    {
      address: api.usdc.address,
      abi: api.usdc.abi,
      functionName: "balanceOf",
      args: [account],
    },
    {
      address: api.usdc.address,
      abi: api.usdc.abi,
      functionName: "allowance",
      args: [account, api.vault.address],
    },
  ]

  if (traderId !== undefined) {
    for (const marketId of api.markets.marketIds()) {
      for (let posSubId = 1n; posSubId <= MAX_POSITION_SUB_IDS; ++posSubId) {
        const positionId = encodePositionId({ traderId, positionSubId: posSubId })
        calls.push({
          address: api.silverKoi.address,
          abi: api.silverKoi.abi,
          functionName: "getPositionSnapshot",
          args: [marketId, positionId],
        })
      }
    }
  }

  const results = await api.client.public.multicall({
    contracts: calls,
    allowFailure: false,
    blockNumber: blockNumber,
    multicallAddress: api.multicall.address,
  })

  const [nativeBalanceX18, usdcBalanceXS, usdcAllowanceXS] = results.slice(0, 3) as number[]

  let accountValueX12: bigint
  if (traderId === undefined) {
    accountValueX12 = 0n
  } else {
    accountValueX12 = results
      .slice(3)
      .map((r) => {
        const snapshot = r as viem.ReadContractReturnType<
          typeof api.silverKoi.abi,
          "getPositionSnapshot"
        >
        return BigInt(snapshot.conservativeAccountValueX12)
      })
      .reduce((a, b) => a + b)
  }

  const nativeBalance = BigDecimal.fromRaw(nativeBalanceX18, 18)
  const usdcBalance = BigDecimal.fromRaw(usdcBalanceXS, api.usdcDecimals)
  const usdcAllowance = BigDecimal.fromRaw(usdcAllowanceXS, api.usdcDecimals)
  const accountValue = BigDecimal.fromRaw(accountValueX12, 12)

  return {
    nativeBalance,
    usdcBalance,
    usdcAllowance,
    accountValue,
  }
}

interface MarketIdAndOrderId {
  marketId: bigint
  orderId: bigint
}

type GetOrderSnapshotReturnType = viem.ReadContractReturnType<
  typeof silverKoiAbi,
  "getOrderSnapshot"
>

export async function getOrderSnapshots({
  api,
  traderId,
  marketAndOrderIds,
  blockNumber,
}: {
  api: SilverKoiApi
  traderId?: bigint
  marketAndOrderIds: MarketIdAndOrderId[]
  blockNumber?: bigint
}): Promise<OrderSnapshot[]> {
  if (traderId === undefined) {
    return []
  }

  const contracts: viem.ContractFunctionParameters[] = marketAndOrderIds.map(
    ({ marketId, orderId }) => {
      return {
        address: api.silverKoi.address,
        abi: api.silverKoi.abi,
        functionName: "getOrderSnapshot",
        args: [marketId, orderId],
      }
    },
  )
  const result = (await api.client.public.multicall({
    contracts,
    blockNumber,
    allowFailure: false,
    multicallAddress: api.multicall.address,
  })) as GetOrderSnapshotReturnType[]
  const orders = result.map((snapshot) => parseOrderSnapshot({ api, snapshot }))
  return orders
}

function parseOrderSnapshot({
  api,
  snapshot,
}: {
  api: SilverKoiApi
  snapshot: GetOrderSnapshotReturnType
}): OrderSnapshot {
  const marketId = BigInt(snapshot.marketId)
  const traderId = BigInt(snapshot.traderId)
  const positionId = BigInt(snapshot.positionId)
  const positionSubId = BigInt(snapshot.positionSubId)
  const orderId = BigInt(snapshot.orderId)
  const clientOrderId = BigInt(snapshot.clientOrderId)
  const tick = BigInt(snapshot.tick)
  const deadline = BigInt(snapshot.deadline)
  const { postOnly, reduceOnly } = snapshot

  const marketMeta = api.markets.getByMarketId(marketId)
  const symbolMeta = api.universe.getBySymbol(marketMeta.symbol)

  const size = BigDecimal.fromRaw(snapshot.sizeX5, 5)
  const price = BigDecimal.fromRaw(snapshot.priceX7, 7)
  const side = decodeOrderSide(BigInt(snapshot.side))
  const tif = decodeTimeInForce(BigInt(snapshot.tif))
  const state = decodeOrderState(BigInt(snapshot.state))
  const remainingSize = BigDecimal.fromRaw(snapshot.remainingSizeX5, 5)
  const executedSize = size.sub(remainingSize)
  const executedNotional = BigDecimal.fromRaw(snapshot.executedNotionalX12, 12)
  return {
    symbolMeta,
    marketMeta,
    traderId,
    positionId,
    positionSubId,
    orderId,
    clientOrderId,
    size,
    tick,
    price,
    side,
    tif,
    postOnly,
    reduceOnly,
    state,
    deadline,
    remainingSize,
    executedSize,
    executedNotional,
  }
}

export async function getOpenOrderIds({
  api,
  traderId,
  marketIds,
  blockNumber,
}: {
  api: SilverKoiApi
  traderId?: bigint
  marketIds: bigint[]
  blockNumber?: bigint
}): Promise<MarketIdAndOrderId[]> {
  if (traderId === undefined) {
    return []
  }

  type GetActiveOrderIdsReturnType = viem.ReadContractReturnType<
    typeof silverKoiAbi,
    "getActiveOrderIds"
  >

  const contracts: viem.ContractFunctionParameters[] = marketIds.map((marketId) => {
    return {
      address: api.silverKoi.address,
      abi: api.silverKoi.abi,
      functionName: "getActiveOrderIds",
      args: [marketId, traderId],
    }
  })

  const results = (await api.client.public.multicall({
    contracts,
    blockNumber,
    allowFailure: false,
    multicallAddress: api.multicall.address,
  })) as GetActiveOrderIdsReturnType[]

  const ids: MarketIdAndOrderId[] = []
  for (const [i, orderIds] of results.entries()) {
    const marketId = marketIds[i]
    for (const orderId of orderIds) {
      ids.push({ marketId, orderId })
    }
  }
  return ids
}

export async function getOpenOrderSnapshots({
  api,
  traderId,
  marketIds,
  blockNumber,
}: {
  api: SilverKoiApi
  traderId?: bigint
  marketIds: bigint[]
  blockNumber?: bigint
}): Promise<OrderSnapshot[]> {
  if (traderId === undefined) return []
  const marketAndOrderIds = await getOpenOrderIds({
    api,
    traderId,
    marketIds,
    blockNumber,
  })
  return await getOrderSnapshots({
    api,
    traderId,
    marketAndOrderIds,
    blockNumber,
  })
}

interface MarketIdAndTriggerId {
  marketId: bigint
  triggerId: bigint
}

type GetTriggerSnapshotReturnType = viem.ReadContractReturnType<
  typeof silverKoiAbi,
  "getTriggerSnapshot"
>

export async function getTriggerSnapshots({
  api,
  traderId,
  marketAndTriggerIds,
  blockNumber,
}: {
  api: SilverKoiApi
  traderId: bigint
  marketAndTriggerIds: MarketIdAndTriggerId[]
  blockNumber?: bigint
}): Promise<TriggerSnapshot[]> {
  if (traderId === undefined) return []

  const contracts: viem.ContractFunctionParameters[] = marketAndTriggerIds.map(
    ({ marketId, triggerId }) => {
      return {
        address: api.silverKoi.address,
        abi: api.silverKoi.abi,
        functionName: "getTriggerSnapshot",
        args: [marketId, triggerId],
      }
    },
  )
  const results = (await api.client.public.multicall({
    contracts,
    blockNumber,
    allowFailure: false,
    multicallAddress: api.multicall.address,
  })) as GetTriggerSnapshotReturnType[]

  const triggers = results.map((snapshot) => parseTriggerSnapshot({ api, snapshot }))
  return triggers
}

function parseTriggerSnapshot({
  api,
  snapshot,
}: {
  api: SilverKoiApi
  snapshot: GetTriggerSnapshotReturnType
}): TriggerSnapshot {
  const { postOnly, reduceOnly } = snapshot

  const marketId = BigInt(snapshot.marketId)
  const traderId = BigInt(snapshot.traderId)
  const positionId = BigInt(snapshot.positionId)
  const positionSubId = BigInt(snapshot.positionSubId)
  const triggerId = BigInt(snapshot.triggerId)
  const clientOrderId = BigInt(snapshot.clientOrderId)
  const fromAboveTriggerTick = BigInt(snapshot.fromAboveTriggerTick)
  const fromBelowTriggerTick = BigInt(snapshot.fromBelowTriggerTick)
  const fromAboveLimitTick = BigInt(snapshot.fromAboveLimitTick)
  const fromBelowLimitTick = BigInt(snapshot.fromBelowLimitTick)
  const fromAboveTriggerPriceX7 = BigInt(snapshot.fromAboveTriggerPriceX7)
  const fromBelowTriggerPriceX7 = BigInt(snapshot.fromBelowTriggerPriceX7)
  const fromAboveLimitPriceX7 = BigInt(snapshot.fromAboveLimitPriceX7)
  const fromBelowLimitPriceX7 = BigInt(snapshot.fromBelowLimitPriceX7)
  const sizeX5 = BigInt(snapshot.sizeX5)
  const targetLeverageX2 = BigInt(snapshot.targetLeverageX2)

  const marketMeta = api.markets.getByMarketId(marketId)
  const symbolMeta = api.universe.getBySymbol(marketMeta.symbol)
  const size = BigDecimal.fromRaw(sizeX5, 5)
  const fromAboveTriggerPrice = BigDecimal.fromRaw(fromAboveTriggerPriceX7, 7)
  const fromBelowTriggerPrice = BigDecimal.fromRaw(fromBelowTriggerPriceX7, 7)
  const fromAboveLimitPrice = BigDecimal.fromRaw(fromAboveLimitPriceX7, 7)
  const fromBelowLimitPrice = BigDecimal.fromRaw(fromBelowLimitPriceX7, 7)
  const side = decodeOrderSide(BigInt(snapshot.side))
  const tif = decodeTimeInForce(BigInt(snapshot.tif))
  const targetLeverage = BigDecimal.fromRaw(targetLeverageX2, 2)
  const maintainLeverage = snapshot.maintainLeverage

  return {
    symbolMeta,
    marketMeta,
    traderId,
    positionId,
    positionSubId,
    triggerId,
    clientOrderId,
    size,
    fromAboveTriggerTick,
    fromBelowTriggerTick,
    fromAboveLimitTick,
    fromBelowLimitTick,
    fromAboveTriggerPrice,
    fromBelowTriggerPrice,
    fromAboveLimitPrice,
    fromBelowLimitPrice,
    side,
    tif,
    postOnly,
    reduceOnly,
    targetLeverage,
    maintainLeverage,
  }
}

export async function getOpenTriggerIds({
  api,
  traderId,
  marketIds,
  blockNumber,
}: {
  api: SilverKoiApi
  traderId?: bigint
  marketIds: bigint[]
  blockNumber?: bigint
}): Promise<MarketIdAndTriggerId[]> {
  if (traderId === undefined) return []

  type GetActiveTriggerIdsReturnType = viem.ReadContractReturnType<
    typeof silverKoiAbi,
    "getActiveTriggerIds"
  >

  const contracts: viem.ContractFunctionParameters[] = marketIds.map((marketId) => {
    return {
      address: api.silverKoi.address,
      abi: api.silverKoi.abi,
      functionName: "getActiveTriggerIds",
      args: [marketId, traderId],
    }
  })

  const results = (await api.client.public.multicall({
    contracts,
    blockNumber,
    allowFailure: false,
    multicallAddress: api.multicall.address,
  })) as GetActiveTriggerIdsReturnType[]

  const ids: MarketIdAndTriggerId[] = []
  for (const [i, triggerIds] of results.entries()) {
    const marketId = marketIds[i]
    for (const triggerId of triggerIds) {
      ids.push({ marketId, triggerId })
    }
  }

  return ids
}

export async function getOpenTriggerSnapshots({
  api,
  traderId,
  marketIds,
  blockNumber,
}: {
  api: SilverKoiApi
  traderId?: bigint
  marketIds: bigint[]
  blockNumber?: bigint
}): Promise<TriggerSnapshot[]> {
  if (traderId === undefined) return []
  const marketAndTriggerIds = await getOpenTriggerIds({
    api,
    traderId,
    marketIds,
    blockNumber,
  })
  return await getTriggerSnapshots({
    api,
    traderId,
    marketAndTriggerIds,
    blockNumber,
  })
}

type GetPositionSnapshotReturnType = viem.ReadContractReturnType<
  typeof silverKoiAbi,
  "getPositionSnapshot"
>

// Pre: every market id in `marketId` is present in `api.markets.marketIds()`
export async function getPositionSnapshots({
  api,
  traderId,
  marketIds,
  marketSnapshots,
  blockNumber,
}: {
  api: SilverKoiApi
  traderId?: bigint
  marketIds: bigint[]
  marketSnapshots: Map<bigint, MarketSnapshot>
  blockNumber?: bigint
}): Promise<PositionSnapshot[]> {
  if (traderId === undefined) return []

  if (marketIds.length === 0) {
    throw new Error(`market ids is empty`)
  }

  const contracts: viem.ContractFunctionParameters[] = []
  for (const marketId of marketIds) {
    const marketSnapshot = marketSnapshots.get(marketId)
    if (marketSnapshot === undefined) {
      throw new Error(`no market snapshot found for market id ${marketId}`)
    }

    for (let i = 1n; i <= MAX_POSITION_SUB_IDS; ++i) {
      const positionSubId = i
      const positionId = encodePositionId({ traderId, positionSubId })
      contracts.push({
        address: api.silverKoi.address,
        abi: api.silverKoi.abi,
        functionName: "getPositionSnapshot",
        args: [marketId, positionId],
      })
    }
  }
  const results = (await api.client.public.multicall({
    contracts,
    blockNumber,
    allowFailure: false,
    multicallAddress: api.multicall.address,
  })) as GetPositionSnapshotReturnType[]
  const positions = results.map((snapshot) => parsePositionSnapshot({ api, snapshot }))
  return positions
}

function parsePositionSnapshot({
  api,
  snapshot,
}: {
  api: SilverKoiApi
  snapshot: GetPositionSnapshotReturnType
}): PositionSnapshot {
  const marketId = BigInt(snapshot.marketId)
  const traderId = BigInt(snapshot.traderId)
  const positionId = BigInt(snapshot.positionId)
  const positionSubId = BigInt(snapshot.positionSubId)

  const marketMeta = api.markets.getByMarketId(marketId)
  const symbolMeta = api.universe.getBySymbol(marketMeta.symbol)

  const size = BigDecimal.fromRaw(snapshot.sizeX5, 5)
  const openNotional = BigDecimal.fromRaw(snapshot.openNotionalX12, 12)
  const notional = BigDecimal.fromRaw(snapshot.positionNotionalX12, 12)
  const longOpenInterestSize = BigDecimal.fromRaw(snapshot.longOpenInterestSizeX5, 5)
  const longOpenInterestNotional = BigDecimal.fromRaw(snapshot.longOpenInterestNotionalX12, 12)
  const shortOpenInterestSize = BigDecimal.fromRaw(snapshot.shortOpenInterestSizeX5, 5)
  const shortOpenInterestNotional = BigDecimal.fromRaw(snapshot.shortOpenInterestNotionalX12, 12)
  const openInterestSize = BigDecimal.fromRaw(snapshot.openInterestSizeX5, 5)
  const openInterestNotional = BigDecimal.fromRaw(snapshot.openInterestNotionalX12, 12)

  const collateral = BigDecimal.fromRaw(snapshot.collateralX6, 6)
  const unrealizedPnl = BigDecimal.fromRaw(snapshot.unrealizedPnlX12, 12)
  const unsettledFunding = BigDecimal.fromRaw(snapshot.unsettledFundingX6, 6)
  const conservativeAccountValue = BigDecimal.fromRaw(snapshot.conservativeAccountValueX12, 12)
  const freeCollateral = BigDecimal.fromRaw(snapshot.imFreeCollateralX6, 6)
  const badDebt = BigDecimal.fromRaw(snapshot.badDebtX6, 6)

  const entryPrice = size.isZero()
    ? undefined
    : BigDecimal.fromRaw((snapshot.openNotionalX12 * 10n ** 3n) / snapshot.sizeX5, 10).abs()

  const conservativeLiquidationPrice = parsePriceFromFlagAndRawValue({
    flag: parsePriceFlag(BigInt(snapshot.conservativeLiquidationPriceFlag)),
    priceX10: BigInt(snapshot.conservativeLiquidationPriceX10),
  })
  const liquidationPrice = parsePriceFromFlagAndRawValue({
    flag: parsePriceFlag(BigInt(snapshot.liquidationPriceFlag)),
    priceX10: BigInt(snapshot.liquidationPriceX10),
  })
  const conservativeBankruptcyPrice = parsePriceFromFlagAndRawValue({
    flag: parsePriceFlag(BigInt(snapshot.conservativeBankruptcyPriceFlag)),
    priceX10: BigInt(snapshot.conservativeBankruptcyPriceX10),
  })
  const bankruptcyPrice =
    snapshot.bankruptcyPriceX10 === 0n
      ? undefined
      : BigDecimal.fromRaw(snapshot.bankruptcyPriceX10, 10)
  const marginRatio = snapshot.effectiveMarginRatioDefined
    ? BigDecimal.fromRaw(snapshot.effectiveMarginRatioX6, 6)
    : undefined
  const leverage = snapshot.effectiveLeverageDefined
    ? BigDecimal.fromRaw(snapshot.effectiveLeverageX2, 2)
    : undefined

  const empty =
    size.isZero() &&
    openInterestSize.isZero() &&
    openInterestNotional.isZero() &&
    collateral.isZero() &&
    badDebt.isZero()

  return {
    symbolMeta,
    marketMeta,
    traderId,
    positionId,
    positionSubId,
    size,
    openNotional,
    notional,
    longOpenInterestSize,
    longOpenInterestNotional,
    shortOpenInterestSize,
    shortOpenInterestNotional,
    openInterestSize,
    openInterestNotional,
    collateral,
    unrealizedPnl,
    unsettledFunding,
    conservativeAccountValue,
    freeCollateral,
    badDebt,
    entryPrice,
    conservativeLiquidationPrice,
    liquidationPrice,
    conservativeBankruptcyPrice,
    bankruptcyPrice,
    marginRatio,
    leverage,
    empty,
  }
}

export async function register({ api }: { api: SilverKoiApi }): Promise<viem.Hash> {
  const client = api.client.wallet
  if (!client || !client.account) throw new NoSignerAccountError()

  const { request } = await api.client.public.simulateContract({
    address: api.silverKoi.address,
    abi: api.silverKoi.abi,
    functionName: "registerMsgSender",
    account: client.account,
  })
  return await client.writeContract(request)
}

export async function approveUsdc({
  api,
  amountXS,
}: {
  api: SilverKoiApi
  amountXS?: bigint
}): Promise<viem.Hash> {
  const client = api.client.wallet
  if (!client || !client.account) throw new NoSignerAccountError()

  const { request } = await api.client.public.simulateContract({
    address: api.usdc.address,
    abi: api.usdc.abi,
    functionName: "approve",
    args: [api.vault.address, amountXS ?? viem.maxUint256],
    account: client.account,
  })
  return await client.writeContract(request)
}

export async function replenishUsdcFromFaucet({ api }: { api: SilverKoiApi }): Promise<viem.Hash> {
  const client = api.client.wallet
  if (!client || !client.account) throw new NoSignerAccountError()
  if (!api.faucet) throw new NoFaucetError()

  const { request } = await api.client.public.simulateContract({
    address: api.faucet.address,
    abi: api.faucet.abi,
    functionName: "replenishUsdc",
    account: client.account,
  })
  return await client.writeContract(request)
}

export async function cancelOrder({
  api,
  marketId,
  traderId,
  orderId,
}: {
  api: SilverKoiApi
  marketId: bigint
  traderId: bigint
  orderId: bigint
}): Promise<viem.Hash> {
  const client: viem.WalletClient | undefined = api.client.wallet
  if (!client || !client.account) throw new NoSignerAccountError()

  const { clientOrderId } = decodeOrderId(orderId)
  const msg = { traderId: Number(traderId), clientOrderId: Number(clientOrderId) }
  const packedRequest = await api.silverKoi.read.encodeCancelOrderRequest([msg])

  const { request } = await api.client.public.simulateContract({
    address: api.silverKoi.address,
    abi: api.silverKoi.abi,
    functionName: "cancelOrder",
    args: [Number(marketId), packedRequest],
    account: client.account,
  })
  return await client.writeContract(request)
}

export async function cancelTrigger({
  api,
  marketId,
  traderId,
  triggerId,
}: {
  api: SilverKoiApi
  marketId: bigint
  traderId: bigint
  triggerId: bigint
}): Promise<viem.Hash> {
  const client: viem.WalletClient | undefined = api.client.wallet
  if (!client || !client.account) throw new NoSignerAccountError()

  const { clientOrderId } = decodeTriggerId(triggerId)
  const msg = { traderId: Number(traderId), clientOrderId: Number(clientOrderId) }
  const packedRequest = await api.silverKoi.read.encodeCancelTriggerRequest([msg])

  const { request } = await api.client.public.simulateContract({
    address: api.silverKoi.address,
    abi: api.silverKoi.abi,
    functionName: "cancelTrigger",
    args: [Number(marketId), packedRequest],
    account: client.account,
  })
  return await client.writeContract(request)
}

export function encodeOrderSide(value: OrderSide): bigint {
  switch (value) {
    case "bid":
      return 0n
    case "ask":
      return 1n
  }
}

export function decodeOrderSide(value: bigint): OrderSide {
  switch (value) {
    case 0n:
      return "bid"
    case 1n:
      return "ask"
    default: {
      throw new Error(`unknown value for OrderSide: ${value}`)
    }
  }
}

export function encodeTimeInForce(value: TimeInForce): bigint {
  switch (value) {
    case "ioc": {
      return 0n
    }
    case "fok": {
      return 1n
    }
    case "gtc": {
      return 2n
    }
    case "gtd": {
      return 3n
    }
    default: {
      throw new Error(`unknown value for TimeInForce: ${value}`)
    }
  }
}

export function decodeTimeInForce(value: bigint): TimeInForce {
  switch (value) {
    case 0n: {
      return "ioc"
    }
    case 1n: {
      return "fok"
    }
    case 2n: {
      return "gtc"
    }
    case 3n: {
      return "gtd"
    }
    default: {
      throw new Error(`unknown value for TimeInForce: ${value}`)
    }
  }
}

function decodeOrderState(value: bigint): OrderState {
  switch (value) {
    case 0n: {
      return "created"
    }
    case 1n: {
      return "onbook"
    }
    case 2n: {
      return "inactive"
    }
    default: {
      throw new Error(`unknown value for OrderState: ${value}`)
    }
  }
}

function parsePriceFlag(flag: bigint): LiquidationPriceFlag {
  switch (flag) {
    case 0n:
      return "defined"
    case 1n:
      return "undefined"
    case 2n:
      return "liquidatable"
    default: {
      throw new Error(`unknown price flag: ${flag}`)
    }
  }
}

// Converts the price flag and raw price value into a BigDecimal.
// If the flag is "undefined", then the resulting price is undefined.
// If the flag is "liquidatable", then the resulting price is the singleton type
// CurrentPrice.
function parsePriceFromFlagAndRawValue({
  flag,
  priceX10,
}: {
  flag: LiquidationPriceFlag
  priceX10: bigint
}): BigDecimal | CurrentPrice | undefined {
  switch (flag) {
    case "defined": {
      return BigDecimal.fromRaw(priceX10, 10)
    }
    case "undefined": {
      return undefined
    }
    case "liquidatable": {
      return CURRENT_PRICE
    }
    default: {
      throw new Error(`unknown price flag: ${flag}`)
    }
  }
}

type DepositCollateralArgs = viem.ReadContractParameters<
  typeof silverKoiAbi,
  "encodeDepositCollateralRequest"
>["args"][0]
type DepositCollateralInput = ShallowMapProp<DepositCollateralArgs, number, bigint>

function fromDepositCollateralInput(obj: DepositCollateralInput): DepositCollateralArgs {
  return {
    ...obj,
    traderId: Number(obj.traderId),
    positionSubId: Number(obj.positionSubId),
  }
}

export async function simDepositCollateral({
  api,
  marketId,
  request,
}: {
  api: SilverKoiApi
  marketId: bigint
  request: DepositCollateralInput
}): Promise<OperationTransactionSimulationResult> {
  const client: viem.WalletClient | undefined = api.client.wallet
  if (!client || !client.account) throw new NoSignerAccountError()

  const rawRequest = fromDepositCollateralInput(request)
  const packedRequest = await api.silverKoi.read.encodeDepositCollateralRequest([rawRequest])

  const commonArgs = {
    address: api.silverKoi.address,
    abi: api.silverKoi.abi,
    args: [Number(marketId), packedRequest],
    account: client.account,
  } as const

  const { result } = await api.client.public.simulateContract({
    ...commonArgs,
    functionName: "simDepositCollateral",
  })
  const [oldPosition, newPosition] = result

  const txHashFn = async () => {
    const { request } = await api.client.public.simulateContract({
      ...commonArgs,
      functionName: "depositCollateral",
    })
    return await client.writeContract(request)
  }

  return {
    oldPosition: parsePositionSnapshot({ api, snapshot: oldPosition }),
    newPosition: parsePositionSnapshot({ api, snapshot: newPosition }),
    enqueued: false,
    txHashFn,
  }
}

type WithdrawCollateralArgs = viem.ReadContractParameters<
  typeof silverKoiAbi,
  "encodeWithdrawCollateralRequest"
>["args"][0]
type WithdrawCollateralInput = ShallowMapProp<WithdrawCollateralArgs, number, bigint>

function fromWithdrawCollateralInput(obj: WithdrawCollateralInput): WithdrawCollateralArgs {
  return {
    ...obj,
    traderId: Number(obj.traderId),
    positionSubId: Number(obj.positionSubId),
  }
}

export async function simWithdrawCollateral({
  api,
  marketId,
  request,
}: {
  api: SilverKoiApi
  marketId: bigint
  request: WithdrawCollateralInput
}): Promise<OperationTransactionSimulationResult> {
  const client: viem.WalletClient | undefined = api.client.wallet
  if (!client || !client.account) throw new NoSignerAccountError()

  const rawRequest = fromWithdrawCollateralInput(request)
  const packedRequest = await api.silverKoi.read.encodeWithdrawCollateralRequest([rawRequest])

  const commonArgs = {
    address: api.silverKoi.address,
    abi: api.silverKoi.abi,
    args: [Number(marketId), packedRequest],
    account: client.account,
  } as const

  const { result } = await api.client.public.simulateContract({
    ...commonArgs,
    functionName: "simWithdrawCollateral",
  })
  const [oldPosition, newPosition] = result

  const txHashFn = async () => {
    const { request } = await api.client.public.simulateContract({
      ...commonArgs,
      functionName: "withdrawCollateral",
    })
    return await client.writeContract(request)
  }

  return {
    oldPosition: parsePositionSnapshot({ api, snapshot: oldPosition }),
    newPosition: parsePositionSnapshot({ api, snapshot: newPosition }),
    enqueued: false,
    txHashFn,
  }
}

type CreateTriggerArgs = viem.ReadContractParameters<
  typeof silverKoiAbi,
  "encodeCreateTriggerRequest"
>["args"][0]
type CreateTriggerInput = ShallowMapProp<CreateTriggerArgs, number, bigint>

function fromCreateTriggerInput(obj: CreateTriggerInput): CreateTriggerArgs {
  return {
    ...obj,
    traderId: Number(obj.traderId),
    clientOrderId: Number(obj.clientOrderId),
    positionSubId: Number(obj.positionSubId),
    side: Number(obj.side),
    fromAboveTriggerTick: Number(obj.fromAboveTriggerTick),
    fromBelowTriggerTick: Number(obj.fromBelowTriggerTick),
    fromAboveLimitTick: Number(obj.fromAboveLimitTick),
    fromBelowLimitTick: Number(obj.fromBelowLimitTick),
    sizeX5: Number(obj.sizeX5),
    tif: Number(obj.tif),
    targetLeverageX2: Number(obj.targetLeverageX2),
  }
}

export async function simCreateTrigger({
  api,
  marketId,
  request,
}: {
  api: SilverKoiApi
  marketId: bigint
  request: CreateTriggerInput
}): Promise<CreateTriggerSimulationResult> {
  const client: viem.WalletClient | undefined = api.client.wallet
  if (!client || !client.account) throw new NoSignerAccountError()

  const rawRequest = fromCreateTriggerInput(request)
  const packedRequest = await api.silverKoi.read.encodeCreateTriggerRequest([rawRequest])

  const commonArgs = {
    address: api.silverKoi.address,
    abi: api.silverKoi.abi,
    args: [Number(marketId), packedRequest],
    account: client.account,
  } as const

  const { result } = await api.client.public.simulateContract({
    ...commonArgs,
    functionName: "simCreateTrigger",
  })
  const [oldPosition, newPosition, trigger] = result

  const txHashFn = async () => {
    const { request } = await api.client.public.simulateContract({
      ...commonArgs,
      functionName: "createTrigger",
    })
    return await client.writeContract(request)
  }

  return {
    oldPosition: parsePositionSnapshot({ api, snapshot: oldPosition }),
    newPosition: parsePositionSnapshot({ api, snapshot: newPosition }),
    trigger: parseTriggerSnapshot({ api, snapshot: trigger }),
    enqueued: false,
    txHashFn,
  }
}

type CancelTriggerArgs = viem.ReadContractParameters<
  typeof silverKoiAbi,
  "encodeCancelTriggerRequest"
>["args"][0]
type CancelTriggerInput = ShallowMapProp<CancelTriggerArgs, number, bigint>

function fromCancelTriggerInput(obj: CancelTriggerInput): CancelTriggerArgs {
  return {
    traderId: Number(obj.traderId),
    clientOrderId: Number(obj.clientOrderId),
  }
}

export async function simReplaceTrigger({
  api,
  marketId,
  cancelRequest,
  createRequest,
}: {
  api: SilverKoiApi
  marketId: bigint
  cancelRequest: CancelTriggerInput
  createRequest: CreateTriggerInput
}): Promise<ReplaceTriggerSimulationResult> {
  const client: viem.WalletClient | undefined = api.client.wallet
  if (!client || !client.account) throw new NoSignerAccountError()

  const packedCancelRequest = await api.silverKoi.read.encodeCancelTriggerRequest([
    fromCancelTriggerInput(cancelRequest),
  ])
  const packedCreateRequest = await api.silverKoi.read.encodeCreateTriggerRequest([
    fromCreateTriggerInput(createRequest),
  ])

  const commonArgs = {
    address: api.silverKoi.address,
    abi: api.silverKoi.abi,
    args: [Number(marketId), packedCancelRequest, packedCreateRequest],
    account: client.account,
  } as const

  const { result } = await api.client.public.simulateContract({
    ...commonArgs,
    functionName: "simReplaceTrigger",
  })
  const [oldTrigger, newTrigger] = result

  const txHashFn = async () => {
    const { request } = await api.client.public.simulateContract({
      ...commonArgs,
      functionName: "replaceTrigger",
    })
    return await client.writeContract(request)
  }

  return {
    oldTrigger: parseTriggerSnapshot({ api, snapshot: oldTrigger }),
    newTrigger: parseTriggerSnapshot({ api, snapshot: newTrigger }),
    enqueued: false,
    txHashFn,
  }
}

type PlaceOrderArgs = viem.ReadContractParameters<
  typeof silverKoiAbi,
  "encodePlaceOrderRequest"
>["args"][0]
type PlaceOrderInput = ShallowMapProp<PlaceOrderArgs, number, bigint>

function fromPlaceOrderInput(obj: PlaceOrderInput): PlaceOrderArgs {
  return {
    ...obj,
    traderId: Number(obj.traderId),
    clientOrderId: Number(obj.clientOrderId),
    positionSubId: Number(obj.positionSubId),
    side: Number(obj.side),
    tick: Number(obj.tick),
    sizeX5: Number(obj.sizeX5),
    tif: Number(obj.tif),
    deadline: Number(obj.deadline),
    targetLeverageX2: Number(obj.targetLeverageX2),
  }
}

export async function simPlaceOrder({
  api,
  marketId,
  request,
}: {
  api: SilverKoiApi
  marketId: bigint
  request: PlaceOrderInput
}): Promise<OperationTransactionSimulationResult> {
  const client: viem.WalletClient | undefined = api.client.wallet
  if (!client || !client.account) throw new NoSignerAccountError()

  const rawRequest = fromPlaceOrderInput(request)
  const packedRequest = await api.silverKoi.read.encodePlaceOrderRequest([rawRequest])

  const commonArgs = {
    address: api.silverKoi.address,
    abi: api.silverKoi.abi,
    args: [Number(marketId), packedRequest],
    account: client.account,
  } as const

  const { result } = await api.client.public.simulateContract({
    ...commonArgs,
    functionName: "simPlaceOrder",
  })
  const [oldPosition, newPosition, enqueued] = result

  const txHashFn = async () => {
    const { request } = await api.client.public.simulateContract({
      ...commonArgs,
      functionName: "placeOrder",
    })
    return await client.writeContract(request)
  }

  return {
    oldPosition: parsePositionSnapshot({ api, snapshot: oldPosition }),
    newPosition: parsePositionSnapshot({ api, snapshot: newPosition }),
    enqueued,
    txHashFn,
  }
}

function decodeShortString20(data: viem.Hex): string {
  const bytes = viem.toBytes(data)
  if (bytes.length !== 20) {
    throw new Error(`invalid data for ShortString20: ${data}`)
  }

  // The last byte is the length of the short string, so we ignore that and
  // parse the rest, dropping nil bytes.
  let str = viem.bytesToString(bytes.slice(0, -1))
  str = str.replace(/\0*$/g, "")
  return str
}
