import { z } from "zod"

import UNIVERSE_DATA from "./data/universe.json"

const SymbolMetaSchema = z.object({
  name: z.string(),
  symbol: z.string(),
  displayName: z.string(),
})

export type SymbolMeta = z.infer<typeof SymbolMetaSchema>

export class Universe {
  private _metaBySymbol: Map<string, SymbolMeta>
  private _metaByName: Map<string, SymbolMeta>
  private _metaByDisplayName: Map<string, SymbolMeta>

  constructor({
    metaBySymbol,
    metaByName,
    metaByDisplayName,
  }: {
    metaBySymbol: Map<string, SymbolMeta>
    metaByName: Map<string, SymbolMeta>
    metaByDisplayName: Map<string, SymbolMeta>
  }) {
    this._metaBySymbol = metaBySymbol
    this._metaByName = metaByName
    this._metaByDisplayName = metaByDisplayName
  }

  getBySymbol(symbol: string): SymbolMeta {
    const meta = this._metaBySymbol.get(symbol.toLowerCase())
    if (meta === undefined) {
      throw new Error(`symbol not found: ${symbol}`)
    }
    return meta
  }

  getByName(name: string): SymbolMeta {
    const meta = this._metaByName.get(name.toLowerCase())
    if (meta === undefined) {
      throw new Error(`name not found: ${name}`)
    }
    return meta
  }

  getByDisplayName(name: string): SymbolMeta {
    const meta = this._metaByDisplayName.get(name.toLowerCase())
    if (meta === undefined) {
      throw new Error(`display name not found: ${name}`)
    }
    return meta
  }
}

function buildUniverse(): Universe {
  const metaBySymbol = new Map<string, SymbolMeta>()
  const metaByName = new Map<string, SymbolMeta>()
  const metaByDisplayName = new Map<string, SymbolMeta>()
  for (const raw of UNIVERSE_DATA.symbols) {
    const meta = SymbolMetaSchema.parse(raw)
    metaBySymbol.set(meta.symbol.toLowerCase(), meta)
    metaByName.set(meta.name.toLowerCase(), meta)
    metaByDisplayName.set(meta.displayName.toLowerCase(), meta)
  }

  return new Universe({ metaBySymbol, metaByName, metaByDisplayName })
}

const UNIVERSE = buildUniverse()

export function getUniverse(): Universe {
  return UNIVERSE
}
