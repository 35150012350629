export type Chain = "localhost" | "arbitrum-one" | "arbitrum-sepolia" | "plume-testnet"

export const getSupportedChains = (): Chain[] => {
  return ["localhost", "arbitrum-one", "arbitrum-sepolia", "plume-testnet"]
}

export const getChain = (chainId: bigint): Chain => {
  for (const chain of getSupportedChains()) {
    if (chainId === getChainId(chain)) {
      return chain
    }
  }
  throw new Error(`unsupported chain id: ${chainId}`)
}

export const getChainId = (chain: Chain): bigint => {
  switch (chain) {
    case "localhost":
      return 1337n
    case "arbitrum-one":
      return 42161n
    case "arbitrum-sepolia":
      return 421614n
    case "plume-testnet":
      return 161221135n
  }
}

export const validateChain = (chain: string): Chain => {
  switch (chain as Chain) {
    case "localhost":
    case "arbitrum-one":
    case "arbitrum-sepolia":
    case "plume-testnet":
      return chain as Chain
    default:
      throw new Error(`unknown chain: ${chain}`)
  }
}

export const isTestnet = (chain: Chain): boolean => {
  switch (chain) {
    case "localhost":
    case "arbitrum-sepolia":
    case "plume-testnet":
      return true
    default:
      return false
  }
}

export const getChainName = (chain: Chain): string => {
  switch (chain) {
    case "localhost":
      return "Localhost"
    case "arbitrum-one":
      return "Arbitrum One"
    case "arbitrum-sepolia":
      return "Arbitrum Sepolia"
    case "plume-testnet":
      return "Plume Testnet"
  }
}
