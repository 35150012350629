import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { createWeb3Modal } from "@web3modal/wagmi/react"
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config"
import { ReactNode } from "react"
import { WagmiProvider } from "wagmi"
import { plumeTestnet } from "wagmi/chains"

import { env } from "~/env"
import { getChainLogo } from "../utils"

const queryClient = new QueryClient()

const projectId = env.VITE_WALLET_CONNECT_PROJECT_ID
const metadata = {
  name: "Silver Koi",
  description: "RWA perps DEX for pro traders",
  url: "https://app-alpha.silverkoi.io",
  icons: [],
}

const chains = [plumeTestnet] as const

const config = defaultWagmiConfig({
  chains,
  metadata,
  projectId,
})

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  chainImages: {
    421614: getChainLogo({ chain: "arbitrum-sepolia" }),
    161221135: getChainLogo({ chain: "plume-testnet", padded: true }),
  },
  featuredWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", // Metamask
    "38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662", // BitGet
  ],
})

export function Web3ModalProvider({ children }: { children?: ReactNode }) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}
