import { PropsWithChildren, useState } from "react"

import { MobileContext } from "./MobileContext"

interface Props {}

export const MobileContextProvider = ({ children }: PropsWithChildren<Props>) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [walletMenuOpen, setWalletMenuOpen] = useState<boolean>(false)
  const value = {
    menuOpen,
    setMenuOpen,
    walletMenuOpen,
    setWalletMenuOpen,
  }
  return <MobileContext.Provider value={value}>{children}</MobileContext.Provider>
}
