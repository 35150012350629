import { Address } from "viem"

import { Chain } from "./chain"

export const getDefaultMulticall3Address = (chain: Chain): Address => {
  switch (chain) {
    case "plume-testnet":
      return "0x4Ec1C7D7a6B12481e4e5EcFD057F5F7EACecE910"
    default:
      return "0xcA11bde05977b3631167028862bE2a173976CA11"
  }
}
