import { useWeb3Modal } from "@web3modal/wagmi/react"
import { SyntheticEvent } from "react"
import { useAccount } from "wagmi"

import * as sk from "silverkoi"
import * as skoi from "~/api/silverkoi"
import {
  useChainDetail,
  useNeedNativeToken,
  useSilverKoiApi,
  useSimulationResult,
  useSubmitTx,
  useTraderId,
} from "~/hooks"
import { tw2 } from "~/utils"
import { Button } from "./Button"

export interface SubmitButtonProps {
  simEnabled: boolean
  onSuccess: () => void
  txDescription: string
  idleText: string
  pendingText: string
}

export const SubmitButton = ({
  simEnabled,
  onSuccess,
  txDescription,
  idleText,
  pendingText,
}: SubmitButtonProps) => {
  const { isConnected } = useAccount()
  const { open } = useWeb3Modal()

  const { data: api } = useSilverKoiApi()
  const { data: simResult } = useSimulationResult({ enabled: simEnabled })

  const { isPending: traderIdIsPending, data: traderId } = useTraderId()
  const requiredApproveAmount = skoi.getRequiredApproveAmount(simResult)
  const submitTx = useSubmitTx()

  const { faucetUrl, nativeCurrency } = useChainDetail()
  const needNativeToken = useNeedNativeToken()

  const onConnectClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    open()
  }

  const onRegisterClick = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!api) return
    const txHashFn = async () => {
      return await sk.register({ api })
    }
    submitTx.mutate({ description: "register", txHashFn })
  }

  const onApproveClick = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!api) return
    const txHashFn = async () => {
      return await sk.approveUsdc({ api })
    }
    submitTx.mutate({ description: "approve", txHashFn })
  }

  const onSubmitClick = async (e: SyntheticEvent) => {
    e.preventDefault()
    if (!simResult || simResult.err) return
    const { txHashFn } = simResult.val
    submitTx.mutate({ description: txDescription, txHashFn }, { onSuccess })
  }

  const onGetTokenClick = async (e: SyntheticEvent) => {
    e.preventDefault()
    window.open(faucetUrl, "_blank")
  }

  const { disabled, onClick, text } = (() => {
    if (!isConnected) {
      return {
        disabled: false,
        onClick: onConnectClick,
        text: "Connect Wallet",
      }
    } else if (faucetUrl && needNativeToken) {
      return {
        onClick: onGetTokenClick,
        disabled: false,
        text: `Get ${nativeCurrency.name}`,
      }
    } else if (!traderId) {
      if (traderIdIsPending) {
        return {
          disabled: true,
          onClick: undefined,
          text: "Connecting ...",
        }
      } else {
        return {
          disabled: submitTx.isPending,
          onClick: onRegisterClick,
          text: submitTx.isPending ? "Registering ..." : "Register",
        }
      }
    } else if (submitTx.isPending && submitTx.variables.description === "approve") {
      return {
        onClick: undefined,
        disabled: true,
        text: "Approving...",
      }
    } else if (requiredApproveAmount !== 0n) {
      return {
        onClick: onApproveClick,
        disabled: false,
        text: "Approve",
      }
    } else {
      const text = submitTx.isPending ? pendingText : idleText
      const disabled = !simResult || simResult.err || submitTx.isPending
      return {
        onClick: onSubmitClick,
        text,
        disabled,
      }
    }
  })()

  return (
    <Button
      className={
        "shrink-0 w-full h-[2rem] items-center justify-center " +
        "background-gradient-bright rounded-xl border-none " +
        `${tw2("font-submit-button")} text-neutral-01`
      }
      disabled={disabled}
      onClick={onClick}
    >
      <p>{text}</p>
    </Button>
  )
}
