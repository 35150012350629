import { Chain, SymbolMeta, getUniverse } from "silverkoi"

export function getSupportedSymbols(chain: Chain): string[] {
  switch (chain) {
    case "arbitrum-sepolia":
      return ["PPG", "BAYC"]
    case "plume-testnet":
      return [
        "ETHUSD",
        "BTCUSD",
        "NVDAUSD",
        "TSLAUSD",
        "EURUSD",
        "GBPUSD",
        "XAUUSD",
        "XAGUSD",
        "WTIUSD",
      ]
    default:
      return ["PPG", "XAUUSD", "WTIUSD"]
  }
}

export function getDefaultSymbol(chain: Chain): string {
  return getSupportedSymbols(chain).at(0)!
}

export function getSupportedSymbolMetas(chain: Chain): SymbolMeta[] {
  const universe = getUniverse()
  return getSupportedSymbols(chain).map((symbol) => universe.getBySymbol(symbol))
}

export function getSymbolMeta(symbol: string): SymbolMeta {
  return getUniverse().getBySymbol(symbol)
}

export type SymbolConfig = {
  decimals: number
  maxLeverage: number
}

export const getSymbolConfig = (symbol: string): SymbolConfig => {
  switch (symbol) {
    case "ETHUSD":
      return { decimals: 2, maxLeverage: 50 }
    case "BTCUSD":
      return { decimals: 2, maxLeverage: 50 }
    case "NVDAUSD":
      return { decimals: 2, maxLeverage: 50 }
    case "TSLAUSD":
      return { decimals: 2, maxLeverage: 50 }
    case "EURUSD":
      return { decimals: 5, maxLeverage: 200 }
    case "GBPUSD":
      return { decimals: 5, maxLeverage: 200 }
    case "XAUUSD":
      return { decimals: 3, maxLeverage: 150 }
    case "XAGUSD":
      return { decimals: 3, maxLeverage: 150 }
    case "WTIUSD":
      return { decimals: 3, maxLeverage: 150 }
    default:
      throw new Error(`no config defined for symbol ${symbol}`)
  }
}
