import { useWeb3Modal } from "@web3modal/wagmi/react"
import { SyntheticEvent } from "react"
import { FaFaucetDrip } from "react-icons/fa6"
import { useAccount } from "wagmi"

import * as sk from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import * as skoi from "~/api/silverkoi"
import { Button } from "~/components/Button"
import { Icon } from "~/components/icons"
import {
  useChainDetail,
  useMobileMainPageContext,
  useNeedNativeToken,
  useNeedUsd,
  useSilverKoiApi,
  useSimulationResult,
  useSubmitTx,
  useTradeContext,
  useTradeContextActions,
  useTraderId,
} from "~/hooks"
import { OperationInput } from "~/types"
import { USD_SYMBOL, tw } from "~/utils"
import { EditPositionViewData, ReducePositionViewData, ViewData } from "./MobileMainPageContext"

const GRADIENT_BUTTON =
  "shrink-0 w-full h-[3rem] items-center justify-center background-gradient-bright border-none " +
  tw("font-button-1") +
  " text-neutral-01"

export const MobileMainButton = () => {
  const { isConnected } = useAccount()
  const { open } = useWeb3Modal()
  const { symbolMeta, viewData, setViewData } = useMobileMainPageContext()

  const simEnabled = viewData.type === "trade"
  const { data: api } = useSilverKoiApi()
  const { data: simResult } = useSimulationResult({ enabled: simEnabled })

  const { isPending: traderIdIsPending, data: traderId } = useTraderId()
  const requiredApproveAmount = skoi.getRequiredApproveAmount(simResult)
  const submitTx = useSubmitTx()

  const { faucetUrl, nativeCurrency } = useChainDetail()
  const needNativeToken = useNeedNativeToken()
  const needUsd = useNeedUsd()

  const { reset, setInputMode, setOrderType, setTif, setLeverage, setSymbol } =
    useTradeContextActions()

  const buttonData = useButtonData({ viewData })

  const resetInputs = () => {
    reset()
    setSymbol(symbolMeta.symbol)
    setInputMode("NewPosition")
    setOrderType("market")
    setTif("gtc")
    setLeverage({ value: skoi.MAX_LEVERAGE, text: skoi.MAX_LEVERAGE.toStringTrimmed() })
  }

  const onSuccess = () => {
    resetInputs()
    setViewData({ type: "overview", symbolMeta, initialTab: "orderbook" })
  }

  const onConnectClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    open()
  }

  const onRegisterClick = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!api) return
    const txHashFn = async () => {
      return await sk.register({ api })
    }
    submitTx.mutate({ description: "register", txHashFn })
  }

  const onApproveClick = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!api) return
    const txHashFn = async () => {
      return await sk.approveUsdc({ api })
    }
    submitTx.mutate({ description: "approve", txHashFn })
  }

  const onGetTokenClick = async (e: SyntheticEvent) => {
    e.preventDefault()
    window.open(faucetUrl, "_blank")
  }

  const onGetUsdClick = async (e: SyntheticEvent) => {
    e.preventDefault()
    if (!api || !api.faucet) return
    const txHashFn = async () => {
      return await sk.replenishUsdcFromFaucet({ api })
    }
    submitTx.mutate({ description: "get testnet token", txHashFn })
  }

  const buttonProps = (() => {
    if (!isConnected) {
      return {
        disabled: false,
        onClick: onConnectClick,
        icon: (
          <div className="w-[1rem] h-[1rem]">
            <Icon name="wallet" iconFill="white" />
          </div>
        ),
        text: "Connect Wallet",
      }
    } else if (faucetUrl && needNativeToken) {
      return {
        onClick: onGetTokenClick,
        disabled: false,
        icon: <FaFaucetDrip size="1rem" color="white" />,
        text: `Get ${nativeCurrency.name}`,
      }
    } else if (!traderId) {
      if (traderIdIsPending) {
        return {
          disabled: true,
          onClick: undefined,
          text: "Connecting ...",
        }
      } else {
        return {
          disabled: submitTx.isPending,
          onClick: onRegisterClick,
          text: submitTx.isPending ? "Registering ..." : "Register",
        }
      }
    } else if (api && api.faucet && needUsd) {
      return {
        onClick: onGetUsdClick,
        disabled: false,
        icon: <FaFaucetDrip size="1rem" color="white" />,
        text: `Get ${USD_SYMBOL}`,
      }
    } else if (viewData.type === "overview") {
      return {
        disabled: false,
        onClick: () => setViewData({ type: "trade", symbolMeta }),
        text: "Trade",
      }
    } else if (submitTx.isPending && submitTx.variables.description === "approve") {
      return {
        onClick: undefined,
        disabled: true,
        text: "Approving...",
      }
    } else if (requiredApproveAmount !== 0n) {
      return {
        onClick: onApproveClick,
        disabled: false,
        text: "Approve",
      }
    } else if (buttonData) {
      const { txDescription, idleText, pendingText } = buttonData
      const text = submitTx.isPending ? pendingText : idleText
      const disabled = !simResult || simResult.err || submitTx.isPending

      const onSubmitClick = async (e: SyntheticEvent) => {
        e.preventDefault()
        if (!simResult || simResult.err) return
        const { txHashFn } = simResult.val
        submitTx.mutate({ description: txDescription, txHashFn }, { onSuccess })
      }

      return {
        onClick: onSubmitClick,
        text,
        disabled,
      }
    }
  })()

  return (
    <div className="flex grid-cols-2 w-full">
      <div className="flex w-full">
        {buttonProps && (
          <Button
            className={GRADIENT_BUTTON}
            disabled={buttonProps.disabled}
            onClick={buttonProps.onClick}
          >
            <div className="flex gap-2">
              {buttonProps.icon && buttonProps.icon}
              <p>{buttonProps.text}</p>
            </div>
          </Button>
        )}
      </div>
    </div>
  )
}

const useButtonData = ({ viewData }: { viewData: ViewData }) => {
  const tradeContext = useTradeContext()
  const { useTradeContextStore } = tradeContext
  const input = useTradeContextStore((s) => s.input)

  switch (viewData.type) {
    case "trade":
      return getTradeViewButtonData({ input })
    case "edit-position":
      return getEditPositionViewButtonData({ data: viewData, input })
    case "reduce-position":
      return getReducePositionViewButtonData({ data: viewData })
    case "create-trigger":
      return getCreateTriggerViewButtonData({ input })
    case "edit-trigger":
      return getEditTriggerViewButtonData()
    default:
      return undefined
  }
}

const getTradeViewButtonData = ({ input }: { input: OperationInput }) => {
  const { side, type: orderType } = input
  const direction = side === "bid" ? "Long" : "Short"
  switch (orderType) {
    case "market":
      return {
        txDescription: "new market order",
        idleText: `Open ${direction} Position`,
        pendingText: `Opening ${direction} Position ...`,
      }

    case "limit":
      return {
        txDescription: "new limit order",
        idleText: `Open ${direction} Position`,
        pendingText: `Opening ${direction} Position ...`,
      }

    case "stop-limit":
      return {
        txDescription: "new stop limit order",
        idleText: `Create ${direction} Stop Limit Order`,
        pendingText: `Creating ${direction} Stop Limit Order ...`,
      }
    default:
      throw new Error(`unknown order type: ${orderType}`)
  }
}

const getEditPositionViewButtonData = ({
  data,
  input,
}: {
  data: EditPositionViewData
  input: OperationInput
}) => {
  const { position: positionData } = data
  const { position } = positionData
  const posDir = position.size.gt(BigDecimal.zero()) ? "Long" : "Short"
  switch (input.inputMode) {
    case "WithdrawCollateral":
      return {
        txDescription: "withdraw collateral",
        idleText: "Withdraw Collateral",
        pendingText: "Withdrawing Collateral ...",
      }
    case "DepositCollateral":
      return {
        txDescription: "deposit collateral",
        idleText: "Deposit Collateral",
        pendingText: "Depositing Collateral ...",
      }
    case "AddPosition":
      return {
        txDescription: "add position",
        idleText: `Add ${posDir} Position`,
        pendingText: `Adding ${posDir} Position ...`,
      }
    case "ReducePosition":
      return {
        txDescription: "reduce position",
        idleText: `Reduce ${posDir} Position`,
        pendingText: `Reducing ${posDir} Position ...`,
      }
  }
}

const getReducePositionViewButtonData = ({ data }: { data: ReducePositionViewData }) => {
  const position = data.position.position
  const posDir = position.size.gt(BigDecimal.zero()) ? "Long" : "Short"
  const idleText = `Close ${posDir} Position`
  const pendingText = `Closing ${posDir} Position ...`
  return {
    txDescription: "reduce position",
    idleText,
    pendingText,
  }
}

const getCreateTriggerViewButtonData = ({ input }: { input: OperationInput }) => {
  const { slTriggerPrice, tpTriggerPrice } = input
  const triggerType = (() => {
    const hasTP = tpTriggerPrice.value && !tpTriggerPrice.value.isZero()
    const hasSL = slTriggerPrice.value && !slTriggerPrice.value.isZero()
    if (hasSL && hasTP) {
      return "Two-Sided Trigger"
    } else if (hasSL) {
      return "Stop-Loss Trigger"
    } else if (hasTP) {
      return "Take-Profit Trigger"
    } else {
      return "Trigger"
    }
  })()
  const idleText = `Create ${triggerType}`
  const pendingText = `Creating ${triggerType} ...`

  return {
    txDescription: "create sltp trigger",
    idleText,
    pendingText,
  }
}

const getEditTriggerViewButtonData = () => {
  return {
    txDescription: "replace trigger",
    idleText: "Submit",
    pendingText: "Submitting...",
  }
}
