import { PropsWithChildren, ReactNode } from "react"

import * as sk from "silverkoi"

import { getSymbolLogo, tw } from "~/utils"
import { ScrollableArea } from "../../components/ScrollableArea"
import { CircleImage } from "../CircleImage"

const logoSize = "1.75rem"
const symbolFontCs = "font-sans text-[1rem] leading-[1.125em] tracking-[-0.01em] font-semibold"
const textFontCs = "font-sans text-[0.8125rem] leading-[1.125em] tracking-[-0.01em]"
const valueFontCs = `${textFontCs} font-bold text-white bg-neutral-06`
const labelFontCs = `${textFontCs} font-bold text-neutral-04`
;("font-sans text-[0.8125rem] leading-[0.9rem] tracking-[-0.01em] text-white font-bold")

type Props = {
  symbolMeta: sk.SymbolMeta
  buttons: ReactNode
  data: DetailCellData[]
}

export type DetailCellData = {
  label: string
  value: ReactNode
}

export const MobileDetailView = ({ symbolMeta, buttons, data }: Props) => {
  const cells = data.map(({ label, value }, i) => (
    <Cell key={i} label={label}>
      {value}
    </Cell>
  ))

  return (
    <ScrollableArea className="px-3">
      <div className="shrink-0 h-4" />

      <div className="flex flex-wrap w-full items-center gap-3 text-white">
        <SymbolSection symbolMeta={symbolMeta} />

        {buttons}
      </div>

      <div className="flex grid grid-cols-2">{cells}</div>
    </ScrollableArea>
  )
}

type CellProps = {
  label: string
}

const Cell = ({ label, children }: PropsWithChildren<CellProps>) => {
  return (
    <div className="flex flex-col">
      <div className={`flex h-10 px-5 items-center ${labelFontCs}`}>{label}</div>
      <div className={`flex h-10 px-5 items-center ${valueFontCs}`}>{children}</div>
    </div>
  )
}

const SymbolSection = ({ symbolMeta }: Pick<Props, "symbolMeta">) => {
  const { symbol, name, displayName } = symbolMeta
  return (
    <div className="shrink-0 flex gap-3">
      <CircleImage src={getSymbolLogo(symbol)} size={logoSize} />
      <div className={`shrink-0 flex items-center ${symbolFontCs} text-white truncate`}>{name}</div>
      <div className={`shrink-0 flex items-center ${symbolFontCs} ${tw("font-gradient")} truncate`}>
        {displayName}
      </div>
    </div>
  )
}
