import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react"

import * as sk from "silverkoi"

import { getChainLogo, tw2 } from "~/utils"
import { Button } from "./Button"

export const NetworkButton = () => {
  const { selectedNetworkId } = useWeb3ModalState()
  const chainId = selectedNetworkId
    ? BigInt(selectedNetworkId as unknown as number) // Type definition is wrong
    : undefined
  const chain = chainId ? sk.getChain(chainId) : undefined

  const { open } = useWeb3Modal()

  const onClick = () => {
    open({ view: "Networks" })
  }

  const text = chain ? sk.getChainName(chain) : "Select Network"

  return (
    <Button
      className={
        `flex flex-row px-3 ${tw2("button-navbar")} ` +
        `bg-neutral-07 rounded-[1rem] border-neutral-05 border-[0.1rem]`
      }
      onClick={onClick}
    >
      <div className="flex items-center gap-2">
        <img src={getChainLogo({ chain })} className="w-[0.75rem] h-[0.75rem]" />
        <div className={`${tw2("font-navbar-button")} font-semibold`}>{text}</div>
      </div>
    </Button>
  )
}
