import { useWeb3Modal } from "@web3modal/wagmi/react"
import { useAccount } from "wagmi"

import { useTraderId } from "~/hooks"
import { tw2 } from "~/utils"
import { Button } from "./Button"
import { Icon } from "./icons"

interface Props {
  widthCs: string
}

export const ConnectButton = ({ widthCs }: Props) => {
  const { isConnected } = useAccount()
  const { open } = useWeb3Modal()
  const onConnectClick = () => {
    if (isConnected) return
    open()
  }

  const { isPending } = useTraderId()
  const text = isPending ? "Connecting ..." : "Connect Wallet"

  return (
    <Button
      className={
        `flex flex-row px-5 ${widthCs} ${tw2("button-navbar")} ` +
        `rounded-lg background-gradient-bright border-none`
      }
      onClick={onConnectClick}
      disabled={isPending}
    >
      <div className="flex items-center gap-1">
        <Icon name={"wallet"} iconFill={"white"} size={"1rem"} />
        <div className={`${tw2("font-navbar-button")} font-bold`}>{text}</div>
      </div>
    </Button>
  )
}
