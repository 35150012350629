import { PropsWithChildren, useRef } from "react"

import { Tooltips } from "../components/Tooltips"
import { TooltipStore, createTooltipStore } from "../stores"
import { TooltipContext } from "./TooltipContext"

interface Props {}

export const TooltipContextProvider = ({ children }: PropsWithChildren<Props>) => {
  const useTooltipStoreRef = useRef<TooltipStore>()
  if (!useTooltipStoreRef.current) {
    useTooltipStoreRef.current = createTooltipStore()
  }
  const useTooltipStore = useTooltipStoreRef.current

  const value = { useTooltipStore }

  return (
    <TooltipContext.Provider value={value}>
      {children}
      <Tooltips legacy={true} />
    </TooltipContext.Provider>
  )
}
