import { BigDecimal } from "./bigdecimal"
import * as BigIntMath from "./bigintMath"

export type PriceErrorCode = "PriceOutOfRange" | "TickOutOfRange" | "NotTickPrice"

export const MIN_TICK = 1n
export const MAX_TICK = 1_090_000n
export const MIN_PRICE = BigDecimal.fromRaw(10n ** 11n, 18) // 1e-7
export const MAX_PRICE = BigDecimal.fromRaw(10n ** 27n, 18) // 1e9

export interface PriceError<T extends PriceErrorCode = PriceErrorCode> {
  code: T
}

/** @see {isPriceOutOfRangeError} ts-auto-guard:type-guard */
export interface PriceOutOfRangeError extends PriceError<"PriceOutOfRange"> {
  min: BigDecimal
  max: BigDecimal
}

/** @see {isTickOutOfRangeError} ts-auto-guard:type-guard */
export interface TickOutOfRangeError extends PriceError<"TickOutOfRange"> {
  min: bigint
  max: bigint
}

/** @see {isNotTickPriceError} ts-auto-guard:type-guard */
export interface NotTickPriceError extends PriceError<"NotTickPrice"> {
  prevPrice: BigDecimal
  prevTick: bigint
  nextPrice: BigDecimal
  nextTick: bigint
}

type CodedPriceError<T> = T extends "PriceOutOfRange"
  ? PriceOutOfRangeError
  : T extends "TickOutOfRange"
    ? TickOutOfRangeError
    : T extends "NotTickPrice"
      ? NotTickPriceError
      : never

function makePriceError<C extends PriceErrorCode>(
  code: C,
  info: Omit<CodedPriceError<C>, "code">,
): CodedPriceError<C> {
  return { code, ...info } as CodedPriceError<C>
}

/*
 * Converts the given tick to a price.
 *
 * Throws `TickOutOfRangeError` if tick is outside of valid range.
 *
 * WARNING: This implementation must be kept in sync with the solidity
 * implmentation in contracts/libraries/LibTick.sol!
 */
export function tickToPrice(tick: bigint): BigDecimal {
  const priceX7 = tickToPriceX7(tick)
  return BigDecimal.fromRaw(priceX7, 7)
}

/*
 * Converts the given price to a tick.
 *
 * Throws `NotTickPriceError` if the price does not correspond to a valid tick.
 *
 * WARNING: This implementation must be kept in sync with the solidity
 * implmentation in contracts/libraries/LibTick.sol!
 */
export function priceToTick(price: BigDecimal): bigint {
  if (price.isZero()) return 0n

  const prevTick = getClosestTick(price, true)
  const nextTick = getClosestTick(price, false)
  const prevPrice = tickToPrice(prevTick)
  const nextPrice = tickToPrice(nextTick)
  if (!prevPrice.eq(price) || !nextPrice.eq(price)) {
    throw makePriceError("NotTickPrice", {
      prevPrice,
      prevTick,
      nextPrice,
      nextTick,
    })
  }
  return prevTick
}

/*
 * Rounds the provided price to a valid tick. Returns undefined if the price is
 * outside the valid price range.
 *
 * WARNING: This implementation must be kept in sync with the solidity
 * implmentation in contracts/libraries/LibTick.sol!
 */
export function getClosestTick(price: BigDecimal, roundDown: boolean): bigint {
  const MIN_PRICE = 10n ** 11n
  const MAX_PRICE = 10n ** 27n
  const MIN_PRICE_RANGE_UPPER = 10n ** 15n
  const FIRST_PRICE_RANGE_INCREMENT = 10n ** 11n
  const FACTOR = 10n ** 4n

  const priceX18 = price.raw(18)

  if (priceX18 < MIN_PRICE || priceX18 > MAX_PRICE) {
    throw makePriceError("PriceOutOfRange", {
      min: BigDecimal.fromRaw(MIN_PRICE, 18),
      max: BigDecimal.fromRaw(MAX_PRICE, 18),
    })
  }

  let tick: bigint
  if (priceX18 <= MIN_PRICE_RANGE_UPPER) {
    tick = priceX18 / FIRST_PRICE_RANGE_INCREMENT
  } else {
    const priceRangeIdx = BigIntMath.log10(priceX18) - 14n
    const startX18 = 10n ** (14n + priceRangeIdx)
    const incrementX18 = 10n ** (10n + priceRangeIdx)
    const priceMinusStartX18 = priceX18 - startX18
    const tickCount = priceMinusStartX18 / incrementX18
    const tickRangeStart = (1n + (priceRangeIdx - 1n) * 9n) * FACTOR
    tick = tickRangeStart + tickCount
  }

  const snappedPriceX18 = tickToPriceX7(tick) * 10n ** 11n
  if (!roundDown && snappedPriceX18 !== priceX18) {
    ++tick
  }

  return tick
}

/*
 * Rounds the provided price to a valid tick price.
 *
 * WARNING: This implementation must be kept in sync with the solidity
 * implmentation in contracts/libraries/LibTick.sol!
 */
export function getClosestTickPrice(price: BigDecimal, roundDown: boolean): BigDecimal {
  const tick = getClosestTick(price, roundDown)
  return tickToPrice(tick)
}

/*
 * Converts the given tick to a price in 7 decimals.
 *
 * Throws `TickOutOfRangeError` if tick is nonzero and is outside of valid tick
 * range.
 *
 * WARNING: This implementation must be kept in sync with the solidity
 * implmentation in contracts/libraries/LibTick.sol!
 */
export function tickToPriceX7(tick: bigint): bigint {
  if (tick === 0n) return 0n

  const MIN_TICK_INT = 1n
  const MAX_TICK_INT = 1_090_000n
  const FACTOR = 10n ** 4n
  const FIRST_PRICEX7_RANGE_INCREMENT = 1n

  if (tick < MIN_TICK_INT || tick > MAX_TICK_INT) {
    throw makePriceError("TickOutOfRange", {
      min: MIN_TICK_INT,
      max: MAX_TICK_INT,
    })
  }

  let priceX7: bigint
  if (tick >= FACTOR) {
    const upperPart = tick / FACTOR
    const priceRangeIdx = (upperPart + 8n) / 9n
    const numIncrements = tick - (priceRangeIdx * 9n - 8n) * FACTOR

    // NOTE: priceRangeIdx >= 1 => priceRangeIdx - 1 >= 0
    const incrementX7 = 10n ** (priceRangeIdx - 1n)
    const startX7 = incrementX7 * FACTOR
    priceX7 = startX7 + incrementX7 * numIncrements
  } else {
    priceX7 = tick * FIRST_PRICEX7_RANGE_INCREMENT
  }
  return priceX7
}
