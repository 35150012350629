/*
 * Generated type guards for "tick.ts".
 * WARNING: Do not manually change this file.
 */
import { BigDecimal } from "./bigdecimal"
import { NotTickPriceError, PriceOutOfRangeError, TickOutOfRangeError } from "./tick"

export function isPriceOutOfRangeError(obj: unknown): obj is PriceOutOfRangeError {
  const typedObj = obj as PriceOutOfRangeError
  return (
    ((typedObj !== null && typeof typedObj === "object") || typeof typedObj === "function") &&
    typedObj["code"] === "PriceOutOfRange" &&
    typedObj["min"] instanceof BigDecimal &&
    typedObj["max"] instanceof BigDecimal
  )
}

export function isTickOutOfRangeError(obj: unknown): obj is TickOutOfRangeError {
  const typedObj = obj as TickOutOfRangeError
  return (
    ((typedObj !== null && typeof typedObj === "object") || typeof typedObj === "function") &&
    typedObj["code"] === "TickOutOfRange" &&
    typeof typedObj["min"] === "bigint" &&
    typeof typedObj["max"] === "bigint"
  )
}

export function isNotTickPriceError(obj: unknown): obj is NotTickPriceError {
  const typedObj = obj as NotTickPriceError
  return (
    ((typedObj !== null && typeof typedObj === "object") || typeof typedObj === "function") &&
    typedObj["code"] === "NotTickPrice" &&
    typedObj["prevPrice"] instanceof BigDecimal &&
    typeof typedObj["prevTick"] === "bigint" &&
    typedObj["nextPrice"] instanceof BigDecimal &&
    typeof typedObj["nextTick"] === "bigint"
  )
}
