import { Chain } from "silverkoi"

import baycLogo from "../assets/symbols/bayc.png"
import btcusdLogo from "../assets/symbols/btcusd.svg"
import ethLogo from "../assets/symbols/eth.svg"
import ethusdLogo from "../assets/symbols/ethusd.svg"
import eurusdLogo from "../assets/symbols/eurusd.svg"
import gbpusdLogo from "../assets/symbols/gbpusd.svg"
import goonUsdLogo from "../assets/symbols/goonUSD.svg"
import maticLogo from "../assets/symbols/matic.svg"
import miladyLogo from "../assets/symbols/milady.png"
import moonbirdLogo from "../assets/symbols/moonbird.png"
import nvdausdLogo from "../assets/symbols/nvdausd.svg"
import ppgLogo from "../assets/symbols/ppg.avif"
import tslausdLogo from "../assets/symbols/tslausd.svg"
import unknownNftLogo from "../assets/symbols/unknownNft.svg"
import wtiusdLogo from "../assets/symbols/wtiusd.svg"
import xagusdLogo from "../assets/symbols/xagusd.svg"
import xauusdLogo from "../assets/symbols/xauusd.svg"

import barchartIcon from "~/assets/barchart.svg"
import cashIcon from "~/assets/cash.svg"
import coinIcon from "~/assets/coin.svg"
import expandIcon from "~/assets/expand.svg"
import gaugeWhiteIcon from "~/assets/gauge_white.svg"
import historyIcon from "~/assets/history.svg"
import historySelectedIcon from "~/assets/history_selected.svg"
import myPositionIcon from "~/assets/myposition.svg"
import myPositionSelectedIcon from "~/assets/myposition_selected.svg"
import openOrderIcon from "~/assets/openorder.svg"
import openOrderSelectedIcon from "~/assets/openorder_selected.svg"
import readIcon from "~/assets/read.svg"
import readSelectedIcon from "~/assets/readactive.svg"
import unreadIcon from "~/assets/unread.svg"
import unreadSelectedIcon from "~/assets/unreadactive.svg"
import loadingSpinBlueIcon from "../assets/animations/loading-spin-blue.svg"
import loadingSpinIcon from "../assets/animations/loading-spin.svg"
import arrowIcon from "../assets/arrow.png"
import cancelIcon from "../assets/cancel.svg"
import editIcon from "../assets/edit.svg"
import gaugeIcon from "../assets/gauge.svg"
import gaugeBlueIcon from "../assets/gauge_blue.svg"
import silverkoiIcon from "../assets/iconv2.svg"
import infoIcon from "../assets/info.svg"
import logoutIcon from "../assets/logout.svg"
import magnifyingGlassIcon from "../assets/magnifying-glass.png"
import rankPnlIcon from "../assets/rank-pnl.svg"
import rankPointsIcon from "../assets/rank-points.svg"
import rankVolumeIcon from "../assets/rank-volume.svg"
import warningRedIcon from "../assets/redWarning.svg"
import successIcon from "../assets/success.svg"
import tailedArrowGreen from "../assets/tailedarrow-green.svg"
import tailedArrowRed from "../assets/tailedarrow-red.svg"
import usdcIcon from "../assets/usdc.png"
import usdcBwIcon from "../assets/usdcbw.svg"
import usdcGrayIcon from "../assets/usdcgray.svg"
import eyeIcon from "../assets/view.svg"
import warningYellowIcon from "../assets/warning.svg"
import arrowDownWhiteIcon from "../assets/whitedownarrow.svg"
import arrowUpWhiteIcon from "../assets/whiteuparrow.svg"

import arbitrumLogo from "../assets/explorer/arbitrum.svg"
import plumePaddedLogo from "../assets/explorer/plume-padded.svg"
import plumeLogo from "../assets/explorer/plume.svg"
import unknownChainLogo from "../assets/explorer/unknown.svg"

export function getChainLogo({ chain, padded }: { chain?: Chain; padded?: boolean }): string {
  switch (chain) {
    case "arbitrum-sepolia":
      return arbitrumLogo
    case "plume-testnet":
      return padded ? plumePaddedLogo : plumeLogo
    default:
      return unknownChainLogo
  }
}

// TODO: Make this a hook that depends on contract's settlement token.
export function getCollateralUsdLogo() {
  return goonUsdLogo
}

export function getSymbolLogo(symbol: string) {
  switch (symbol) {
    case "BAYC":
      return baycLogo
    case "MOONBIRD":
      return moonbirdLogo
    case "MILADY":
      return miladyLogo
    case "PPG":
      return ppgLogo
    case "ETH":
      return ethLogo
    case "MATIC":
      return maticLogo
    case "ETHUSD":
      return ethusdLogo
    case "BTCUSD":
      return btcusdLogo
    case "EURUSD":
      return eurusdLogo
    case "GBPUSD":
      return gbpusdLogo
    case "NVDAUSD":
      return nvdausdLogo
    case "TSLAUSD":
      return tslausdLogo
    case "XAUUSD":
      return xauusdLogo
    case "XAGUSD":
      return xagusdLogo
    case "WTIUSD":
      return wtiusdLogo
    case "goonUSD":
      return goonUsdLogo
    default:
      return unknownNftLogo
  }
}

// TODO: merge getSymbolLogo and getTokenLogo into one

export type Token = "ETH" | "MATIC"

export function getTokenLogo(token: Token) {
  switch (token) {
    case "ETH":
      return ethLogo
    case "MATIC":
      return maticLogo
  }
}

export type IconType =
  | "arrow"
  | "arrow-down-white"
  | "arrow-up-white"
  | "barchart"
  | "cancel"
  | "cash"
  | "coin"
  | "edit"
  | "expand"
  | "eye"
  | "gauge"
  | "gauge-blue"
  | "gauge-white"
  | "history"
  | "history-selected"
  | "info"
  | "loading-spin"
  | "loading-spin-blue"
  | "logout"
  | "magnifying-glass"
  | "myposition"
  | "myposition-selected"
  | "open-order"
  | "open-order-selected"
  | "rank-pnl"
  | "rank-points"
  | "rank-volume"
  | "read"
  | "read-selected"
  | "silverkoi"
  | "success"
  | "tailed-arrow-green"
  | "tailed-arrow-red"
  | "unread"
  | "unread-selected"
  | "usdc"
  | "usdc-bw"
  | "usdc-gray"
  | "warning-red"
  | "warning-yellow"

export const getIcon = (name: IconType): string => {
  switch (name) {
    case "arrow":
      return arrowIcon
    case "arrow-down-white":
      return arrowDownWhiteIcon
    case "arrow-up-white":
      return arrowUpWhiteIcon
    case "barchart":
      return barchartIcon
    case "cancel":
      return cancelIcon
    case "cash":
      return cashIcon
    case "coin":
      return coinIcon
    case "edit":
      return editIcon
    case "eye":
      return eyeIcon
    case "gauge":
      return gaugeIcon
    case "gauge-blue":
      return gaugeBlueIcon
    case "gauge-white":
      return gaugeWhiteIcon
    case "info":
      return infoIcon
    case "loading-spin":
      return loadingSpinIcon
    case "loading-spin-blue":
      return loadingSpinBlueIcon
    case "logout":
      return logoutIcon
    case "rank-pnl":
      return rankPnlIcon
    case "rank-points":
      return rankPointsIcon
    case "rank-volume":
      return rankVolumeIcon
    case "magnifying-glass":
      return magnifyingGlassIcon
    case "silverkoi":
      return silverkoiIcon
    case "success":
      return successIcon
    case "tailed-arrow-green":
      return tailedArrowGreen
    case "tailed-arrow-red":
      return tailedArrowRed
    case "usdc":
      return usdcIcon
    case "usdc-bw":
      return usdcBwIcon
    case "usdc-gray":
      return usdcGrayIcon
    case "warning-red":
      return warningRedIcon
    case "warning-yellow":
      return warningYellowIcon
    case "expand":
      return expandIcon
    case "history":
      return historyIcon
    case "history-selected":
      return historySelectedIcon
    case "myposition":
      return myPositionIcon
    case "myposition-selected":
      return myPositionSelectedIcon
    case "open-order":
      return openOrderIcon
    case "open-order-selected":
      return openOrderSelectedIcon
    case "read":
      return readIcon
    case "read-selected":
      return readSelectedIcon
    case "unread":
      return unreadIcon
    case "unread-selected":
      return unreadSelectedIcon
  }
}

export type UrlType = "discord" | "plume-miles" | "medium-intro"

export const getUrl = (name: UrlType): string => {
  switch (name) {
    case "plume-miles":
      return "https://miles.plumenetwork.xyz/"
    case "discord":
      return "https://discord.gg/udg27sp2QA"
    case "medium-intro":
      return "https://medium.com/@SilverKoi/introducing-silver-koi-8c6cbbd92e8f"
  }
}
