// TODO: Once stable, move to silverkoi package
import { Err, Ok, Result } from "ts-results"
import * as viem from "viem"

import * as sk from "silverkoi"
import {
  isCurrentPrice,
  MarketSnapshot,
  OrderBook,
  OrderSide,
  PositionSnapshot,
  SilverKoiApi,
  TimeInForce,
  TriggerSnapshot,
} from "silverkoi"
import { BigDecimal, isNotTickPriceError, tickMath } from "silverkoi/math"

import {
  CancelOrderRequest,
  CreateTriggerRequest,
  Delta,
  DepositCollateralRequest,
  FormattedOperationSummary,
  isCreateTriggerRequest,
  isDepositCollateralRequest,
  isPlaceOrderRequest,
  isReplaceTriggerRequest,
  isWithdrawCollateralRequest,
  OperationContext,
  OperationInput,
  OperationSummary,
  OrderType,
  PartialOperationContext,
  PlaceOrderRequest,
  ReplaceTriggerRequest,
  WithdrawCollateralRequest,
} from "../../types"
import {
  COLORS,
  formatValue,
  FormatValueArgs,
  getDurationSeconds,
  RIGHT_ARROW_TEXT,
} from "../../utils"
import {
  isApproveNeededError,
  isSKError,
  makeSKError,
  parseSmartContractError,
  SKError,
} from "./error"
import { getSymbolMeta } from "./universe"

const ZERO = BigDecimal.zero()
const HUNDRED = BigDecimal.fromReal(100)
const ONE = BigDecimal.one()

// TODO: throw exceptions and only catch and return result at top-most level of
// sim function

export function validateOperationContext({
  context,
}: {
  context: PartialOperationContext
}): OperationContext {
  const { traderConfig, position } = context
  if (!traderConfig) {
    throw makeSKError("UserStateError", 'Please register first by clicking "Register" below', {})
  }
  if (!position) {
    throw makeSKError(
      "UserStateError",
      "You have too many positions open for this symbol. Please close or reduce one of them first",
      {},
    )
  }
  return { ...context, traderConfig, position }
}

type OperationRequest =
  | DepositCollateralRequest
  | WithdrawCollateralRequest
  | CancelOrderRequest
  | PlaceOrderRequest
  | CreateTriggerRequest
  | ReplaceTriggerRequest

export function validateOperationInput({
  input,
  context,
}: {
  input: OperationInput
  context: OperationContext
}): OperationRequest {
  const { inputMode, type } = input
  if (inputMode === undefined) {
    throw makeSKError("InternalError", "Undefined input mode", {})
  }

  switch (inputMode) {
    case "NewPosition":
    case "AddPosition":
    case "ReducePosition": {
      if (type === "stop-limit") {
        return validateCreateStopLimitOrderRequest({ input, context })
      } else {
        return validatePlaceOrderRequest({ input, context })
      }
    }
    case "CreateSLTP": {
      return validateCreateTriggerRequest({ input, context })
    }
    case "EditTrigger": {
      return validateEditTriggerRequest({ input, context })
    }
    case "DepositCollateral":
      return validateDepositCollateralRequest({ input, context })
    case "WithdrawCollateral":
      return validateWithdrawCollateralRequest({ input, context })
  }
}

export function validateDepositCollateralRequest({
  input,
  context,
}: {
  input: OperationInput
  context: OperationContext
}): DepositCollateralRequest {
  const { collateralAmount: collateralAmountInput } = input
  const amount = collateralAmountInput.value
  if (!amount) {
    throw makeSKError("UndefinedInputError", "Please specify collateral amount", {})
  }
  if (amount.isZero()) {
    throw makeSKError("InvalidInputError", "Collateral amount must be positive", {})
  }
  return {
    type: "DepositCollateral",
    marketId: context.market.marketId,
    traderId: context.traderConfig.traderId,
    positionSubId: context.position.positionSubId,
    amount,
  }
}

export function validateWithdrawCollateralRequest({
  input,
  context,
}: {
  input: OperationInput
  context: OperationContext
}): WithdrawCollateralRequest {
  const { collateralAmount: collateralAmountInput } = input
  const amount = collateralAmountInput.value
  if (!amount) {
    throw makeSKError("UndefinedInputError", "Please specify collateral amount", {})
  }
  if (amount.isZero()) {
    throw makeSKError("InvalidInputError", "Collateral amount must be positive", {})
  }
  return {
    type: "WithdrawCollateral",
    marketId: context.market.marketId,
    traderId: context.traderConfig.traderId,
    positionSubId: context.position.positionSubId,
    amount,
  }
}

export function validatePlaceOrderRequest({
  input: _input,
  context,
}: {
  input: OperationInput
  context: OperationContext
}): PlaceOrderRequest {
  const { market, traderConfig, position, orderBook, currentTimestamp } = context
  const { marketId } = market
  const { traderId } = traderConfig
  const input = { ..._input } // copy to avoid modifying actualy input
  const { side } = input

  const { tif } = validateTif(input)
  input.tif = tif

  const { postOnly } = validatePostOnly(input)
  input.postOnly = postOnly

  const { bestBid, bestAsk } = validateBestBidAndAsk(orderBook)
  const { size } = validateOrderSize(input, position)

  const limitPrice =
    input.type === "market"
      ? computeLimitPriceForMarketOrder({ input, market })
      : input.limitPrice.value

  const { price, tick } = validateLimitPrice(input, bestBid, bestAsk, limitPrice)

  const { deadline } = validateDeadline(input, currentTimestamp)
  const { leverage } = validateLeverage(input, market, position)

  // TODO: Support reduce only
  const reduceOnly = false

  const clientOrderId = generateClientOrderId()

  return {
    type: "PlaceOrder",
    marketId,
    traderId,
    positionSubId: position.positionSubId,
    clientOrderId,
    side,
    size,
    price,
    tick,
    tif,
    deadline: deadline ?? 0n,
    postOnly,
    reduceOnly,
    targetLeverage: leverage ?? ZERO,
    maintainLeverage: false, // TODO: support
  }
}

function computeLimitPriceForMarketOrder({
  input,
  market,
}: {
  input: OperationInput
  market: MarketSnapshot
}): BigDecimal | undefined {
  const { slippage, side } = input
  const marketPrice = market.marketPrice

  if (!slippage || !slippage.value) {
    throw makeSKError("UndefinedInputError", "Slippage is undefined", {})
  }

  switch (side) {
    case "bid": {
      return tickMath.getClosestTickPrice(
        marketPrice.mul(ONE.add(slippage.value.div(HUNDRED, 10))),
        true,
      )
    }
    case "ask": {
      return tickMath.getClosestTickPrice(
        marketPrice.mul(ONE.sub(slippage.value.div(HUNDRED, 10))),
        true,
      )
    }
    default: {
      throw makeSKError("InvalidInputError", "Side input is invalid", {})
    }
  }
}

export function validateCreateStopLimitOrderRequest({
  input,
  context,
}: {
  input: OperationInput
  context: OperationContext
}): CreateTriggerRequest {
  // First check the same things we would check with a standard place order
  // request.
  const r = validatePlaceOrderRequest({ input, context })
  const {
    traderId,
    marketId,
    positionSubId,
    clientOrderId,
    side,
    size,
    price,
    tick,
    tif,
    postOnly,
    reduceOnly,
    targetLeverage,
    maintainLeverage,
  } = r

  const { triggerPrice, triggerIsFromAbove: fromAbove } = input
  const fromBelow = !fromAbove

  if (!triggerPrice.value) {
    throw makeSKError("UndefinedInputError", "Please specify trigger price", {})
  }
  if (triggerPrice.value.isZero() || triggerPrice.value.lt(ZERO)) {
    throw makeSKError("InvalidInputError", "Trigger price must be positive", {})
  }
  if (tif === "gtd") {
    throw makeSKError("InvalidInputError", "GTD not supported for stop limit order", {})
  }

  const fromAboveTriggerPrice = fromAbove ? triggerPrice.value : ZERO
  const fromBelowTriggerPrice = fromBelow ? triggerPrice.value : ZERO
  const fromAboveLimitPrice = fromAbove ? price : ZERO
  const fromBelowLimitPrice = fromBelow ? price : ZERO
  const fromAboveTriggerTick = getTickForPrice(fromAboveTriggerPrice, "Trigger Price")
  const fromBelowTriggerTick = getTickForPrice(fromBelowTriggerPrice, "Trigger Price")
  const fromAboveLimitTick = fromAbove ? tick : 0n
  const fromBelowLimitTick = fromBelow ? tick : 0n

  return {
    type: "CreateTrigger",
    traderId,
    marketId,
    positionSubId,
    clientOrderId,
    side,
    size,
    fromAboveTriggerPrice,
    fromBelowTriggerPrice,
    fromAboveLimitPrice,
    fromBelowLimitPrice,
    fromAboveTriggerTick,
    fromBelowTriggerTick,
    fromAboveLimitTick,
    fromBelowLimitTick,
    tif,
    postOnly,
    reduceOnly,
    targetLeverage,
    maintainLeverage,
  }
}

export function validateCreateTriggerRequest({
  input,
  context,
}: {
  input: OperationInput
  context: OperationContext
}): CreateTriggerRequest {
  const { market, traderConfig, position, orderBook } = context
  const { marketId } = market
  const { traderId } = traderConfig
  const { side, type } = input

  if (type !== "stop-limit") {
    throw makeSKError("InternalError", "Incorrect order type for SLTP", {})
  }

  const { tif } = validateTif(input)
  input.tif = tif

  const { postOnly } = validatePostOnly(input)
  input.postOnly = postOnly

  const { bestBid, bestAsk } = validateBestBidAndAsk(orderBook)
  const { size } = validateOrderSize(input, position)
  const { leverage } = validateLeverage(input, market, position)
  const sltpPrices = validateSLTPPrices(input, bestBid, bestAsk)

  // TODO: Support reduce only
  const reduceOnly = false

  const clientOrderId = generateClientOrderId()

  return {
    type: "CreateTrigger",
    traderId,
    marketId,
    positionSubId: position.positionSubId,
    clientOrderId,
    side,
    size,
    ...sltpPrices,
    tif,
    postOnly,
    reduceOnly,
    targetLeverage: leverage,
    maintainLeverage: false, // TODO: support
  }
}

export function validateEditTriggerRequest({
  input,
  context,
}: {
  input: OperationInput
  context: OperationContext
}): ReplaceTriggerRequest {
  const { market, orderBook } = context
  const { marketId } = market
  const { type, referenceTrigger } = input

  if (!referenceTrigger) {
    throw makeSKError("InternalError", "Missing reference trigger", {})
  }

  if (type !== "stop-limit") {
    throw makeSKError("InternalError", "Incorrect order type for SLTP", {})
  }

  let priceAndTick = { price: ZERO, tick: 0n }
  if (input.limitPrice.value) {
    const { bestBid, bestAsk } = validateBestBidAndAsk(orderBook)
    priceAndTick = validateLimitPrice(input, bestBid, bestAsk, input.limitPrice.value)
  }
  const { price, tick } = priceAndTick

  const { triggerPrice, triggerIsFromAbove: fromAbove } = input
  if (!triggerPrice.value) {
    throw makeSKError("UndefinedInputError", "Please specify trigger price", {})
  }
  if (triggerPrice.value.isZero() || triggerPrice.value.lt(ZERO)) {
    throw makeSKError("InvalidInputError", "Trigger price must be positive", {})
  }

  const prices = {
    fromAboveTriggerPrice: referenceTrigger.fromAboveTriggerPrice,
    fromBelowTriggerPrice: referenceTrigger.fromBelowTriggerPrice,
    fromAboveLimitPrice: referenceTrigger.fromAboveLimitPrice,
    fromBelowLimitPrice: referenceTrigger.fromBelowLimitPrice,
    fromAboveTriggerTick: referenceTrigger.fromAboveTriggerTick,
    fromBelowTriggerTick: referenceTrigger.fromBelowTriggerTick,
    fromAboveLimitTick: referenceTrigger.fromAboveLimitTick,
    fromBelowLimitTick: referenceTrigger.fromBelowLimitTick,
  }
  if (fromAbove) {
    prices.fromAboveTriggerPrice = triggerPrice.value
    prices.fromAboveLimitPrice = price
    prices.fromAboveTriggerTick = getTickForPrice(triggerPrice.value, "Trigger Price")
    prices.fromAboveLimitTick = tick
  } else {
    prices.fromBelowTriggerPrice = triggerPrice.value
    prices.fromBelowLimitPrice = price
    prices.fromBelowTriggerTick = getTickForPrice(triggerPrice.value, "Trigger Price")
    prices.fromBelowLimitTick = tick
  }

  const clientOrderId = generateClientOrderId()

  return {
    type: "ReplaceTrigger",
    fromAbove,
    oldClientOrderId: referenceTrigger.clientOrderId,
    traderId: referenceTrigger.traderId,
    marketId: marketId,
    positionSubId: referenceTrigger.positionSubId,
    clientOrderId,
    side: referenceTrigger.side,
    size: referenceTrigger.size,
    ...prices,
    tif: referenceTrigger.tif,
    postOnly: referenceTrigger.postOnly,
    reduceOnly: referenceTrigger.reduceOnly,
    targetLeverage: referenceTrigger.targetLeverage,
    maintainLeverage: referenceTrigger.maintainLeverage,
  }
}

function validateBestBidAndAsk(orderBook: OrderBook): {
  bestBid: BigDecimal
  bestAsk: BigDecimal
} {
  const bestBid = orderBook.bids.at(0)?.price
  const bestAsk = orderBook.asks.at(0)?.price
  if (!bestBid || !bestAsk || bestBid.isZero() || bestAsk.isZero()) {
    throw makeSKError("InternalError", "Market is in invalid state", {})
  }
  return { bestBid, bestAsk }
}

function validateOrderSize(
  input: OperationInput,
  position: PositionSnapshot,
): { size: BigDecimal } {
  const { inputMode, size: sizeInput } = input
  const size = sizeInput.value

  if (!size) {
    throw makeSKError("UndefinedInputError", "Please specify order amount", {})
  }
  if (size.isZero() || size.lt(ZERO)) {
    throw makeSKError("InvalidInputError", "Order amount must be positive", {})
  }
  if (inputMode === "ReducePosition") {
    if (size.gt(position.size.abs())) {
      throw makeSKError("InvalidInputError", "Order size cannot exceed position size", {})
    }
  }
  return { size }
}

function validateLimitPrice(
  input: OperationInput,
  bestBid: BigDecimal,
  bestAsk: BigDecimal,
  limitPrice: BigDecimal | undefined,
): {
  price: BigDecimal
  tick: bigint
} {
  const { type, side, postOnly } = input

  let price: BigDecimal = ZERO
  let tick: bigint = 0n

  if (type == "limit" || type == "stop-limit" || type == "market") {
    if (!limitPrice) {
      throw makeSKError("UndefinedInputError", "Please specify limit price", {})
    }
    if (limitPrice.isZero() || limitPrice.lt(ZERO)) {
      throw makeSKError("InvalidInputError", "Limit price must be positive", {})
    }

    if (postOnly) {
      if (side === "bid" && limitPrice.gt(bestAsk)) {
        throw makeSKError("InvalidInputError", "Limit price must be less than best ask", {})
      }
      if (side === "ask" && limitPrice.lt(bestBid)) {
        throw makeSKError("InvalidInputError", "Limit price must be less than best bid", {})
      }
    }

    price = limitPrice
    tick = getTickForPrice(limitPrice, "Limit price")
  }

  return { price, tick }
}

// We only use GTC for TP triggers and assume FOK for market orders.
function validateTif(input: OperationInput): {
  tif: TimeInForce
} {
  const { inputMode, type, tif, tpTriggerPrice: tpTriggerPriceInput } = input
  const tpTriggerPrice = tpTriggerPriceInput.value
  if (inputMode === "CreateSLTP") {
    if (tpTriggerPrice && !tpTriggerPrice.isZero()) {
      return { tif: "gtc" }
    } else {
      return { tif: "ioc" }
    }
  } else if (type === "market") {
    return { tif: "fok" }
  } else {
    return { tif }
  }
}

function validatePostOnly(input: OperationInput): {
  postOnly: boolean
} {
  const { inputMode, type, postOnly } = input
  if (type === "market" || inputMode === "CreateSLTP") {
    return { postOnly: false }
  } else {
    return { postOnly }
  }
}

function validateDeadline(
  input: OperationInput,
  currentTimestamp: bigint,
): {
  deadline: bigint
} {
  const { tif, cancelAfter: cancelAfterInput } = input
  const cancelAfter = cancelAfterInput.value

  if (tif === "gtd") {
    if (!cancelAfter) {
      throw makeSKError("InternalError", "Deadline is not specified", {})
    }
    const cancelAfterSeconds = BigInt(getDurationSeconds(cancelAfter).real())
    const buffer = 30n // TODO: Don't hardcode this buffer
    if (cancelAfterSeconds < buffer) {
      throw makeSKError(
        "InvalidInputError",
        "GTD deadline must be at least 30 seconds from now",
        {},
      )
    }
    return { deadline: currentTimestamp + cancelAfterSeconds }
  }

  return { deadline: 0n }
}

function validateLeverage(
  input: OperationInput,
  market: MarketSnapshot,
  position: PositionSnapshot,
): { leverage: BigDecimal } {
  const { maxInitialLeverage } = market
  const { inputMode, leverage: leverageInput } = input
  const leverage = leverageInput.value

  if (
    inputMode === "NewPosition" ||
    inputMode === "AddPosition" ||
    inputMode === "ReducePosition"
  ) {
    if (!leverage || leverage.isZero()) {
      throw makeSKError("UndefinedInputError", "Please specify leverage", {})
    }
    return { leverage }
  } else {
    if (position.leverage === undefined) {
      throw makeSKError("UserStateError", "This position is already bankrupt", {})
    }
    if (leverage && leverage.lt(BigDecimal.one())) {
      throw makeSKError("InvalidInputError", "Leverage must be at least one", {})
    }
    if (leverage && leverage.gt(maxInitialLeverage)) {
      throw makeSKError(
        "InvalidInputError",
        `Leverage cannot be larger ${maxInitialLeverage.toStringTrimmed()}`,
        {},
      )
    }
    return { leverage: leverage ?? BigDecimal.zero() }
  }
}

function validateSLTPPrices(
  input: OperationInput,
  bestBid: BigDecimal,
  bestAsk: BigDecimal,
): {
  fromAboveTriggerPrice: BigDecimal
  fromBelowTriggerPrice: BigDecimal
  fromAboveTriggerTick: bigint
  fromBelowTriggerTick: bigint
  fromAboveLimitPrice: BigDecimal
  fromBelowLimitPrice: BigDecimal
  fromAboveLimitTick: bigint
  fromBelowLimitTick: bigint
} {
  const { side, slTriggerPrice: slTriggerPriceInput, tpTriggerPrice: tpTriggerPriceInput } = input
  const slTriggerPrice = slTriggerPriceInput.value
  const tpTriggerPrice = tpTriggerPriceInput.value
  // NOTE: side is opposite direction of position size!

  let fromAboveTriggerPrice: BigDecimal = ZERO
  let fromBelowTriggerPrice: BigDecimal = ZERO
  let fromAboveTriggerTick: bigint = 0n
  let fromBelowTriggerTick: bigint = 0n

  let fromAboveLimitPrice: BigDecimal = ZERO
  let fromBelowLimitPrice: BigDecimal = ZERO
  let fromAboveLimitTick: bigint = 0n
  let fromBelowLimitTick: bigint = 0n

  if (!slTriggerPrice && !tpTriggerPrice) {
    throw makeSKError(
      "UndefinedInputError",
      "Please specify at least one of stop-loss or take-profit price",
      {},
    )
  }

  if (slTriggerPrice) {
    if (slTriggerPrice.isZero() || slTriggerPrice.lt(ZERO)) {
      throw makeSKError("InvalidInputError", "Stop loss price must be positive", {})
    }

    if (side === "bid" && slTriggerPrice.le(bestAsk)) {
      throw makeSKError(
        "InvalidInputError",
        "Stop loss trigger would immediately execute. Please pick a price greater than best ask",
        {},
      )
    }
    if (side === "ask" && slTriggerPrice.ge(bestBid)) {
      throw makeSKError(
        "InvalidInputError",
        "Stop loss trigger would immediately execute. Please pick a price less than best bid",
        {},
      )
    }

    // SL is a stop-market order.
    const slTriggerTick = getTickForPrice(slTriggerPrice, "Stop loss price")
    if (side === "bid") {
      fromBelowTriggerPrice = slTriggerPrice
      fromBelowTriggerTick = slTriggerTick
    } else {
      fromAboveTriggerPrice = slTriggerPrice
      fromAboveTriggerTick = slTriggerTick
    }
  }

  if (tpTriggerPrice) {
    if (tpTriggerPrice.isZero() || tpTriggerPrice.lt(ZERO)) {
      throw makeSKError("InvalidInputError", "Take profit price must be positive", {})
    }

    if (side === "bid" && tpTriggerPrice.ge(bestAsk)) {
      throw makeSKError(
        "InvalidInputError",
        "Take profit trigger would immediately execute. Please pick a price less than best ask",
        {},
      )
    }
    if (side === "ask" && tpTriggerPrice.le(bestBid)) {
      throw makeSKError(
        "InvalidInputError",
        "Take profit trigger would immediately execute. Please pick a price greater than best bid",
        {},
      )
    }

    // TP is a stop-limit order.
    const tpTriggerTick = getTickForPrice(tpTriggerPrice, "Take profit price")
    if (side === "bid") {
      fromAboveTriggerPrice = tpTriggerPrice
      fromAboveTriggerTick = tpTriggerTick
      fromAboveLimitPrice = tpTriggerPrice
      fromAboveLimitTick = tpTriggerTick
    } else {
      fromBelowTriggerPrice = tpTriggerPrice
      fromBelowTriggerTick = tpTriggerTick
      fromBelowLimitPrice = tpTriggerPrice
      fromBelowLimitTick = tpTriggerTick
    }
  }
  if (slTriggerPrice && tpTriggerPrice) {
    if (side === "bid" && slTriggerPrice.le(tpTriggerPrice)) {
      throw makeSKError(
        "InvalidInputError",
        "Stop loss price must be less than take profit price",
        {},
      )
    }
    if (side === "ask" && slTriggerPrice.ge(tpTriggerPrice)) {
      throw makeSKError(
        "InvalidInputError",
        "Stop loss price must be greater than take profit price",
        {},
      )
    }
  }

  return {
    fromAboveLimitPrice,
    fromAboveLimitTick,
    fromAboveTriggerPrice,
    fromAboveTriggerTick,
    fromBelowLimitPrice,
    fromBelowLimitTick,
    fromBelowTriggerPrice,
    fromBelowTriggerTick,
  }
}

// TODO: Revisit this, but for now, should be good enough. It is very unlikely
// that a user can create more than one order per second from the UI.
function generateClientOrderId(): bigint {
  const timestamp = BigInt(Date.now()) / 1000n
  const clientOrderIdOffset = 1704038400n // 2024-01-01 00:00:00 America/Chicago
  const clientOrderId = timestamp - clientOrderIdOffset
  return clientOrderId
}

export async function estimateOrderSize({
  api,
  marketId,
  type,
  side,
  notional,
  limitPrice,
  tif,
  postOnly,
  blockNumber,
}: {
  api: SilverKoiApi
  marketId: bigint
  type: OrderType
  side: OrderSide
  notional?: BigDecimal
  limitPrice?: BigDecimal
  tif: TimeInForce
  postOnly: boolean
  blockNumber: bigint
}): Promise<BigDecimal | undefined> {
  if (!notional || notional.isZero()) return undefined

  if (type === "market") {
    const { fillSize } = await sk.getOrderSizeForOrderNotional({
      api,
      marketId,
      side,
      notional,
      limitPrice: undefined,
      blockNumber,
    })
    return fillSize.round(5)
  } else {
    if (!limitPrice || limitPrice.isZero()) return undefined
    if (postOnly) {
      return notional.div(limitPrice, 18)
    } else {
      const { fillSize, fillNotional } = await sk.getOrderSizeForOrderNotional({
        api,
        marketId,
        side,
        notional,
        limitPrice,
        blockNumber,
      })

      let makerSize = ZERO
      const remainingNotional = notional.sub(fillNotional)
      if (tif === "gtc" || tif === "gtd") {
        if (!limitPrice || limitPrice.isZero()) return undefined
        makerSize = remainingNotional.div(limitPrice)
      }

      return fillSize.add(makerSize).round(5)
    }
  }
}

export interface SimulationFailure {
  input: OperationInput
  context: PartialOperationContext | OperationContext
  error: SKError
}

export type InvokeTransactionFn = () => Promise<viem.Hash>

export interface SimulationSuccess {
  input: OperationInput
  context: OperationContext
  summary: OperationSummary
  description: string
  txHashFn: InvokeTransactionFn
}

export type SimulationResult = Result<SimulationSuccess, SimulationFailure>

export class SkoiInputError extends Error {
  message: string
  needApprove: boolean

  constructor(message: string, needApprove: boolean = false) {
    super()
    this.message = message
    this.needApprove = needApprove
  }
}

export async function simulateOperation({
  api,
  input,
  context,
}: {
  api: SilverKoiApi
  input: OperationInput
  context: PartialOperationContext
}): Promise<SimulationResult> {
  try {
    const success = await trySimulateOperation({ api, input, context })
    return Ok(success)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const unknownErrorMessage =
      "Sorry, we can't process your transaction right now. Please contact support"

    if (isSKError(e)) {
      return Err({ input, context, error: e })
    } else if (e?.name && e?.walk) {
      // TODO: I don't know how to check that an error is a viem error. Checking
      // `e instanceof BaseError` doesn't work...
      const err = (e as viem.BaseError).walk(
        (e) => (e as viem.BaseError).name === "ContractFunctionRevertedError",
      ) as viem.ContractFunctionRevertedError | null
      const error = err
        ? parseSmartContractError({ api, error: err, input })
        : makeSKError("UnknownError", unknownErrorMessage, { error: e })
      if (!error) {
        console.warn("unknown smart contract error:", e)
      }
      return Err({ input, context, error })
    } else {
      console.warn("unknown error:", e)
      const error = makeSKError("UnknownError", unknownErrorMessage, { error: e })
      return Err({ input, context, error })
    }
  }
}

async function trySimulateOperation({
  api,
  input,
  context: _context,
}: {
  api: SilverKoiApi
  input: OperationInput
  context: PartialOperationContext
}): Promise<SimulationSuccess> {
  const context = validateOperationContext({ context: _context })

  const request = validateOperationInput({ input, context })

  if (isDepositCollateralRequest(request)) {
    const { summary, txHashFn } = await simulateDepositCollateral({ api, request, context })
    return { input, context, summary, txHashFn, description: "deposit collateral" }
  } else if (isWithdrawCollateralRequest(request)) {
    const { summary, txHashFn } = await simulateWithdrawCollateral({ api, request, context })
    return { input, context, summary, txHashFn, description: "withdraw collateral" }
  } else if (isPlaceOrderRequest(request)) {
    const { summary, txHashFn } = await simulatePlaceOrder({ api, request, context })
    return { input, context, summary, txHashFn, description: "place order" }
  } else if (isCreateTriggerRequest(request)) {
    const { summary, txHashFn } = await simulateCreateTrigger({ api, request, context })
    return { input, context, summary, txHashFn, description: "create trigger" }
  } else if (isReplaceTriggerRequest(request)) {
    const { summary, txHashFn } = await simulateReplaceTrigger({ api, request, context })
    return { input, context, summary, txHashFn, description: "replace trigger" }
  } else {
    throw makeSKError("InternalError", `Simulation not ready for ${request.type}`, {})
  }
}

async function simulateDepositCollateral({
  api,
  request: _request,
  context,
}: {
  api: SilverKoiApi
  request: DepositCollateralRequest
  context: OperationContext
}): Promise<{ summary: OperationSummary; txHashFn: InvokeTransactionFn }> {
  const { marketId } = _request
  const request = {
    traderId: _request.traderId,
    positionSubId: _request.positionSubId,
    amountX6: _request.amount.raw(6),
  }
  const { oldPosition, newPosition, txHashFn } = await sk.simDepositCollateral({
    api,
    marketId,
    request,
  })

  let marketPrice: BigDecimal | undefined = context.market.marketPrice
  if (marketPrice.isZero()) {
    marketPrice = undefined
  }

  const partialSummary = createOperationSummaryFromOldAndNewPosition({
    marketPrice,
    oldPosition,
    newPosition,
  })
  const summary: OperationSummary = {
    ...partialSummary,
    entryPrice: undefined,
    priceImpactPct: undefined,
    transactionFee: ZERO,
  }

  return { summary, txHashFn }
}

async function simulateWithdrawCollateral({
  api,
  request: _request,
  context,
}: {
  api: SilverKoiApi
  request: WithdrawCollateralRequest
  context: OperationContext
}): Promise<{ summary: OperationSummary; txHashFn: InvokeTransactionFn }> {
  const { marketId } = _request
  const request = {
    traderId: _request.traderId,
    positionSubId: _request.positionSubId,
    amountX6: _request.amount.raw(6),
  }
  const { oldPosition, newPosition, txHashFn } = await sk.simWithdrawCollateral({
    api,
    marketId,
    request,
  })

  let marketPrice: BigDecimal | undefined = context.market.marketPrice
  if (marketPrice.isZero()) {
    marketPrice = undefined
  }

  const partialSummary = createOperationSummaryFromOldAndNewPosition({
    marketPrice,
    oldPosition,
    newPosition,
  })
  const summary: OperationSummary = {
    ...partialSummary,
    entryPrice: undefined,
    priceImpactPct: undefined,
    transactionFee: ZERO,
  }

  return { summary, txHashFn }
}

async function simulateCreateTrigger({
  api,
  request: _request,
  context,
}: {
  api: SilverKoiApi
  request: CreateTriggerRequest
  context: OperationContext
}): Promise<{ summary: OperationSummary; txHashFn: InvokeTransactionFn }> {
  const { marketId } = _request
  const request = {
    traderId: _request.traderId,
    clientOrderId: _request.clientOrderId,
    positionSubId: _request.positionSubId,
    side: sk.encodeOrderSide(_request.side),
    sizeX5: _request.size.raw(5),
    fromAboveTriggerTick: _request.fromAboveTriggerTick,
    fromBelowTriggerTick: _request.fromBelowTriggerTick,
    fromAboveLimitTick: _request.fromAboveLimitTick,
    fromBelowLimitTick: _request.fromBelowLimitTick,
    tif: sk.encodeTimeInForce(_request.tif),
    postOnly: _request.postOnly,
    reduceOnly: _request.reduceOnly,
    targetLeverageX2: _request.targetLeverage.raw(2),
    maintainLeverage: _request.maintainLeverage,
  }
  const { trigger, txHashFn } = await sk.simCreateTrigger({ api, marketId, request })

  let marketPrice: BigDecimal | undefined = context.market.marketPrice
  if (marketPrice.isZero()) {
    marketPrice = undefined
  }

  const summary = createCreateTriggerSummary({ trigger })
  return { summary, txHashFn }
}

async function simulateReplaceTrigger({
  api,
  request: _request,
  /*context,*/
}: {
  api: SilverKoiApi
  request: ReplaceTriggerRequest
  context: OperationContext
}): Promise<{ summary: OperationSummary; txHashFn: InvokeTransactionFn }> {
  const { marketId } = _request
  const cancelRequest = {
    traderId: _request.traderId,
    clientOrderId: _request.oldClientOrderId,
  }
  const createRequest = {
    traderId: _request.traderId,
    clientOrderId: _request.clientOrderId,
    positionSubId: _request.positionSubId,
    side: sk.encodeOrderSide(_request.side),
    sizeX5: _request.size.raw(5),
    fromAboveTriggerTick: _request.fromAboveTriggerTick,
    fromBelowTriggerTick: _request.fromBelowTriggerTick,
    fromAboveLimitTick: _request.fromAboveLimitTick,
    fromBelowLimitTick: _request.fromBelowLimitTick,
    tif: sk.encodeTimeInForce(_request.tif),
    postOnly: _request.postOnly,
    reduceOnly: _request.reduceOnly,
    targetLeverageX2: _request.targetLeverage.raw(2),
    maintainLeverage: _request.maintainLeverage,
  }
  const { oldTrigger, newTrigger, txHashFn } = await sk.simReplaceTrigger({
    api,
    marketId,
    cancelRequest,
    createRequest,
  })

  const summary = createReplaceTriggerSummary({
    fromAbove: _request.fromAbove,
    oldTrigger,
    newTrigger,
  })
  return { summary, txHashFn }
}

async function simulatePlaceOrder({
  api,
  request,
  context,
}: {
  api: SilverKoiApi
  request: PlaceOrderRequest
  context: OperationContext
}): Promise<{ summary: OperationSummary; txHashFn: InvokeTransactionFn }> {
  const summary = localSimulatePlaceOrder({ request, context })
  const { txHashFn } = await evmSimulatePlaceOrder({ api, request, context })
  return { summary, txHashFn }
}

function getTickForPrice(price: BigDecimal, priceName: string): bigint {
  if (price.isZero()) return 0n

  try {
    return tickMath.priceToTick(price)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    if (isNotTickPriceError(err)) {
      const { prevPrice, nextPrice } = err
      const prevPriceStr = prevPrice.toStringTrimmed()
      const nextPriceStr = nextPrice.toStringTrimmed()
      throw makeSKError(
        "InvalidInputError",
        `${priceName} is not a valid tick. Closest alternatives are ${prevPriceStr} or ${nextPriceStr}`,
        {},
      )
    } else {
      throw err
    }
  }
}

async function evmSimulatePlaceOrder({
  api,
  request: _request,
  context,
}: {
  api: SilverKoiApi
  request: PlaceOrderRequest
  context: OperationContext
}): Promise<{ summary: OperationSummary; txHashFn: InvokeTransactionFn; enqueued: boolean }> {
  const { marketId } = _request
  const request = {
    traderId: _request.traderId,
    clientOrderId: _request.clientOrderId,
    positionSubId: _request.positionSubId,
    side: sk.encodeOrderSide(_request.side),
    sizeX5: _request.size.raw(5),
    tick: _request.tick,
    tif: sk.encodeTimeInForce(_request.tif),
    deadline: _request.deadline,
    postOnly: _request.postOnly,
    reduceOnly: _request.reduceOnly,
    targetLeverageX2: _request.targetLeverage.raw(2),
    maintainLeverage: _request.maintainLeverage,
  }
  const { oldPosition, newPosition, enqueued, txHashFn } = await sk.simPlaceOrder({
    api,
    marketId,
    request,
  })
  // TODO: bubble up "enqueued" and use this knowledge in ui somehow?

  let marketPrice: BigDecimal | undefined = context.market.marketPrice
  if (marketPrice.isZero()) {
    marketPrice = undefined
  }

  const partialSummary = createOperationSummaryFromOldAndNewPosition({
    marketPrice,
    oldPosition,
    newPosition,
  })
  const summary: OperationSummary = {
    ...partialSummary,
    entryPrice: undefined,
    priceImpactPct: undefined,
    transactionFee: ZERO,
  }

  return { summary, txHashFn, enqueued }
}

function localSimulatePlaceOrder({
  request,
  context,
}: {
  request: PlaceOrderRequest
  context: OperationContext
}): OperationSummary {
  const { symbol, position, market, traderConfig, orderBook } = context
  const { twapMarketPrice, mmRatio } = market
  const { takerFeeRatio, makerFeeRatio } = traderConfig
  const { side, size, tif, price, postOnly, targetLeverage } = request
  const bestBid = orderBook.bids.at(0)?.price
  const bestAsk = orderBook.asks.at(0)?.price

  const r = sk.computePlaceOrderResult({
    orderBook,
    side,
    size,
    price,
    tif,
    postOnly,
  })
  if (r.err) {
    switch (r.val.message) {
      case "FokNotFullyFilled":
        throw makeSKError("SimulationError", "Order will not completely fill", {})
      case "OrderWillCross":
        throw makeSKError("SimulationError", "Order will cross book", {})
      default:
        throw makeSKError("InternalError", r.val.message, {})
    }
  }
  const { takerNotional, makerNotional, takerSize, makerSize, newBestBid, newBestAsk } = r.val

  const oldMarketPrice = bestBid && bestAsk ? bestBid.add(bestAsk).div("2") : undefined
  const newMarketPrice = newBestBid && newBestAsk ? newBestBid.add(newBestAsk).div("2") : undefined
  const priceImpactPct =
    oldMarketPrice && newMarketPrice
      ? newMarketPrice.sub(oldMarketPrice).div(oldMarketPrice).mul("100")
      : undefined

  const takerFee = takerFeeRatio.mul(takerNotional)
  const makerFee = makerFeeRatio.mul(makerNotional)
  const transactionFee = takerFee.add(makerFee).round(6, "up")

  const diffSize = side === "bid" ? takerSize : takerSize.neg()
  if (diffSize.isZero() && tif === "ioc") {
    throw makeSKError("SimulationError", "Order will not execute against the book", {})
  }

  const newPositionSize = position.size.add(diffSize)

  const diffNotional = side === "bid" ? takerNotional : takerNotional.neg()
  const newOpenNotional = sk.computeNewOpenNotional({
    oldPositionSize: position.size,
    oldOpenNotional: position.openNotional,
    executedSize: diffSize,
    executedNotional: diffNotional,
  })

  const diffOpenInterestNotional = makerNotional
  const newOpenInterestNotional = position.openInterestNotional.add(diffOpenInterestNotional)
  const diffOpenInterestSize = makerSize
  const newOpenInterestSize = position.openInterestSize.add(diffOpenInterestSize)

  // TODO: support maintainLeverage = true

  // Below, we assume that twap market price does not change significantly after
  // this order and use the twap market price before the trader to compute
  // position notional and unrealized pnl.

  // If leverage is not specified, we don't change collatera.
  const marginRatio =
    targetLeverage && targetLeverage.gt(ZERO)
      ? BigDecimal.one().div(targetLeverage, 18)
      : position.leverage
  const diffCollateral = marginRatio
    ? sk.computeRequiredExtraCollateral({
        collateral: position.collateral,
        positionSize: newPositionSize,
        openNotional: newOpenNotional,
        openInterestNotional: newOpenInterestNotional,
        marketPrice: twapMarketPrice,
        marginRatio,
      })
    : ZERO
  const newCollateral = position.collateral.add(diffCollateral)

  const conservativeAccountValue = sk.computeConservativeAccountValue({
    collateral: newCollateral,
    positionSize: newPositionSize,
    openNotional: newOpenNotional,
    marketPrice: twapMarketPrice,
  })

  const newLeverage = sk.computeEffectiveLeverage({
    conservativeAccountValue,
    openInterestNotional: newOpenInterestNotional,
    positionSize: newPositionSize,
    openNotional: newOpenNotional,
    marketPrice: twapMarketPrice,
  })
  const diffLeverage =
    position.leverage && newLeverage ? newLeverage.sub(position.leverage) : undefined

  let newLiquidationPrice = sk.computeLiquidationPrice({
    collateral: newCollateral,
    openInterestNotional: newOpenInterestNotional,
    positionSize: newPositionSize,
    openNotional: newOpenNotional,
    mmRatio,
  })
  if (sk.isCurrentPrice(newLiquidationPrice)) {
    newLiquidationPrice = twapMarketPrice
  }
  const oldLiquidationPrice = sk.isCurrentPrice(position.liquidationPrice)
    ? twapMarketPrice
    : position.liquidationPrice
  const diffLiquidationPrice =
    oldLiquidationPrice && newLiquidationPrice
      ? newLiquidationPrice.sub(oldLiquidationPrice)
      : undefined

  const entryPrice = takerSize.isZero() ? undefined : takerNotional.abs().div(takerSize.abs(), 18)

  const summary = {
    symbol,
    collateral: {
      oldValue: position.collateral,
      newValue: newCollateral,
      diff: diffCollateral,
    },
    positionSize: {
      oldValue: position.size,
      newValue: newPositionSize,
      diff: diffSize,
    },
    openNotional: {
      oldValue: position.openNotional,
      newValue: newOpenNotional,
      diff: diffNotional,
    },
    openInterestSize: {
      oldValue: position.openInterestSize,
      newValue: newOpenInterestSize,
      diff: diffOpenInterestSize,
    },
    openInterestNotional: {
      oldValue: position.openInterestNotional,
      newValue: newOpenInterestNotional,
      diff: diffOpenInterestNotional,
    },
    leverage: {
      oldValue: position.leverage,
      newValue: newLeverage,
      diff: diffLeverage,
    },
    liquidationPrice: {
      oldValue: oldLiquidationPrice,
      newValue: newLiquidationPrice,
      diff: diffLiquidationPrice,
    },
    entryPrice,
    priceImpactPct,
    transactionFee,
  }
  return summary
}

export const DEFAULT_FORMATTED_OPERATION_SUMMARY = {
  description: "Unknown",
  descriptionColor: COLORS.WHITE,
  name: "-",
  symbol: "-",
  ticker: "-",
  entryPrice: "-",
  collateral: "-",
  positionSize: "-",
  openNotional: "-",
  openInterestNotional: "-",
  priceImpact: "-",
  liquidationPrice: "-",
  leverage: "-",
  transactionFee: "-",
}

// TODO: deprecate this method after refactoring confirmation modal
export function formatOperationSummary(summary?: OperationSummary): FormattedOperationSummary {
  if (!summary) {
    return DEFAULT_FORMATTED_OPERATION_SUMMARY
  }

  const entryPrice = !summary?.entryPrice ? "-" : `$${summary.entryPrice.toString(2)}`

  const [description, descriptionColor] = ((): [string, string] => {
    if (!summary.positionSize || !summary.collateral) {
      return ["Unknown", COLORS.WHITE]
    }

    if (summary.positionSize.diff.raw() != 0n) {
      if (summary.positionSize.oldValue.eq(ZERO)) {
        if (summary.positionSize.diff.lt(ZERO)) {
          return ["Open Short Position", COLORS.RED]
        } else {
          return ["Open Long Position", COLORS.GREEN]
        }
      } else if (summary.positionSize.oldValue.lt(ZERO)) {
        if (summary.positionSize.newValue.isZero()) {
          return ["Close Short Position", COLORS.RED]
        } else if (summary.positionSize.diff.lt(ZERO)) {
          return ["Increase Short Position", COLORS.GREEN]
        } else {
          return ["Reduce Short Position", COLORS.RED]
        }
      } else {
        if (summary.positionSize.newValue.isZero()) {
          return ["Close Long Position", COLORS.RED]
        } else if (summary.positionSize.diff.lt(ZERO)) {
          return ["Reduce Long Position", COLORS.RED]
        } else {
          return ["Increase Long Position", COLORS.GREEN]
        }
      }
    } else if (summary.collateral.diff.lt(ZERO)) {
      return ["Reduce Collateral", COLORS.RED]
    } else if (summary.collateral.diff.gt(ZERO)) {
      return ["Add Collateral", COLORS.GREEN]
    } else {
      return ["Unknown", COLORS.WHITE]
    }
  })()

  const formatDelta = (
    delta: Delta<BigDecimal | undefined> | undefined,
    args?: FormatValueArgs,
  ) => {
    if (!delta) return ["-", RIGHT_ARROW_TEXT, "-"].join(" ")
    const oldStr = formatValue(delta.oldValue, args)
    const newStr = formatValue(delta.newValue, args)
    return [oldStr, RIGHT_ARROW_TEXT, newStr].join(" ")
  }

  const collateral = formatDelta(summary.collateral, { decimals: 6, dollar: true })
  const positionSize = formatDelta(summary.positionSize, { signed: true })
  const openNotional = formatDelta(summary.openNotional, { decimals: 6, dollar: true })
  const openInterestNotional = formatDelta(summary.openInterestNotional, {
    decimals: 6,
    dollar: true,
  })
  const leverage = formatDelta(summary.leverage, { decimals: 2, suffix: "X" })
  const priceImpact = !summary.priceImpactPct ? "-" : `${summary.priceImpactPct.toString(2)}%`

  const liquidationPrice = formatDelta(summary.liquidationPrice, { decimals: 2, dollar: true })
  const transactionFee = formatValue(summary.transactionFee, { decimals: 6, dollar: true })

  const { displayName } = getSymbolMeta(summary.symbol)
  return {
    description,
    descriptionColor,
    symbol: summary.symbol,
    ticker: displayName,
    entryPrice,
    collateral,
    positionSize,
    openNotional,
    openInterestNotional,
    leverage,
    priceImpact,
    liquidationPrice,
    transactionFee,
  }
}

function createDelta(oldValue: BigDecimal, newValue: BigDecimal): Delta<BigDecimal> {
  return { oldValue, newValue, diff: newValue.sub(oldValue) }
}

function createMaybeDelta(
  oldValue: BigDecimal | undefined,
  newValue: BigDecimal | undefined,
): Delta<BigDecimal | undefined> {
  if (oldValue && newValue) {
    return { oldValue, newValue, diff: newValue.sub(oldValue) }
  } else {
    return { oldValue, newValue, diff: undefined }
  }
}

function createOperationSummaryFromOldAndNewPosition({
  marketPrice,
  oldPosition,
  newPosition,
}: {
  marketPrice?: BigDecimal
  oldPosition: PositionSnapshot
  newPosition: PositionSnapshot
}): Omit<OperationSummary, "entryPrice" | "priceImpact" | "transactionFee"> {
  const symbol = oldPosition.symbolMeta.symbol

  const collateral = createDelta(oldPosition.collateral, newPosition.collateral)
  const positionSize = createDelta(oldPosition.size, newPosition.size)
  const openNotional = createDelta(oldPosition.openNotional, newPosition.openNotional)
  const openInterestSize = createDelta(oldPosition.openInterestSize, newPosition.openInterestSize)
  const openInterestNotional = createDelta(
    oldPosition.openInterestNotional,
    newPosition.openInterestNotional,
  )
  const leverage = createMaybeDelta(oldPosition.leverage, newPosition.leverage)

  const oldLiquidationPrice = isCurrentPrice(oldPosition.liquidationPrice)
    ? marketPrice
    : oldPosition.liquidationPrice
  const newLiquidationPrice = isCurrentPrice(newPosition.liquidationPrice)
    ? marketPrice
    : newPosition.liquidationPrice
  const liquidationPrice = createMaybeDelta(oldLiquidationPrice, newLiquidationPrice)

  return {
    symbol,
    collateral,
    positionSize,
    openNotional,
    openInterestSize,
    openInterestNotional,
    leverage,
    liquidationPrice,
  }
}

function createCreateTriggerSummary({ trigger }: { trigger: TriggerSnapshot }): OperationSummary {
  const symbol = trigger.symbolMeta.symbol
  const isBid = trigger.side === "bid"
  const slTriggerPrice = isBid ? trigger.fromBelowTriggerPrice : trigger.fromAboveTriggerPrice
  const slLimitPrice = isBid ? trigger.fromBelowLimitPrice : trigger.fromAboveLimitPrice
  const tpTriggerPrice = !isBid ? trigger.fromBelowTriggerPrice : trigger.fromAboveTriggerPrice
  const tpLimitPrice = !isBid ? trigger.fromBelowLimitPrice : trigger.fromAboveLimitPrice

  const zeroToUndefined = (x: BigDecimal) => {
    return x.isZero() ? undefined : x
  }

  return {
    symbol,
    triggerSide: trigger.side,
    triggerSize: trigger.size,
    tpTriggerPrice: createMaybeDelta(undefined, zeroToUndefined(tpTriggerPrice)),
    tpLimitPrice: createMaybeDelta(undefined, zeroToUndefined(tpLimitPrice)),
    slTriggerPrice: createMaybeDelta(undefined, zeroToUndefined(slTriggerPrice)),
    slLimitPrice: createMaybeDelta(undefined, zeroToUndefined(slLimitPrice)),
  }
}

function createReplaceTriggerSummary({
  fromAbove,
  oldTrigger,
  newTrigger,
}: {
  fromAbove: boolean
  oldTrigger: TriggerSnapshot
  newTrigger: TriggerSnapshot
}): OperationSummary {
  const symbol = oldTrigger.symbolMeta.symbol

  const triggerPrice = createDelta(
    fromAbove ? oldTrigger.fromAboveTriggerPrice : oldTrigger.fromBelowTriggerPrice,
    fromAbove ? newTrigger.fromAboveTriggerPrice : newTrigger.fromBelowTriggerPrice,
  )
  const limitPrice = createDelta(
    fromAbove ? oldTrigger.fromAboveLimitPrice : oldTrigger.fromBelowLimitPrice,
    fromAbove ? newTrigger.fromAboveLimitPrice : newTrigger.fromBelowLimitPrice,
  )

  const isBid = newTrigger.side === "bid"
  const isTP = (isBid && fromAbove) || (!isBid && !fromAbove)

  const tpTriggerPrice = isTP ? triggerPrice : undefined
  const slTriggerPrice = isTP ? undefined : triggerPrice
  const tpLimitPrice = isTP ? limitPrice : undefined
  const slLimitPrice = isTP ? undefined : limitPrice

  return {
    symbol,
    triggerSide: newTrigger.side,
    triggerSize: newTrigger.size,
    tpTriggerPrice,
    tpLimitPrice,
    slTriggerPrice,
    slLimitPrice,
  }
}

export function getRequiredApproveAmount(simResult?: SimulationResult): bigint {
  if (simResult?.err) {
    const error = simResult.val.error
    if (isApproveNeededError(error)) {
      return error.amountXS
    }
  }
  return 0n
}
