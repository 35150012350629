import { Address, Hash } from "viem"

import { BigDecimal } from "./math"
import { SymbolMeta } from "./symbol"

// TODO: tagged type alias for market id, trader id, etc. Example:
// declare const tagMarketId: unique symbol;
// type MarketId = bigint & { [tagMarketId]: true }

export type OrderSide = "bid" | "ask"

export type TimeInForce = "ioc" | "fok" | "gtc" | "gtd"

export type OrderState = "created" | "onbook" | "inactive"

export type LiquidationPriceFlag = "defined" | "undefined" | "liquidatable"

// This is a special type used to describe liquidation/bankruptcy price to
// indicate that the liquidation/bankruptcy price is equal to the "current"
// market price, whatever that price may be.
const tagCurrentPrice: unique symbol = Symbol()
export type CurrentPrice = { [tagCurrentPrice]: true }
export const CURRENT_PRICE = { [tagCurrentPrice]: true } as const
export function isCurrentPrice(obj: unknown): obj is CurrentPrice {
  const typedObj = obj as CurrentPrice
  return (
    // eslint-disable-next-line no-null/no-null
    typedObj !== null &&
    typeof typedObj === "object" &&
    typeof typedObj[tagCurrentPrice] === "boolean" &&
    typedObj[tagCurrentPrice] === true
  )
}

export interface FundingRate {
  longHourlyRate: BigDecimal
  shortHourlyRate: BigDecimal
  longYearlyRate: BigDecimal
  shortYearlyRate: BigDecimal
  lastDistributeTimestamp: bigint
}

export interface TraderInfo {
  address: Address
  traderId?: bigint
}

export interface TraderConfig {
  traderId: bigint
  marketId: bigint
  makerFeeRatio: BigDecimal
  takerFeeRatio: BigDecimal
}

export interface MarketMeta {
  address: Address
  symbol: string
  marketId: bigint
}

export interface MarketSnapshot extends FundingRate {
  symbol: string
  marketId: bigint
  imRatio: BigDecimal
  mmRatio: BigDecimal
  maxInitialLeverage: BigDecimal
  marketPrice: BigDecimal
  oraclePrice: BigDecimal
  bestBid: BigDecimal
  bestAsk: BigDecimal
  twapInterval: bigint
  twapMarketPrice: BigDecimal
}

export interface UserBalances {
  nativeBalance: BigDecimal
  usdcBalance: BigDecimal
  usdcAllowance: BigDecimal
  accountValue: BigDecimal
}

export interface OrderSnapshot {
  symbolMeta: SymbolMeta
  marketMeta: MarketMeta
  traderId: bigint
  positionId: bigint
  positionSubId: bigint
  orderId: bigint
  clientOrderId: bigint
  size: BigDecimal
  tick: bigint
  price: BigDecimal
  side: OrderSide
  tif: TimeInForce
  postOnly: boolean
  reduceOnly: boolean
  state: OrderState
  deadline: bigint
  remainingSize: BigDecimal
  executedSize: BigDecimal
  executedNotional: BigDecimal
}

export interface TriggerSnapshot {
  symbolMeta: SymbolMeta
  marketMeta: MarketMeta
  traderId: bigint
  positionId: bigint
  positionSubId: bigint
  triggerId: bigint
  clientOrderId: bigint
  size: BigDecimal
  fromAboveTriggerTick: bigint
  fromBelowTriggerTick: bigint
  fromAboveLimitTick: bigint
  fromBelowLimitTick: bigint
  fromAboveTriggerPrice: BigDecimal
  fromBelowTriggerPrice: BigDecimal
  fromAboveLimitPrice: BigDecimal
  fromBelowLimitPrice: BigDecimal
  side: OrderSide
  tif: TimeInForce
  postOnly: boolean
  reduceOnly: boolean
  targetLeverage: BigDecimal
  maintainLeverage: boolean
}

export interface PositionSnapshot {
  symbolMeta: SymbolMeta
  marketMeta: MarketMeta
  traderId: bigint
  positionId: bigint
  positionSubId: bigint
  size: BigDecimal
  openNotional: BigDecimal
  notional: BigDecimal
  longOpenInterestSize: BigDecimal
  longOpenInterestNotional: BigDecimal
  shortOpenInterestSize: BigDecimal
  shortOpenInterestNotional: BigDecimal
  openInterestSize: BigDecimal
  openInterestNotional: BigDecimal
  // This is equal to the total collateral for this position. This includes
  // vault balance and unsettled funding.
  collateral: BigDecimal
  unrealizedPnl: BigDecimal
  unsettledFunding: BigDecimal
  // This is the total collateral the user can use towards margin requirement
  // for the specified position. This equals the sum of collateral above and \
  // NEGATIVE unrealized pnl.
  conservativeAccountValue: BigDecimal
  // This equals how much collateral the user can withdraw without violating
  // initial margin requirements. This is equal to conservative account value
  // minus initial margin requirement.
  freeCollateral: BigDecimal
  badDebt: BigDecimal
  entryPrice?: BigDecimal
  conservativeLiquidationPrice?: BigDecimal | CurrentPrice
  liquidationPrice?: BigDecimal | CurrentPrice
  conservativeBankruptcyPrice?: BigDecimal | CurrentPrice
  bankruptcyPrice?: BigDecimal
  marginRatio?: BigDecimal
  leverage?: BigDecimal
  empty: boolean
}

export interface DiscretizedOrderBookPriceLevel {
  price: BigDecimal
  size: BigDecimal
}

export interface DiscretizedOrderBook {
  bestBid: BigDecimal
  bestAsk: BigDecimal
  bids: DiscretizedOrderBookPriceLevel[]
  asks: DiscretizedOrderBookPriceLevel[]
}

export interface OrderBookOrder {
  marketId: bigint
  orderId: bigint
  price: BigDecimal
  size: BigDecimal
  deadline: bigint
}

export interface OrderBook {
  bids: OrderBookOrder[]
  asks: OrderBookOrder[]
}

export interface PlaceOrderResult {
  takerNotional: BigDecimal
  takerSize: BigDecimal
  makerNotional: BigDecimal
  makerSize: BigDecimal
  remainingSize: BigDecimal
  newBestBid?: BigDecimal
  newBestAsk?: BigDecimal
}

export interface PlaceOrderError {
  type: "PlaceOrderError"
  message: "OrderWillCross" | "FokNotFullyFilled"
}

export interface OperationTransactionSimulationResult {
  oldPosition: PositionSnapshot
  newPosition: PositionSnapshot
  enqueued: boolean
  txHashFn: () => Promise<Hash>
}

export interface CreateTriggerSimulationResult {
  oldPosition: PositionSnapshot
  newPosition: PositionSnapshot
  trigger: TriggerSnapshot
  enqueued: boolean
  txHashFn: () => Promise<Hash>
}

export interface ReplaceTriggerSimulationResult {
  oldTrigger: TriggerSnapshot
  newTrigger: TriggerSnapshot
  enqueued: boolean
  txHashFn: () => Promise<Hash>
}
