import * as BigIntMath from "./bigintMath"
import { Rounding } from "./rounding"

interface FormatOptions {
  decimals?: bigint | number
  minDecimals?: bigint | number
  signed?: boolean
  locale?: string
}

export const Sign = {
  Positive: 1n,
  Negative: -1n,
  Zero: 0n,
} as const

export class BigDecimal {
  private readonly _rawValue: bigint
  private readonly _decimals: bigint

  constructor(rawValue: bigint, decimals: bigint) {
    this._rawValue = rawValue
    this._decimals = decimals
  }

  // TODO: Add rounding method as a parameter. For now, rounds torward zero.
  static fromReal(value: number, decimals?: bigint | number) {
    if (decimals === undefined) {
      return BigDecimal.fromString(`${value}`)
    } else {
      return new BigDecimal(
        BigInt(Math.round(value * 10 ** Number(BigInt(decimals)))),
        BigInt(decimals),
      )
    }
  }

  static fromRaw(rawValue: bigint | number, decimals: bigint | number) {
    return new BigDecimal(BigInt(rawValue), BigInt(decimals))
  }

  static fromString(value: string) {
    const regex = /^-?\d*(\.\d*)?$/
    const match = value.match(regex)
    if (!value || !match) {
      throw new Error(`invalid decimal string: ${value}`)
    }

    const parts = value.split(".")
    if (parts.length == 1) {
      const [int] = parts
      return new BigDecimal(BigInt(int), 0n)
    } else {
      const [int, frac] = parts
      return new BigDecimal(BigInt(int + frac), BigInt(frac.length))
    }
  }

  static zero(): BigDecimal {
    return new BigDecimal(0n, 0n)
  }

  static one(): BigDecimal {
    return new BigDecimal(1n, 0n)
  }

  static normalize(value: BigDecimal | string | bigint): BigDecimal {
    if (typeof value === "string") {
      return BigDecimal.fromString(value)
    } else if (typeof value === "bigint") {
      return BigDecimal.fromRaw(value, 0n)
    } else {
      return value
    }
  }

  decimals(): bigint {
    return this._decimals
  }

  raw(decimals?: bigint | number): bigint {
    if (decimals === undefined) {
      return this._rawValue
    } else {
      return this.round(decimals).raw()
    }
  }

  real(): number {
    return Number(this._rawValue) / 10 ** Number(this._decimals)
  }

  toString(decimals?: bigint | number): string {
    if (decimals === undefined) {
      decimals = this._decimals
    } else {
      decimals = BigInt(decimals)
    }
    const rounded = this.round(decimals)
    const frac = (BigIntMath.abs(rounded.raw()) % 10n ** decimals)
      .toString()
      .padStart(Number(decimals), "0")
    const intg = rounded.raw() / 10n ** decimals
    const sign = intg === 0n && this._rawValue < 0n ? "-" : ""
    if (decimals === 0n) {
      return `${sign}${intg}`
    } else {
      return `${sign}${intg}.${frac}`
    }
  }

  toStringSigned(decimals?: bigint | number): string {
    const result = this.toString(decimals)
    if (this._rawValue > 0) {
      return `+${result}`
    } else {
      return result
    }
  }

  toStringTrimmed(decimals?: bigint | number): string {
    if (this._rawValue === 0n) return "0"

    const result = this.toString(decimals)
    if (result.includes(".")) {
      const tmp = result.replace(/0*$/, "")
      if (tmp.slice(-1) === ".") {
        return tmp.slice(0, -1)
      } else {
        return tmp
      }
    } else {
      return result
    }
  }

  format(opts?: FormatOptions): string {
    opts = opts ?? {}
    const { decimals, minDecimals, signed, locale } = opts

    let result = this.toString(decimals)

    if (minDecimals !== undefined) {
      if (decimals !== undefined && Number(minDecimals) > Number(decimals)) {
        throw new Error(`min decimals (${minDecimals}) is greater than decimals ${decimals}`)
      }

      const trimmedResult = this.toStringTrimmed(decimals)
      const minDecResult = this.toString(minDecimals)
      if (trimmedResult.length < minDecResult.length) {
        result = minDecResult
      } else {
        result = trimmedResult
      }
    }

    if (locale) {
      // Take the integer part, cast it to bigint, and use
      // BigInt.toLocaleString() to format the integer part.
      const parts = result.split(".")
      if (parts.length == 1) {
        const [int] = parts
        const intStr = BigInt(int).toLocaleString(locale)
        result = intStr
      } else {
        const [int, frac] = parts
        const intStr = BigInt(int).toLocaleString(locale)
        result = `${intStr}.${frac}`
        if (this._rawValue < 0 && intStr === "0") {
          result = `-${result}`
        }
      }
    }

    if (signed && this._rawValue > 0) {
      result = `+${result}`
    }

    return result
  }

  isZero(): boolean {
    return this._rawValue === 0n
  }

  abs(): BigDecimal {
    return new BigDecimal(BigIntMath.abs(this._rawValue), this._decimals)
  }

  neg(): BigDecimal {
    return new BigDecimal(-this._rawValue, this._decimals)
  }

  add(o: BigDecimal | string | bigint): BigDecimal {
    o = BigDecimal.normalize(o)
    const decimals = BigIntMath.max(this._decimals, o._decimals)
    const thisRaw = this._rawValue * 10n ** (decimals - this._decimals)
    const otherRaw = o._rawValue * 10n ** (decimals - o._decimals)
    return new BigDecimal(thisRaw + otherRaw, decimals)
  }

  sub(o: BigDecimal | string | bigint): BigDecimal {
    o = BigDecimal.normalize(o)
    const decimals = BigIntMath.max(this._decimals, o._decimals)
    const thisRaw = this._rawValue * 10n ** (decimals - this._decimals)
    const otherRaw = o._rawValue * 10n ** (decimals - o._decimals)
    return new BigDecimal(thisRaw - otherRaw, decimals)
  }

  mul(o: BigDecimal | string | bigint): BigDecimal {
    o = BigDecimal.normalize(o)
    const decimals = this._decimals + o._decimals
    return new BigDecimal(this._rawValue * o._rawValue, decimals)
  }

  div(o: BigDecimal | string | bigint, decimals?: bigint | number | undefined): BigDecimal {
    o = BigDecimal.normalize(o)
    decimals = BigInt(decimals ?? BigIntMath.max(this._decimals, o._decimals))
    const numerRaw = this.raw(decimals)
    const denomRaw = o.raw(decimals)
    const resultRaw = (numerRaw * 10n ** decimals) / denomRaw
    return new BigDecimal(resultRaw, decimals)
  }

  min(o: BigDecimal): BigDecimal {
    return this.le(o) ? this : o
  }

  max(o: BigDecimal): BigDecimal {
    return this.ge(o) ? this : o
  }

  lt(o: BigDecimal): boolean {
    const maxDecimals = BigIntMath.max(this._decimals, o._decimals)
    const _this = this.round(maxDecimals)
    const _that = o.round(maxDecimals)
    return _this._rawValue < _that._rawValue
  }

  gt(o: BigDecimal): boolean {
    const maxDecimals = BigIntMath.max(this._decimals, o._decimals)
    const _this = this.round(maxDecimals)
    const _that = o.round(maxDecimals)
    return _this._rawValue > _that._rawValue
  }

  le(o: BigDecimal): boolean {
    const maxDecimals = BigIntMath.max(this._decimals, o._decimals)
    const _this = this.round(maxDecimals)
    const _that = o.round(maxDecimals)
    return _this._rawValue <= _that._rawValue
  }

  ge(o: BigDecimal): boolean {
    const maxDecimals = BigIntMath.max(this._decimals, o._decimals)
    const _this = this.round(maxDecimals)
    const _that = o.round(maxDecimals)
    return _this._rawValue >= _that._rawValue
  }

  eq(o: BigDecimal): boolean {
    const maxDecimals = BigIntMath.max(this._decimals, o._decimals)
    const _this = this.round(maxDecimals)
    const _that = o.round(maxDecimals)
    return _this._rawValue === _that._rawValue
  }

  round(decimals: bigint | number, rounding: Rounding = "to-zero"): BigDecimal {
    const decimalsInt = BigInt(decimals)
    if (decimalsInt >= this._decimals) {
      return new BigDecimal(this._rawValue * 10n ** (decimalsInt - this._decimals), decimalsInt)
    } else {
      const raw = BigIntMath.div(this._rawValue, 10n ** (this._decimals - decimalsInt), rounding)
      return new BigDecimal(raw, decimalsInt)
    }
  }

  sign(): bigint {
    if (this._rawValue === 0n) {
      return Sign.Zero
    } else if (this._rawValue < 0n) {
      return Sign.Negative
    } else {
      return Sign.Positive
    }
  }
}
