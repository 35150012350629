import { BigDecimal } from "./math/bigdecimal"
import { CURRENT_PRICE, CurrentPrice } from "./types"

const ZERO = BigDecimal.zero()

// Pre: executedSize.sign() === executedNotional.sign()
export function computeNewOpenNotional({
  oldPositionSize,
  oldOpenNotional,
  executedSize,
  executedNotional,
}: {
  oldPositionSize: BigDecimal
  oldOpenNotional: BigDecimal
  executedSize: BigDecimal
  executedNotional: BigDecimal
}): BigDecimal {
  if (executedSize.sign() !== executedNotional.sign()) {
    throw new Error(`executed size and notional must have same sign`)
  }
  if (oldPositionSize.sign() !== oldOpenNotional.sign()) {
    throw new Error(`old position size and open notional must have same sign`)
  }

  const newPositionSize = oldPositionSize.add(executedSize)

  if (oldPositionSize.isZero()) {
    return executedNotional
  }
  if (newPositionSize.isZero()) {
    return ZERO
  }

  const reversed = oldPositionSize.sign() !== newPositionSize.sign()

  if (reversed) {
    // Reversed position
    return executedNotional.mul(newPositionSize.abs()).div(executedSize.abs(), 18)
  } else if (oldPositionSize.abs().lt(newPositionSize.abs())) {
    // Increasing position
    return oldOpenNotional.add(executedNotional)
  } else {
    // Decreasing position
    return oldOpenNotional.mul(newPositionSize.abs()).div(oldPositionSize.abs(), 18)
  }
}

// Returns the extra collateral required to meet the specified margin ratio.
export function computeRequiredExtraCollateral({
  collateral,
  positionSize,
  openNotional,
  openInterestNotional,
  marketPrice,
  marginRatio,
}: {
  collateral: BigDecimal
  positionSize: BigDecimal
  openNotional: BigDecimal
  openInterestNotional: BigDecimal
  marketPrice: BigDecimal
  marginRatio: BigDecimal
}): BigDecimal {
  const marginRequirement = computeMarginRequirement({
    positionSize,
    openNotional,
    openInterestNotional,
    marketPrice,
    marginRatio,
  })
  const accountValue = computeConservativeAccountValue({
    collateral,
    positionSize,
    openNotional,
    marketPrice,
  })
  return marginRequirement.sub(accountValue)
}

export function computeConservativeAccountValue({
  collateral,
  positionSize,
  openNotional,
  marketPrice,
}: {
  collateral: BigDecimal
  positionSize: BigDecimal
  openNotional: BigDecimal
  marketPrice: BigDecimal
}): BigDecimal {
  const unrealizedPnl = positionSize.mul(marketPrice).sub(openNotional)
  if (unrealizedPnl.lt(ZERO)) {
    return collateral.add(unrealizedPnl)
  } else {
    return collateral
  }
}

export function computeMarginRequirement({
  positionSize,
  openNotional,
  openInterestNotional,
  marketPrice,
  marginRatio,
}: {
  positionSize: BigDecimal
  openNotional: BigDecimal
  openInterestNotional: BigDecimal
  marketPrice: BigDecimal
  marginRatio: BigDecimal
}): BigDecimal {
  const debt = computeDebt({ positionSize, openNotional, openInterestNotional, marketPrice })
  return debt.mul(marginRatio)
}

export function computeDebt({
  positionSize,
  openNotional,
  openInterestNotional,
  marketPrice,
}: {
  positionSize: BigDecimal
  openNotional: BigDecimal
  openInterestNotional: BigDecimal
  marketPrice: BigDecimal
}): BigDecimal {
  const activeDebt = positionSize.lt(ZERO) ? positionSize.abs().mul(marketPrice) : openNotional
  return activeDebt.add(openInterestNotional)
}

// Returns the liquidation price given the specified information.
// If there is no liquidation price, returns undefined.
// If liquidation price is the "current market price", returns the singleton
// type CurrentPrice.
export function computeLiquidationPrice({
  collateral,
  openInterestNotional,
  positionSize,
  openNotional,
  mmRatio,
}: {
  collateral: BigDecimal
  openInterestNotional: BigDecimal
  positionSize: BigDecimal
  openNotional: BigDecimal
  mmRatio: BigDecimal
}): BigDecimal | CurrentPrice | undefined {
  const debt = openNotional.abs().add(openInterestNotional)
  const marginRequirement = debt.mul(mmRatio)
  const marginOpenInterest = openInterestNotional.mul(mmRatio)

  if (collateral.lt(marginRequirement)) {
    if (mmRatio.isZero() || positionSize.ge(ZERO)) {
      // This position is liquidatable right now.
      return CURRENT_PRICE
    } else {
      const numer = collateral.sub(marginOpenInterest)
      const denom = mmRatio.mul(positionSize.abs())
      return numer.div(denom, 18)
    }
  } else {
    if (positionSize.isZero()) {
      // This position is never liquidatable
      return undefined
    } else if (positionSize.gt(ZERO)) {
      const numer = collateral.sub(marginRequirement)
      const denom = positionSize.abs()
      const entryPrice = openNotional.div(positionSize, 18)
      const result = entryPrice.sub(numer.div(denom, 18))
      return result.lt(ZERO) ? ZERO : result
    } else {
      const ONE = BigDecimal.fromRaw(1, 0)
      const numer = collateral.sub(marginRequirement)
      const denom = positionSize.abs().mul(ONE.add(mmRatio))
      const entryPrice = openNotional.div(positionSize, 18)
      return entryPrice.add(numer.div(denom, 18))
    }
  }
}

// Returns the bankruptcy price given the specified information.
// If there is no bankruptcy price, returns undefined.
// If bankruptcy price is the "current market price", returns the singleton type
// CurrentPrice.
export function computeBankruptcyPrice({
  collateral,
  positionSize,
  openNotional,
}: {
  collateral: BigDecimal
  positionSize: BigDecimal
  openNotional: BigDecimal
}): BigDecimal | CurrentPrice | undefined {
  if (collateral.lt(ZERO)) {
    // This position is bankrupt right now.
    return CURRENT_PRICE
  } else if (positionSize.isZero()) {
    // This position is never bankruptable.
    return undefined
  } else {
    const entryPrice = openNotional.div(positionSize, 18)
    const result = entryPrice.sub(collateral.div(positionSize, 18))
    return result.lt(ZERO) ? ZERO : result
  }
}

export function computeEffectiveMargin({
  conservativeAccountValue,
  openInterestNotional,
  positionSize,
  openNotional,
  marketPrice,
}: {
  conservativeAccountValue: BigDecimal
  openInterestNotional: BigDecimal
  positionSize: BigDecimal
  openNotional: BigDecimal
  marketPrice: BigDecimal
}): BigDecimal | undefined {
  // If conservative account value is negative, this position is bankrupt and it
  // does not make sense to talk about this position's margin/leverage.
  if (conservativeAccountValue.lt(ZERO)) {
    return undefined
  }

  const activeDebt = positionSize.lt(ZERO) ? positionSize.abs().mul(marketPrice) : openNotional
  const debt = activeDebt.add(openInterestNotional)
  if (debt.isZero()) {
    return undefined
  }

  const margin = conservativeAccountValue.div(debt, 18)
  return margin
}

export function computeEffectiveLeverage({
  conservativeAccountValue,
  openInterestNotional,
  positionSize,
  openNotional,
  marketPrice,
}: {
  conservativeAccountValue: BigDecimal
  openInterestNotional: BigDecimal
  positionSize: BigDecimal
  openNotional: BigDecimal
  marketPrice: BigDecimal
}): BigDecimal | undefined {
  const margin = computeEffectiveMargin({
    conservativeAccountValue,
    openInterestNotional,
    positionSize,
    openNotional,
    marketPrice,
  })
  if (margin === undefined) {
    return undefined
  } else {
    return BigDecimal.one().div(margin, 18)
  }
}
