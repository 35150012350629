import { Chain } from "silverkoi"

import { Token, getTokenLogo } from "./assets"

export interface NativeTokenMeta {
  name: Token
  image: string
}

const NATIVE_TOKEN_METAS: Record<Token, NativeTokenMeta> = {
  ETH: { name: "ETH", image: getTokenLogo("ETH") },
  MATIC: { name: "MATIC", image: getTokenLogo("MATIC") },
} as const

export const getNativeTokenMeta = (chain: Chain): NativeTokenMeta => {
  switch (chain) {
    case "arbitrum-sepolia":
      return NATIVE_TOKEN_METAS["ETH"]
    case "plume-testnet":
      return NATIVE_TOKEN_METAS["ETH"]
    default:
      throw new Error(`unsupported chain: ${chain}`)
  }
}
