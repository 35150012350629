import { PropsWithChildren, useRef } from "react"

import { useScrollView } from "../hooks"

interface Props {}

export const LeftRightScrollableArea = ({ children }: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement | null>(null) // eslint-disable-line no-null/no-null
  const { atLeft, atRight, onScroll } = useScrollView(ref)

  return (
    <div className="relative flex flex-row min-w-0 min-h-0 overflow-hidden">
      {!atLeft && (
        <div className="absolute left-0 shrink-0 h-full w-2 background-white-gradient-[-90deg] z-10" />
      )}

      <div ref={ref} onScroll={onScroll} className="flex flex-row overflow-x-scroll no-scrollbar">
        {children}
      </div>

      {!atRight && (
        <div className="absolute right-0 shrink-0 h-full w-2 background-white-gradient-[90deg] z-10" />
      )}
    </div>
  )
}
