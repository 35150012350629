import { Address, getAddress } from "viem"

import { Chain } from "./chain"
import { abi as erc20Abi } from "./data/abis/common/IERC20"
import { abi as multicall3Abi } from "./data/abis/common/IMulticall3"
import { abi as plumeTestnetMarketAbi } from "./data/abis/plume-testnet/IMarket"
import { abi as plumeTestnetSilverKoiAbi } from "./data/abis/plume-testnet/ISilverKoi"
import { abi as plumeTestnetVaultAbi } from "./data/abis/plume-testnet/IVault"
import { abi as plumeTestnetTestnetFaucetAbi } from "./data/abis/plume-testnet/TestnetFaucet"
import DEPLOYMENT_PLUME_TESTNET_SILVER_KOI from "./data/deployments/plume-testnet/SilverKoi.json"
import DEPLOYMENT_PLUME_TESTNET_TESTNET_FAUCET from "./data/deployments/plume-testnet/TestnetFaucet.json"
import DEPLOYMENT_PLUME_TESTNET_VAULT from "./data/deployments/plume-testnet/Vault.json"

interface PlumeTestnetAbis {
  erc20: typeof erc20Abi
  multicall3: typeof multicall3Abi
  market: typeof plumeTestnetMarketAbi
  silverKoi: typeof plumeTestnetSilverKoiAbi
  vault: typeof plumeTestnetVaultAbi
  faucet: typeof plumeTestnetTestnetFaucetAbi
}

interface PlumeTestnetDeploymentAddresses {
  silverKoi: Address
  vault: Address
  usdc?: Address
  faucet?: Address
  multicall3?: Address
}

export interface PlumeTestnetSilverKoiData {
  abis: PlumeTestnetAbis
  addresses: PlumeTestnetDeploymentAddresses
}

export function loadPlumeTestnetSilverKoiData(): PlumeTestnetSilverKoiData {
  return {
    abis: {
      erc20: erc20Abi,
      multicall3: multicall3Abi,
      market: plumeTestnetMarketAbi,
      silverKoi: plumeTestnetSilverKoiAbi,
      vault: plumeTestnetVaultAbi,
      faucet: plumeTestnetTestnetFaucetAbi,
    },
    addresses: {
      silverKoi: getAddress(DEPLOYMENT_PLUME_TESTNET_SILVER_KOI.address),
      vault: getAddress(DEPLOYMENT_PLUME_TESTNET_VAULT.address),
      faucet: getAddress(DEPLOYMENT_PLUME_TESTNET_TESTNET_FAUCET.address),
    },
  }
}

// TODO: Make generic over chain
export const loadSilverKoiData = ({ chain }: { chain: Chain }): PlumeTestnetSilverKoiData => {
  if (chain === "plume-testnet") {
    return loadPlumeTestnetSilverKoiData()
  } else {
    throw new Error(`unsupported chain: ${chain}`)
  }
}
