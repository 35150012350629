import "core-js/actual"

import * as Sentry from "@sentry/react"
import { watchChainId } from "@wagmi/core"
import { useEffect } from "react"
import { useInterval } from "usehooks-ts"
import { useConfig } from "wagmi"

import { env } from "~/env"
import { MainApp } from "./MainApp"
import { Fallback, FallbackProps } from "./components/Fallback"
import { MobileApp } from "./components/mobile/MobileApp"
import { TooltipContextProvider } from "./contexts/TooltipContextProvider"
import { Web3ModalProvider } from "./contexts/Web3ModalProvider"
import { useIsMobile } from "./hooks"
import { useGlobalActions } from "./stores"

import "react-toastify/dist/ReactToastify.css"

const setupSentry = () => {
  const dsn = env.VITE_SENTRY_DSN
  const tracesSampleRate = Number(env.VITE_SENTRY_TRACES_SAMPLE_RATE ?? 1.0)
  const replaysSessionSampleRate = Number(env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE ?? 0.1)
  const replaysOnErrorSampleRate = Number(env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ?? 1.0)
  const enabled = env.MODE !== "development"

  Sentry.init({
    dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate,
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/app-alpha\.silverkoi\.io/,
      /^https:\/\/skoi-app-alpha-blazing\.onrender\.com/,
    ],
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
    enabled,
  })
}
setupSentry()

export const App = () => {
  const isMobile = useIsMobile()

  // Periodically changing numbers used to trigger updates downstream.
  // TODO: remove these, should no longer need these
  const { updateSlowTimer, updateFastTimer } = useGlobalActions()
  useInterval(() => {
    updateSlowTimer(Date.now())
  }, 5_000)
  useInterval(() => {
    updateFastTimer(Date.now())
  }, 1_000)

  return (
    <Sentry.ErrorBoundary fallback={makeFallback}>
      <Web3ModalProvider>
        <TooltipContextProvider>{isMobile ? <MobileApp /> : <MainApp />}</TooltipContextProvider>

        <ReloadOnNetworkChange />
      </Web3ModalProvider>
    </Sentry.ErrorBoundary>
  )
}

const makeFallback = (props: FallbackProps) => {
  return <Fallback {...props} />
}

const ReloadOnNetworkChange = () => {
  const config = useConfig()
  useEffect(() => {
    const unwatch = watchChainId(config, {
      onChange(_: number) {
        window.location.reload()
      },
    })

    return () => {
      unwatch()
    }
  }, [config])

  return <></>
}
