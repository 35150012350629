import { Slide, ToastContainer, toast } from "react-toastify"
import { useCopyToClipboard } from "usehooks-ts"

import { getIcon, getUrl, tw } from "../utils"

export interface FallbackProps {
  error: Error
  componentStack: string
  eventId: string
  resetError(): void
}

export const Fallback = ({ eventId, resetError }: FallbackProps) => {
  const [_, copy] = useCopyToClipboard()

  const onClick = async () => {
    try {
      await copy(eventId)
      toast.info("Copied error id to clipboard")
    } catch (err: unknown) {
      console.error("Failed to copy error id", err)
    }
  }

  return (
    <div className="flex flex-col w-full h-full text-white items-center justify-center bg-neutral-07 gap-5">
      <div className={tw("font-fallback-title")}>Something fishy happened</div>
      <div className={tw("font-fallback-paragraph")}>
        Something went wrong. We apologize for the inconvenience.
      </div>
      <div className={tw("font-fallback-paragraph")}>
        Please reach out to us on{" "}
        <a href={getUrl("discord")} target="_blank" className="underline">
          discord
        </a>
        &nbsp;and provide the following error id:
      </div>
      <div onClick={onClick} className="text-red text-semibold hover:cursor-pointer underline">
        {eventId}
      </div>

      <button
        onClick={resetError}
        className="text-white px-2 py-1 bg-blue outline-none focus:outline-none hover:border-transparent hover:text-white rounded-lg"
      >
        Try Again
      </button>

      <ToastContainer
        position="bottom-right"
        autoClose={500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        theme="dark"
        transition={Slide}
        icon={({ type }) => {
          switch (type) {
            case "error":
              return <img src={getIcon("warning-red")} className="w-6 h-6" />
            case "warning":
              return <img src={getIcon("warning-yellow")} className="w-6 h-6" />
            case "success":
              return <img src={getIcon("success")} className="w-6 h-6" />
            default:
              return <img src={getIcon("info")} className="w-6 h-6" />
          }
        }}
        toastClassName={() => {
          return (
            "relative flex p-3 mt-1 min-h-10 bg-neutral-05 bg-opacity-1 rounded-md justify-between items-center overflow-hidden cursor-pointer " +
            "font-sans text-[1rem]"
          )
        }}
        bodyClassName={() => {
          return "flex h-[40px] bg-neutral-05 items-center"
        }}
      />
    </div>
  )
}
