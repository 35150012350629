// TODO: Once stable, move to silverkoi package
import * as viem from "viem"

import { SilverKoiApi } from "silverkoi"
import { BigDecimal } from "silverkoi/math"

import { OperationInput } from "~/types"
import { USD_SYMBOL } from "~/utils"

export function getContractFunctionRevertedError(
  e: unknown,
): viem.ContractFunctionRevertedError | undefined {
  if (e instanceof viem.ContractFunctionExecutionError) {
    const cause = (e as viem.ContractFunctionExecutionError).cause
    if (cause && cause instanceof viem.ContractFunctionRevertedError) {
      return cause
    }
  }
  return undefined
}

// TODO: make error codes subclass of Error

export type SKErrorCode =
  | "UserStateError"
  | "UndefinedInputError"
  | "InvalidInputError"
  | "ApproveNeededError"
  | "SimulationError"
  | "SmartContractError"
  | "InternalError"
  | "UnknownError"

export interface SKError<C extends SKErrorCode = SKErrorCode> {
  source: "silverkoi-app"
  code: C
  message: string
}

export interface InvalidInputError extends SKError<"InvalidInputError"> {}

export interface SimulationError extends SKError<"SimulationError"> {}

export interface UndefinedInputError extends SKError<"UndefinedInputError"> {}

export interface UserStateError extends SKError<"UserStateError"> {}

export interface SmartContractError extends SKError<"SmartContractError"> {
  error: viem.ContractFunctionRevertedError
  // TODO: parsed error and arguments
}

export interface ApproveNeededError extends SKError<"ApproveNeededError"> {
  error: viem.ContractFunctionRevertedError
  amountXS: bigint
}

export interface InternalError extends SKError<"InternalError"> {}

export interface UnknownError extends SKError<"UnknownError"> {
  error: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

// WARNING: This is not 100% correct (e.g. only checks the code)
export function isApproveNeededError(obj: unknown): obj is ApproveNeededError {
  if (!isSKError(obj)) return false
  const err = obj as ApproveNeededError
  return err.code === "ApproveNeededError"
}

// WARNING: This is not 100% correct (e.g. does not check presence of error
// filed for more specific types like SmartContractError) but is good enough
// since we will be using the more specific type guards anyway if we need more
// specific types.
export function isSKError(obj: unknown): obj is SKError {
  const typedObj = obj as SKError
  return (
    // eslint-disable-next-line no-null/no-null
    ((typedObj !== null && typeof typedObj === "object") || typeof typedObj === "function") &&
    typedObj["source"] === "silverkoi-app" &&
    (typedObj["code"] === "UserStateError" ||
      typedObj["code"] === "UndefinedInputError" ||
      typedObj["code"] === "InvalidInputError" ||
      typedObj["code"] === "ApproveNeededError" ||
      typedObj["code"] === "SimulationError" ||
      typedObj["code"] === "SmartContractError" ||
      typedObj["code"] === "InternalError" ||
      typedObj["code"] === "UnknownError") &&
    typeof typedObj["message"] === "string"
  )
}

// prettier-ignore
export type CodedSKError<C extends SKErrorCode> =
  C extends "UserStateError" ? UserStateError :
  C extends "UndefinedInputError" ? UndefinedInputError :
  C extends "InvalidInputError" ? InvalidInputError :
  C extends "ApproveNeededError" ? ApproveNeededError :
  C extends "SimulationError" ? SimulationError :
  C extends "SmartContractError" ? SmartContractError :
  C extends "InternalError" ? InternalError :
  C extends "UnknownError" ? UnknownError :
  never

type ErrorInfo<C extends SKErrorCode> = Omit<CodedSKError<C>, "source" | "code" | "message">

export function makeSKError<C extends SKErrorCode>(
  code: C,
  message: string,
  info: ErrorInfo<C>,
): CodedSKError<C> {
  return { ...info, source: "silverkoi-app", code, message } as CodedSKError<C>
}

export function parseSmartContractError({
  api,
  error,
  input,
}: {
  api: SilverKoiApi
  error: viem.ContractFunctionRevertedError
  input?: OperationInput
}): SmartContractError | ApproveNeededError {
  const { data, reason } = error

  const requiredApproveAmountXS: bigint = (() => {
    if (data && data.errorName === "Vault_InsufficientSettlementTokenAllowance") {
      const [amountXS, allowanceXS] = data.args as [bigint, bigint]
      return amountXS < allowanceXS ? 0n : amountXS - allowanceXS
    } else {
      return 0n
    }
  })()

  const message = (() => {
    if (requiredApproveAmountXS !== 0n) {
      // The amount in the error message doesn"t include fees. Manually add them
      // here. We use a conservative estimate.
      const feeUpperBound = BigDecimal.fromReal(0.01, 6)
      const amountWithoutFee = BigDecimal.fromRaw(requiredApproveAmountXS, api.usdcDecimals)
      const amount = amountWithoutFee.add(amountWithoutFee.mul(feeUpperBound))

      // Round up to nearest 10.
      const EPS = 10 // Epsilon to avoid rounding issues in smart contract.
      const roundedAmount = BigDecimal.fromReal(
        (Math.round((amount.real() + EPS) / 10) + 1) * 10,
        6,
      )
      const amountStr = roundedAmount.toStringTrimmed(0)
      return `Please approve at least ${amountStr} ${USD_SYMBOL}`
    }

    if (reason) {
      if (reason.match(/ERC20: transfer amount exceeds balance/)) {
        return `You don't have enough ${USD_SYMBOL} to deposit as collateral.`
      }
      if (reason.match(/Leverage not reachable/)) {
        return (
          "Can't attain specified leverage. Please change its value or " +
          "withdraw some collateral first"
        )
      }
      if (reason.match(/Not fully executed/)) {
        return "This order is likely to fail because it cannot be fully filled."
      }
      if (reason.match(/Order crosses/)) {
        return "This order results in a crossed market."
      }
      if (reason.match(/Amount too small/)) {
        return "Collateral amount is too small. Please increase it to a value above 1."
      }
      console.error("error msg:", reason)
      return reason
    } else if (data) {
      const { errorName } = data
      if (errorName.match(/NotEnoughFreeCollateral/)) {
        if (!input) {
          return "Not enough free collateral"
        } else if (input.inputMode === "WithdrawCollateral") {
          return "You cannot withdraw this much collateral"
        } else if (input.inputMode === "ReducePosition") {
          return "You need to deposit more collateral first to meet margin requirement"
        } else {
          return "You need to provide more collateral to meet margin requirement"
        }
      } else if (errorName.match(/TestnetFaucet_HasEnough/)) {
        return `You already have enough ${USD_SYMBOL}`
      } else if (errorName.match(/ERC20InsufficientBalance/)) {
        return `You don't have enough ${USD_SYMBOL} to deposit as collateral.`
      } else if (errorName.match(/TriggerPriceViolatesBestPrice/)) {
        return "Order would immediately trigger. Please adjust trigger price"
      } else if (errorName.match(/OrderSizeTooLarge/)) {
        const [, limitX5] = data.args as [bigint, bigint]
        const limit = BigDecimal.fromRaw(limitX5, 5)
        const limitStr = limit.format({ minDecimals: 1 })
        return `Order size cannot exceed ${limitStr}`
      } else if (errorName.match(/MaxPositionSizeExceeded/)) {
        const [, , limitX5] = data.args as [bigint, bigint, bigint]
        const limit = BigDecimal.fromRaw(limitX5, 5)
        const limitStr = limit.format({ minDecimals: 1 })
        return `This order would cause position to exceed max size of ${limitStr}`
      } else {
        return errorName
      }
    } else {
      // Use a generic message if we can't parse the reason from the error data.
      console.error(`${error}`)
      console.error(`${error.cause}`)
      return "Transaction will fail do to an unexpected error! Please adjust your inputs."
    }
  })()

  if (requiredApproveAmountXS) {
    return makeSKError("ApproveNeededError", message, { error, amountXS: requiredApproveAmountXS })
  } else {
    return makeSKError("SmartContractError", message, { error })
  }
}
