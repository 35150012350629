import { Rounding } from "./rounding"

export function max(a: bigint, b: bigint): bigint {
  return a < b ? b : a
}

export function min(a: bigint, b: bigint): bigint {
  return a < b ? a : b
}

export function abs(a: bigint): bigint {
  return a < 0 ? -a : a
}

export function div(a: bigint, b: bigint, rounding: Rounding): bigint {
  const neg = (a < 0n && b > 0n) || (a > 0n && b < 0n)
  const aAbs = abs(a)
  const bAbs = abs(b)
  if (neg) {
    if (rounding === "up") {
      rounding = "down"
    } else if (rounding === "down") {
      rounding = "up"
    }
  }

  let resultAbs: bigint = 0n
  switch (rounding) {
    case "to-zero": {
      resultAbs = aAbs / bAbs
      break
    }
    case "up": {
      resultAbs = (aAbs + bAbs - 1n) / bAbs
      break
    }
    case "down": {
      resultAbs = aAbs / bAbs
      break
    }
    case "away-from-zero": {
      resultAbs = (aAbs + bAbs - 1n) / bAbs
      break
    }
    default: {
      throw new Error(`invalid value for rounding: ${rounding}`)
      break
    }
  }
  return neg ? -1n * resultAbs : resultAbs
}

// Returns log base 10 of a, rounded down.
export function log10(a: bigint): bigint {
  if (a === 0n) {
    throw new Error(`cannot take logarithm of zero`)
  }

  let result = 0n
  while (a >= 10n) {
    a = a / 10n
    ++result
  }

  return result
}
