import { Address } from "viem"

import {
  MarketSnapshot,
  OrderBook,
  OrderSide,
  OrderSnapshot,
  PositionSnapshot,
  TimeInForce,
  TraderConfig,
  TriggerSnapshot,
  UserBalances,
} from "silverkoi"
import { BigDecimal } from "silverkoi/math"

export type Empty = Record<never, never>

export interface Duration {
  unit: "second" | "minute" | "hour" | "day" | "week"
  length: BigDecimal
}

export type DurationUnit = Duration["unit"]

// Represents a value with a text representation to be used in an html input.
// This is necessary for scenarios where we want to control not only the value
// but also the displayed text.
export interface InputState<T> {
  value: T
  text: string
}

export type OrderType = "market" | "limit" | "stop-limit"

export type InputMode =
  | "NewPosition"
  | "AddPosition"
  | "ReducePosition"
  | "DepositCollateral"
  | "WithdrawCollateral"
  | "CreateSLTP"
  | "EditTrigger"

// TODO: replace with string constants
export enum TooltipId {
  NotUsed,
  MarketPrice,
  TwapMarketPrice,
  OraclePrice,
  DailyChange,
  DailyVolume,
  DailyHigh,
  DailyLow,
  FundingRate,
  AvgEntryPrice,
  LiquidationPrice,
  Action,
  Leverage,
  PositionSize,
  PositionValue,
  OpenNotional,
  OpenInterest,
  VaultBalance,
  Collateral,
  AccountValue,
  UnrealizedPnl,
  RealizedPnl,
  UnsettledFunding,
  Funding,
  BadDebt,
  MaxPositionSize,
  MaxCollateral,
  TradeEntryPrice,
  TradeCollateralChange,
  TradePositionSizeChange,
  TradeOpenNotionalChange,
  TradeOpenInterestChange,
  PriceImpact,
  TransactionFee,
  TriggerPrice,
  SLTriggerPrice,
  TPTriggerPrice,
  SLTP,
  TriggerOrder,
  OrderSide,
  OrderLimitPrice,
}

// TODO: rename, this contains both market and user info
// TODO: change to interface with independent helper methods?
// TODO: expose uid field to use in dependency lists
export class UserState {
  public address: Address
  public traderId?: bigint
  public userBalances: UserBalances

  // Map from symbol => market snapshot
  public markets: Map<string, MarketSnapshot>
  // Map from symbol => position id => position
  public positions: Map<string, Map<bigint, PositionSnapshot>>
  // Map from symbol => order id => order
  public openOrders: Map<string, Map<bigint, OrderSnapshot>>
  // Map from symbol => trigger id => trigger
  public triggers: Map<string, Map<bigint, TriggerSnapshot>>

  public constructor(
    address: Address,
    traderId: bigint | undefined,
    userBalances: UserBalances,
    markets: Map<string, MarketSnapshot>,
    positions: Map<string, Map<bigint, PositionSnapshot>>,
    openOrders: Map<string, Map<bigint, OrderSnapshot>>,
    triggers: Map<string, Map<bigint, TriggerSnapshot>>,
  ) {
    this.address = address
    this.traderId = traderId
    this.userBalances = userBalances
    this.markets = markets
    this.positions = positions
    this.openOrders = openOrders
    this.triggers = triggers
  }

  public getMarketPrice(symbol: string): BigDecimal | undefined {
    const market = this.markets.get(symbol)
    if (!market) return undefined
    const { marketPrice } = market
    return marketPrice.isZero() ? undefined : marketPrice
  }

  public getTwapMarketPrice(symbol: string): BigDecimal | undefined {
    const market = this.markets.get(symbol)
    if (!market) return undefined
    const { twapMarketPrice } = market
    return twapMarketPrice.isZero() ? undefined : twapMarketPrice
  }

  // Returns non-empty positions that are NOT zero-size with nonzero open
  // interest.
  public getNonEmptyPositionsThatAreNotZeroSizeWithNonZeroOpenInterest(): PositionSnapshot[] {
    const candidates = []
    for (const marketPositions of this.positions.values()) {
      for (const position of marketPositions.values()) {
        if (position.empty) continue
        if (position.size.isZero() && !position.openInterestSize.isZero()) continue
        candidates.push(position)
      }
    }
    return candidates
  }

  public getOpenOrders(): OrderSnapshot[] {
    const orders = []
    for (const marketOrders of this.openOrders.values()) {
      for (const order of marketOrders.values()) {
        orders.push(order)
      }
    }
    return orders
  }

  public getTriggers(): TriggerSnapshot[] {
    const triggers = []
    for (const marketTriggers of this.triggers.values()) {
      for (const trigger of marketTriggers.values()) {
        triggers.push(trigger)
      }
    }
    return triggers
  }

  public getPosition({
    symbol,
    positionId,
  }: {
    symbol: string
    positionId: bigint
  }): PositionSnapshot | undefined {
    return this.positions.get(symbol)?.get(positionId)
  }

  // Returns any available empty position, if it exists.
  public getEmptyPosition(symbol: string): PositionSnapshot | undefined {
    const positions = this.positions.get(symbol)
    if (!positions) return undefined
    for (const [_, position] of positions) {
      if (position.empty) {
        return position
      }
    }
    return undefined
  }
}

/** @see {isWithdrawCollateralRequest} ts-auto-guard:type-guard */
export interface WithdrawCollateralRequest {
  type: "WithdrawCollateral"
  traderId: bigint
  marketId: bigint
  positionSubId: bigint
  amount: BigDecimal
}

/** @see {isDepositCollateralRequest} ts-auto-guard:type-guard */
export interface DepositCollateralRequest {
  type: "DepositCollateral"
  traderId: bigint
  marketId: bigint
  positionSubId: bigint
  amount: BigDecimal
}

/** @see {isCancelOrderRequest} ts-auto-guard:type-guard */
export interface CancelOrderRequest {
  type: "CancelOrder"
  traderId: bigint
  marketId: bigint
  clientOrderId: bigint
}

/** @see {isPlaceOrderRequest} ts-auto-guard:type-guard */
export interface PlaceOrderRequest {
  type: "PlaceOrder"
  traderId: bigint
  marketId: bigint
  positionSubId: bigint
  clientOrderId: bigint
  side: OrderSide
  size: BigDecimal
  price: BigDecimal
  tick: bigint
  tif: TimeInForce
  deadline: bigint
  postOnly: boolean
  reduceOnly: boolean
  targetLeverage: BigDecimal
  maintainLeverage: boolean
}

/** @see {isCreateTriggerRequest} ts-auto-guard:type-guard */
export interface CreateTriggerRequest {
  type: "CreateTrigger"
  traderId: bigint
  marketId: bigint
  positionSubId: bigint
  clientOrderId: bigint
  side: OrderSide
  size: BigDecimal
  fromAboveTriggerPrice: BigDecimal
  fromBelowTriggerPrice: BigDecimal
  fromAboveLimitPrice: BigDecimal
  fromBelowLimitPrice: BigDecimal
  fromAboveTriggerTick: bigint
  fromBelowTriggerTick: bigint
  fromAboveLimitTick: bigint
  fromBelowLimitTick: bigint
  tif: TimeInForce
  postOnly: boolean
  reduceOnly: boolean
  targetLeverage: BigDecimal
  maintainLeverage: boolean
}

/** @see {isReplaceTriggerRequest} ts-auto-guard:type-guard */
export interface ReplaceTriggerRequest {
  type: "ReplaceTrigger"

  fromAbove: boolean
  oldClientOrderId: bigint

  traderId: bigint
  marketId: bigint
  positionSubId: bigint
  clientOrderId: bigint
  side: OrderSide
  size: BigDecimal
  fromAboveTriggerPrice: BigDecimal
  fromBelowTriggerPrice: BigDecimal
  fromAboveLimitPrice: BigDecimal
  fromBelowLimitPrice: BigDecimal
  fromAboveTriggerTick: bigint
  fromBelowTriggerTick: bigint
  fromAboveLimitTick: bigint
  fromBelowLimitTick: bigint
  tif: TimeInForce
  postOnly: boolean
  reduceOnly: boolean
  targetLeverage: BigDecimal
  maintainLeverage: boolean
}

/** @see {isCancelTriggerRequest} ts-auto-guard:type-guard */
export interface CancelTriggerRequest {
  type: "CancelTrigger"
  traderId: bigint
  marketId: bigint
  clientOrderId: bigint
}

export type OperationRequest =
  | DepositCollateralRequest
  | WithdrawCollateralRequest
  | PlaceOrderRequest
  | CancelOrderRequest

export interface PartialOperationContext {
  symbol: string
  market: MarketSnapshot
  orderBook: OrderBook
  traderConfig?: TraderConfig
  position?: PositionSnapshot
  currentTimestamp: bigint
}

export type OperationContext = Required<PartialOperationContext>

export function isOperationContext(
  context: PartialOperationContext | OperationContext,
): context is OperationContext {
  return context.traderConfig !== undefined && context.position !== undefined
}

export type WithOperationContext<T> = { request: T; context: OperationContext }

export interface OperationInput {
  symbol: string
  inputMode?: InputMode

  // For deposit/withdraw collateral
  collateralAmount: InputState<BigDecimal | undefined>

  // For place order
  type: OrderType
  side: OrderSide
  size: InputState<BigDecimal | undefined>
  notional: InputState<BigDecimal | undefined> // NOTE: only exists for UI
  limitPrice: InputState<BigDecimal | undefined>
  tif: TimeInForce
  cancelAfter: InputState<Duration | undefined>
  postOnly: boolean
  leverage: InputState<BigDecimal | undefined>
  slippage: InputState<BigDecimal | undefined>

  triggerPrice: InputState<BigDecimal | undefined>
  triggerIsFromAbove: boolean

  slTriggerPrice: InputState<BigDecimal | undefined>
  tpTriggerPrice: InputState<BigDecimal | undefined>

  referenceTrigger?: TriggerSnapshot // For edit trigger
}

export interface Delta<Type> {
  oldValue: Type
  newValue: Type
  diff: Type
}

// TODO: consider creating multiple summary types and unioning them
export interface OperationSummary {
  symbol: string

  // These are specific to non-triggers`
  collateral?: Delta<BigDecimal>
  positionSize?: Delta<BigDecimal>
  // TODO: position notional
  openNotional?: Delta<BigDecimal>
  openInterestSize?: Delta<BigDecimal>
  openInterestNotional?: Delta<BigDecimal>
  leverage?: Delta<BigDecimal | undefined>
  liquidationPrice?: Delta<BigDecimal | undefined>
  // TODO: pnl
  entryPrice?: BigDecimal
  priceImpactPct?: BigDecimal
  transactionFee?: BigDecimal

  // These are specific to triggers
  triggerSide?: OrderSide
  triggerSize?: BigDecimal
  tpTriggerPrice?: Delta<BigDecimal | undefined>
  tpLimitPrice?: Delta<BigDecimal | undefined>
  slTriggerPrice?: Delta<BigDecimal | undefined>
  slLimitPrice?: Delta<BigDecimal | undefined>
  // TODO: transaction fee estimate
}

export interface FormattedOperationSummary {
  description: string
  descriptionColor: string
  symbol: string
  ticker: string
  entryPrice: string
  collateral: string
  positionSize: string
  openNotional: string
  openInterestNotional: string
  leverage: string
  priceImpact: string
  liquidationPrice: string
  transactionFee: string
}
